import { default as React} from 'react';
import {Link} from 'react-router-dom';

const DashBoardCard = ({icon, title, route, extraClasses=''}) => {
  return (
      <Link to={route}>
        <div className={`card card-dashboard ${extraClasses}`}>
            <div className="card-dashboard__icon">{icon}</div>
            <h2 className="w-100">{title}</h2>
        </div>
      </Link>
  );
};

export default DashBoardCard;