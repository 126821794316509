import { default as React, Fragment, useState, useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { useAuth, useApi } from '../services';
import {formatMoney} from '../utilities/NumberFormat';

const PayForSubscriptionPage = () => {
  const { currentUser } = useAuth();
  const [prices, setPrices]= useState();
  const [redirecting, setRedirecting]= useState(false);
  const [wantsCard, setWantsCard]= useState(currentUser.card);
  const [credits, setCredits]= useState(0);
  const [showOther, setShowOther]= useState(false);
  const [creditsPrice, setCreditsPrice]= useState(0);
  const { initPayForSubscriptionPage, payForDogOwnerSubscription, payForExhibitorSubscription } = useApi();

  const [loaded, setLoaded] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(async ()=> {
      let data = await initPayForSubscriptionPage();
      setPrices(data);
      setLoaded(true);
  }, []);

  const handleMollieDogOwner = async ( ) => {
    setRedirecting(true);
    let resp = await payForDogOwnerSubscription(wantsCard);
    if (resp.ok) {
      let data = await resp.json();
      window.location = data.mollieUrl;
    }
  }

  const handleMollieExhibitor = async ( ) => {
    setRedirecting(true);
    let resp = await payForExhibitorSubscription(credits);
    if (resp.ok) {
      let data = await resp.json();
      window.location = data.mollieUrl;
    }
  }

  const handleCreditsChange = (amount) => {
    if (amount != 'other') {
      setShowOther(false);
      setCredits(amount); 
      setCreditsPrice(amount * parseFloat(prices.filter((x) => {return x.name == 'coin'})[0].price_incl));
    } else {
      setShowOther(true);
    }
  }

  const handleCreditsInputChange = (amount) => {
      setCredits(amount); 
      setCreditsPrice(amount * parseFloat(prices.filter((x) => {return x.name == 'coin'})[0].price_incl));
  }

  const calcTotalPrice = () =>{
    let price = 0;
    let isExhibitor = currentUser.roles.filter((x)=> { return x.name == "Exhibitor"}).length > 0;
    let isDogOwner = currentUser.roles.filter((x)=> { return x.name == "DogOwner"}).length > 0;
    if (isExhibitor) {
      price = prices.filter((x) => {return x.name == 'exhibitor'})[0].price_incl;
    } else if(isDogOwner){
      price = prices.filter((x) => {return x.name == 'dog_owner'})[0].price_incl;
    }
    if (currentUser.card) {
      price += prices.filter((x) => {return x.name == 'card'})[0].price_incl;
    }
    currentUser?.dogs.map((dog, i)=> {
      if (i<2) {
        price += prices.filter((x) => {return x.name == 'dog_expensive'})[0].price_incl;
      } else {
        price += prices.filter((x) => {return x.name == 'dog_cheap'})[0].price_incl;
      }
    });
    return price;
  }

  useEffect(()=>{
    if (prices) {
      setTotal(total + calcTotalPrice());
    }
  }, [prices]);

  return (
    <Fragment>
          <Container fluid className='mt-4'>
            {loaded && prices ? 
            <>
              {currentUser.roles.filter((x)=> { return x.name == "Exhibitor"}).length > 0?
              <>
                <Row>
                  <Col xs={12}>
                    <h1>Nieuw abonnement</h1>
                    <p>Vooraleer u zich kan inschrijven in een event als standhouder dient u eerst uw abonnement te betalen. U kan ook nu al Tudors kopen. Hiermee kan u als standhouder de toegang tot een evenement betalen of op een event aan een klant geven.</p>
                    <table className="payforsubscriptiontable">
                      <tbody>
                        <tr>
                          <td>
                            Abonnement (jaarlijks)
                          </td>
                          <td>
                            {formatMoney(prices.filter((x) => {return x.name == 'exhibitor'})[0].price_incl)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className='d-flex flex-row align-items-center'>
                              <img alt='tudors' className='me-1' src={'/assets/img/coin.svg'} style={{height: '1em'}}></img>
                              <label htmlFor="floatingCredits" className='p-0 m-0 me-2'>Tudors</label>
                              <div className='select-wrapper ms-auto'>
                                <select name="credits" id="floatingCredits" className="w-100 pe-4" defaultValue={credits} onChange={(e)=> {handleCreditsChange(e.target.value)}}>
                                  <option value="0">0</option>
                                  <option value="1000">1000</option>
                                  <option value="2500">2500</option>
                                  <option value="5000">5000</option>
                                  <option value="other">Eigen aantal</option>
                                </select>
                              </div>
                              {showOther && 
                                <input type="number" className="form-control w-25 ms-2"  onChange={(e)=> {handleCreditsInputChange(e.target.value)}}/>
                              }
                            </div>
                          </td>
                          <td>
                            {formatMoney(creditsPrice)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Totaal: </b>
                          </td>
                          <td>
                            {formatMoney(parseFloat(total) + parseFloat(creditsPrice))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!redirecting?
                      <button type="button" className="btn btn-primary " onClick={()=> {handleMollieExhibitor();}}>Betalen</button>
                      :
                      <span className="loadingspinner d-block ms-3"></span>
                    }
                  </Col>
                </Row>
              </>
              :
              <>
                <Row>
                  <Col xs={12}>
                    <h1>Nieuw abonnement</h1>
                    <p>Vooraleer u uw qr-code kan gebruiken, dient u te betalen voor uw abonnement. Doe dit hier online of op één van onze events aan de kassa.</p>
                    <table className="payforsubscriptiontable">
                      <tbody>
                        <tr>
                          <td>
                            {currentUser.firstname} {currentUser.name}
                          </td>
                          <td>
                            {formatMoney(prices.filter((x) => {return x.name == 'dog_owner'})[0].price_incl)}
                          </td>
                        </tr>
                        {wantsCard == true && 
                          <tr>
                            <td>
                              Fysieke kaart
                            </td>
                            <td>
                              {formatMoney(prices.filter((x) => {return x.name == 'card'})[0].price_incl)}
                            </td>
                          </tr>
                        }
                        {currentUser && currentUser.dogs.map((dog, i)=> {
                          return (
                            <tr key={i}>
                              <td>
                                {dog.dog_name} ({dog.breed.breed_name})
                              </td>
                              <td>
                                {i<2?
                                  formatMoney(prices.filter((x) => {return x.name == 'dog_expensive'})[0].price_incl)
                                :
                                  formatMoney(prices.filter((x) => {return x.name == 'dog_cheap'})[0].price_incl)
                                }
                              </td>
                            </tr>
                          )
                        })}
                        <tr>
                          <td>
                            <b>Totaal: </b>
                          </td>
                          <td>
                            {formatMoney(total)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  {/* <Col xs={12}>
                    <div className="d-flex flex-row flex-nowrap align-items-center mb-3">
                      <input className="form-check-input p-0 m-0 flex-shrink-0" type="checkbox" id="wants_card" defaultChecked={wantsCard} onChange={()=>{setWantsCard(!wantsCard)}}/>
                      <label className="p-0 m-0 ms-2" htmlFor="wants_card">
                        Ik wens ook een fysieke kaart te ontvangen.
                      </label>
                    </div>
                  </Col> */}
                </Row>
                <Row>
                  <Col>
                    {!redirecting?
                      <button type="button" className="btn btn-primary " onClick={()=> {handleMollieDogOwner();}}>Betalen</button>
                      :
                      <span className="loadingspinner d-block ms-3"></span>
                    }
                  </Col>
                </Row>
              </>
              }
            </>
            :
            <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                <p className="m-auto d-flex align-items-center">Laden... <span className="loadingspinner d-block ms-3"></span></p>
            </Col>
            }
          </Container>
    </Fragment>
  );
};

export default PayForSubscriptionPage;