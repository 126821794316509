import { default as React } from 'react';

const PopUp = ({title, subtitle='', negativeTitle, positiveTitle, negativeAction= ()=>{}, positiveAction= ()=>{}, children}) => {
  return (
      <div className="popup" onClick={()=>{negativeAction()}}>
        <div className='card' onClick={(e)=> {e.stopPropagation();}}>
          <h3>{title}</h3>
          <p>{subtitle}</p>
          {children && <div className="popup-component">
            {children}
          </div>}
          <hr></hr>
          <div className="d-flex flex-column-reverse flex-sm-row form-row">
            {negativeTitle && negativeAction && <button className="btn btn-secondary" onClick={()=> {negativeAction()}}>{negativeTitle}</button>}
            {positiveTitle && positiveAction && <button className="btn btn-primary mb-3 mb-sm-0 ms-0 ms-sm-3" onClick={()=> {positiveAction()}}>{positiveTitle}</button>}
          </div>
        </div>
      </div>
  );
};

export default PopUp;