import { default as React, Fragment, useState, useRef, useCallback, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import * as Routes from '../../routes';
import { useAuth, useApi } from '../../services';
import { DogRegisterRow } from '../../components';
import { useHistory } from 'react-router-dom';
import Select from 'react-select'

const RegisterPage = () => {
  const history = useHistory();

  const { signUp } = useAuth();
  const { initRegisterPage } = useApi();

  const [firstname, setFirstname] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatpassword, setRepeatpassword] = useState('');
  const [street, setStreet] = useState('');
  const [nr, setNr] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const [company_name, setCompanyName] = useState('');
  const [company_vat, setCompanyVat] = useState('');
  const [company_street, setCompanyStreet] = useState('');
  const [company_nr, setCompanyNr] = useState('');
  const [company_city, setCompanyCity] = useState('');
  const [company_zip, setCompanyZip] = useState('');
  const [company_description, setCompanyDescription] = useState('');

  const [companyCountry, setCompanyCountry] = useState('BE');

  const [dogs, setDogs] = useState([{name: '', breed: null, other_breed: ''}]);

  const [breeds, setBreeds] = useState([]);
  const [country, setCountry] = useState('BE');
  const [validated, setValidated] = useState(false);
  const [alreadyUsedEmail, setAlreadyUsedEmail] = useState(false);
  const [wantsCard, setWantsCard] = useState(false);
  const [showDogError, setShowDogError] = useState(false);
  const [showVatError, setShowVatError] = useState(false);
  const [registerAsExhibitor, setRegisterAsExhibitor] = useState(false);
  const [companyType, setCompanyType] = useState(1);
  const [companyTypes, setCompanyTypes] = useState(false);

  const [submitting, setSubmitting]= useState(false);

  const initFetch = useCallback(() => {
    const fetchdata = async () => {
      const response = await initRegisterPage();
      setBreeds(response.breeds);
      setCompanyTypes(response.company_types);
      setCompanyType(response.company_types[0].id);
    }
    fetchdata();
  },[initRegisterPage]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const handleSubmit = async (event) => {
    setSubmitting(true);
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    
    if (form.checkValidity() !== false) {
      const resp = await signUp(
                    firstname, 
                    name, 
                    email, 
                    password, 
                    repeatpassword,
                    street,
                    nr,
                    city,
                    zip,
                    country,
                    registerAsExhibitor,
                    company_name,
                    company_vat,
                    company_street,
                    company_nr,
                    company_city,
                    company_zip,
                    company_description,
                    companyCountry,
                    companyType,
                    phone,
                    wantsCard,
                    dogs
                  );
      if (resp.ok) {
        history.push(Routes.PAY_FOR_SUBSCRIPTION);
      } else {
        let data = await resp.json();
        if (data?.errors?.email) {
          setAlreadyUsedEmail(true);
          setShowDogError(false);
          setShowVatError(false);
        } else if(data?.errors?.dog) {
          setAlreadyUsedEmail(false);
          setShowDogError(true);
          setShowVatError(false);
        } else if(data?.errors?.vat){
          setAlreadyUsedEmail(false);
          setShowDogError(false);
          setShowVatError(true);
        }
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
      setValidated(true);
    }
  };

  return (
    <Fragment>
      {breeds && breeds.length > 0 && companyTypes && companyTypes.length > 0 && 
        <Form className='card card-auth' validated={validated} onSubmit={handleSubmit}>
          <h1>Contactgegevens</h1>
            <Form.Group>
              <div className="form-floating mb-4">
                <input type="email" className="form-control" id="floatingEmail" placeholder="name@example.com" required value={email} onChange={(e) => {setEmail(e.target.value)}} />
                <label htmlFor="floatingEmail">E-mailadres</label>
                <Form.Control.Feedback type="invalid" className='mb-5'>
                  Gelieve een e-mailadres in te geven.
                </Form.Control.Feedback>
              </div>
              <div className="form-floating mb-4">
                <input type="password" pattern='.{8,20}' className="form-control" id="floatingPassword" placeholder="wachtwoord" required value={password} onChange={(e) => {setPassword(e.target.value)}}/>
                <label htmlFor="floatingPassword">Wachtwoord</label>
                <Form.Control.Feedback type="invalid" className='mb-5'>
                  Gelieve een wachtwoord in te geven tussen 8 en 20 karakters.
                </Form.Control.Feedback>
              </div>
              <div className="form-floating mb-4">
                <input type="password" pattern='.{8,20}' className={`form-control ${password != repeatpassword ?'is-invalid' :''}`} id="floatingPasswordRepeat" placeholder="wachtwoord" required value={repeatpassword} onChange={(e) => {setRepeatpassword(e.target.value)}}/>
                <label htmlFor="floatingPasswordRepeat">Herhaal Wachtwoord</label>
                <Form.Control.Feedback type="invalid" className='mb-5'>
                  Gelieve uw wachtwoord correct te herhalen.
                </Form.Control.Feedback>
              </div>
            {alreadyUsedEmail && 
            <Form.Row className="w-100 text-start mb-4 error-message">
              Het opgegeven e-mailadres is al in gebruik.
            </Form.Row>}
              <Row>
                <Col xs={6}>
                  <div className="form-floating mb-4">
                    <input type="text" className="form-control" id="floatingFirstname" placeholder="voornaam" required value={firstname} onChange={(e) => {setFirstname(e.target.value)}}/>
                    <label htmlFor="floatingFirstname">Voornaam</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw voornaam in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="form-floating mb-4">
                    <input type="text" className="form-control" id="floatingName" placeholder="naam" required value={name} onChange={(e) => {setName(e.target.value)}}/>
                    <label htmlFor="floatingName">Naam</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw naam in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={9}>
                  <div className="form-floating mb-4">
                    <input type="text" className="form-control" id="floatingStreet" placeholder="straat" required value={street} onChange={(e) => {setStreet(e.target.value)}}/>
                    <label htmlFor="floatingStreet">Straat</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw straat in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-floating mb-4">
                    <input type="text" className="form-control" id="floatingNumber" placeholder="1" required value={nr} onChange={(e) => {setNr(e.target.value)}}/>
                    <label htmlFor="floatingNumber">Nr</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw huisnummer in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="form-floating mb-4">
                    <input type="text" className="form-control" id="floatingZip" placeholder="9000" required value={zip} onChange={(e) => {setZip(e.target.value)}}/>
                    <label htmlFor="floatingZip">Postcode</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw naam in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4">
                    <input type="text" className="form-control" id="floatingCity" placeholder="Gemeente" required value={city} onChange={(e) => {setCity(e.target.value)}}/>
                    <label htmlFor="floatingCity">Gemeente</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw gemeente in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="form-floating always-up mb-4">
                    <label htmlFor="floatingCountry">Land</label>
                    <div className='select-wrapper'>
                      <select name="country" id="country" className="w-100" value={country} onChange={(e)=> {setCountry(e.target.value);}}>
                        <option value="BE">België</option>
                        <option value="NL">Nederland</option>
                      </select>
                    </div>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw land in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4">
                    <input type="text" className="form-control" id="floatingPhone" placeholder="+32123456789" required value={phone} onChange={(e) => {setPhone(e.target.value)}}/>
                    <label htmlFor="floatingPhone">Telefoonnummer</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw telefoonnummer in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <div className="d-flex flex-row flex-nowrap align-items-center mb-3">
                    <input className="form-check-input p-0 m-0 flex-shrink-0" type="checkbox" id="wants_card" defaultChecked={wantsCard} onChange={()=>{setWantsCard(!wantsCard)}}/>
                    <label className="p-0 m-0 ms-2" htmlFor="wants_card">
                      Ik wens ook een fysieke kaart te ontvangen.
                    </label>
                  </div>
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <div className="d-flex flex-row flex-nowrap align-items-center mb-3">
                    <input className="form-check-input p-0 m-0 flex-shrink-0" type="checkbox" id="register_as_exhibitor" defaultChecked={registerAsExhibitor} onChange={()=>{setRegisterAsExhibitor(!registerAsExhibitor)}}/>
                    <label className="p-0 m-0 ms-2" htmlFor="register_as_exhibitor">
                      Registreer als standhouder.
                    </label>
                  </div>
                </Col>
              </Row>
              {registerAsExhibitor == true ? 
                <>
                  <h1>Bedrijfsgegevens</h1>
                  <Row>
                    <Col md={6}>
                      <div className="form-floating mb-4">
                        <input type="text" className="form-control" id="floatingCompanyName" placeholder="bedrijfsnaam" required value={company_name} onChange={(e) => {setCompanyName(e.target.value)}}/>
                        <label htmlFor="floatingCompanyName">Bedrijfsnaam</label>
                        <Form.Control.Feedback type="invalid" className='mb-5'>
                          Gelieve uw Bedrijfsnaam in te geven.
                        </Form.Control.Feedback>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-floating mb-4">
                        <input type="text" className="form-control" id="floatingCompanyVAT" placeholder="naam" required value={company_vat} onChange={(e) => {setCompanyVat(e.target.value)}}/>
                        <label htmlFor="floatingCompanyVAT">BTW-nummer</label>
                        <Form.Control.Feedback type="invalid" className='mb-5'>
                          Gelieve uw BTW-nummer in te geven.
                        </Form.Control.Feedback>
                      </div>
                      {showVatError&& 
                        <p className="w-100 text-start mb-3 error-message">
                            Dit BTW-nummer wordt al gebruikt op onze applicatie.
                        </p>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="form-floating always-up mb-4">
                        <label htmlFor="companyType">Type bedrijf</label>
                        <Select
                          classNamePrefix="react-select"
                          theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                  ...theme.colors,
                                  primary25: '#EBE9EB',
                                  primary: 'black',
                                  neutral10: 'black',
                              },
                          })}
                          defaultValue={{value: companyTypes[0].id, label: companyTypes[0].type_name}}
                          options={[...companyTypes.map((type, i)=> {
                              return {value: type.id, label: type.type_name}
                          })]} 
                          onChange={(e)=> {
                              setCompanyType(e.value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={9}>
                      <div className="form-floating mb-4">
                        <input type="text" className="form-control" id="floatingCompanyStreet" placeholder="straat" required value={company_street} onChange={(e) => {setCompanyStreet(e.target.value)}}/>
                        <label htmlFor="floatingCompanyStreet">Straat</label>
                        <Form.Control.Feedback type="invalid" className='mb-5'>
                          Gelieve uw straat in te geven.
                        </Form.Control.Feedback>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-floating mb-4">
                        <input type="text" className="form-control" id="floatingNumber" placeholder="+32123456789" required value={company_nr} onChange={(e) => {setCompanyNr(e.target.value)}}/>
                        <label htmlFor="floatingNumber">Nr</label>
                        <Form.Control.Feedback type="invalid" className='mb-5'>
                          Gelieve uw huisnummer in te geven.
                        </Form.Control.Feedback>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <div className="form-floating mb-4">
                        <input type="text" className="form-control" id="floatingCompanyZip" placeholder="9000" required value={company_zip} onChange={(e) => {setCompanyZip(e.target.value)}}/>
                        <label htmlFor="floatingCompanyZip">Postcode</label>
                        <Form.Control.Feedback type="invalid" className='mb-5'>
                          Gelieve uw Postcode in te geven.
                        </Form.Control.Feedback>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-floating always-up mb-4">
                        <label htmlFor="floatingCountry">Land</label>
                        <div className='select-wrapper'>
                          <select name="company_country" id="CompanyCountry" className="w-100" value={companyCountry} onChange={(e)=> {setCompanyCountry(e.target.value);}}>
                            <option value="BE">België</option>
                            <option value="NL">Nederland</option>
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-floating mb-4">
                        <input type="text" className="form-control" id="floatingCompanyCity" placeholder="Gemeente" required value={company_city} onChange={(e) => {setCompanyCity(e.target.value)}}/>
                        <label htmlFor="floatingCompanyCity">Gemeente</label>
                        <Form.Control.Feedback type="invalid" className='mb-5'>
                          Gelieve uw gemeente in te geven.
                        </Form.Control.Feedback>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className="form-floating mb-4">
                        <textarea type="text" className="form-control" id="floatingCompanyDescription" placeholder="Omschrijving" value={company_description} onChange={(e) => {setCompanyDescription(e.target.value)}}/>
                        <label htmlFor="floatingCompanyDescription">Omschrijving bedrijf</label>
                        <Form.Control.Feedback type="invalid" className='mb-5'>
                          Gelieve uw omschrijving in te geven.
                        </Form.Control.Feedback>
                      </div>
                    </Col>
                  </Row>
                </>
                :
                <>
                  <h1>Mijn hond(en)</h1>
                  {showDogError && 
                    <p className="w-100 text-start mb-3 error-message">
                        Account aanmaken mislukt. Vul zowel de naam als het ras van alle rijen in.
                    </p>
                  }
                  <Form.Group>
                    {dogs && dogs.map((dog, index)=> {
                        return <Row key={index} className="mb-5">
                                  <DogRegisterRow dogs={dogs} index={index} breeds={breeds} setDogs={setDogs}  showDelete={index != 0}/>
                                </Row>
                        })}
                    <button type="button" className="btn btn-green mb-5" onClick={()=> {setDogs([...dogs, {name: '', breed: null, other_breed: ''}]);}}>Hond toevoegen</button>
                  </Form.Group>
                </>
              }
            </Form.Group>
          <Row>
            <Col>
              <div className="d-flex flex-row flex-nowrap align-items-center mb-3">
                <input className="form-check-input p-0 m-0 flex-shrink-0" type="checkbox" id="accept_privacy_policy" required/>
                <label className="p-0 m-0 ms-2" htmlFor="accept_privacy_policy">
                  Ik bevestig de <a href={Routes.PRIVACY} target="_blank" className='link-policy'>privacy policy</a> te hebben gelezen en deze te accepteren.
                </label>
              </div>
            </Col>
          </Row>
        <Form.Row className="d-flex flex-column-reverse flex-sm-row">
          <button type="button" className="btn btn-secondary " onClick={()=> {history.push(Routes.LOGIN)}}>Terug</button>
          {!submitting?
            <button type="submit" className="btn btn-primary mb-3 mb-sm-0 ms-0 ms-sm-3">registreren</button>
          :
            <span className="loadingspinner d-block ms-3"></span>
          }
        </Form.Row>
      </Form>
    }
  </Fragment>
  );
};

export default RegisterPage;