import { default as React, Fragment, useState, useRef } from 'react';
import {Col, Row, Container, Form} from 'react-bootstrap';
import { useHistory } from 'react-router';
import * as Routes from '../../routes';
import { useApi } from '../../services';
import { EventRewardRow, EventServicesRow } from '../../components';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";

const AdminAddEventPage = () => {
    const history = useHistory();
    
    const { addEvent } = useApi();

    const [validated, setValidated] = useState(false);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [from, setFrom] = useState();
    const [until, setUntil] = useState();
    const [street, setStreet] = useState('');
    const [nr, setNr] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('BE');
    const [exhibitorPrice, setExhibitorPrice] = useState(100);
    const [rewards, setRewards] = useState([]);
    const [services, setServices] = useState([]);
    const [nonProfitServices, setnonProfitServices] = useState([]);

    const handleSubmit = async (event) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      if (form.checkValidity() === true) {
        let response = await addEvent(
            title,
            description,
            from,
            until,
            street,
            nr,
            city,
            zip,
            country,
            exhibitorPrice,
            rewards,
            services,
            nonProfitServices
        );

        if (response.ok) {
          history.push(Routes.ADMIN_EVENTS);
        }
      }
      setValidated(true);
    };

    const handleAddReward = () => {
      let temp = [...rewards];
      temp.push({type:'check-in', reward_at: rewards.length+1, credits:5});
      setRewards(temp);
    }
    const handleAddService = () => {
      let temp = [...services];
      temp.push({name:'', available: 1, price: 50});
      setServices(temp);
    }
    const handleAddnonProfitServices = () => {
      let temp = [...nonProfitServices];
      temp.push({name:'', available: 1, price: 50});
      setnonProfitServices(temp);
    }
    return (
    <Fragment>
        <h1>Event aanmaken</h1>
        <Container fluid className='mt-4'>
          <Form className="card" noValidate validated={validated} onSubmit={handleSubmit}>
              <Col>
                <h2 className='mb-3'>Gegevens</h2>
              </Col>
              <Col>
                <Form.Group>
                  <div className="form-floating mb-4">
                    <input type="text" className="form-control" id="floatingTitle" placeholder="event" required value={title} onChange={(e) => {setTitle(e.target.value)}}/>
                    <label htmlFor="floatingTitle">Naam van het event</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                        Gelieve een naam op te geven.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <div className="form-floating mb-4">
                    <textarea type="email" className="form-control" id="floatingDescription" placeholder="John Doe" value={description} onChange={(e) => {setDescription(e.target.value)}}/>
                    <label htmlFor="floatingDescription">Beschrijving</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve een beschrijving op te geven.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <div className="form-floating mb-4">
                        <Flatpickr
                          className="form-control"
                          id="floatingFrom"
                          placeholder="test"
                          options={{
                            dateFormat:"d/m/Y"
                          }}
                          onChange={(date)=>{let temp = new Date(date); setFrom(`${temp.getDate()}/${temp.getMonth()+1}/${temp.getFullYear()}`)}}
                        />
                        <label htmlFor="floatingFrom">Van</label>
                      <Form.Control.Feedback type="invalid" className='mb-5'>
                        Gelieve startmoment op te geven.
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <div className="form-floating mb-4">
                        <Flatpickr
                          className="form-control"
                          id="floatingUntil"
                          placeholder="test"
                          options={{
                            dateFormat:"d/m/Y"
                          }}
                          onChange={(date)=>{let temp = new Date(date); setUntil(`${temp.getDate()}/${temp.getMonth()+1}/${temp.getFullYear()}`)}}
                        />
                        <label htmlFor="floatingUntil">Tot</label>
                      <Form.Control.Feedback type="invalid" className='mb-5'>
                        Gelieve eindmoment op te geven.
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={9}>
                  <div className="form-floating mb-4">
                    <input type="text" className="form-control" id="floatingStreet" placeholder="straat" required value={street} onChange={(e) => {setStreet(e.target.value)}}/>
                    <label htmlFor="floatingStreet">Straat</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw straat in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-floating mb-4">
                    <input type="text" className="form-control" id="floatingNumber" placeholder="1" required value={nr} onChange={(e) => {setNr(e.target.value)}}/>
                    <label htmlFor="floatingNumber">Nr</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw huisnummer in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <div className="form-floating mb-4">
                    <input type="text" className="form-control" id="floatingZip" placeholder="9000" required value={zip} onChange={(e) => {setZip(e.target.value)}}/>
                    <label htmlFor="floatingZip">Postcode</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw naam in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4">
                    <input type="text" className="form-control" id="floatingCity" placeholder="Gemeente" required value={city} onChange={(e) => {setCity(e.target.value)}}/>
                    <label htmlFor="floatingCity">Gemeente</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw gemeente in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-floating always-up mb-4">
                    <label htmlFor="floatingCountry">Land</label>
                    <div className='select-wrapper'>
                      <select name="country" id="country" className="w-100" value={country} onChange={(e)=> {setCountry(e.target.value);}}>
                        <option value="BE">België</option>
                        <option value="NL">Nederland</option>
                      </select>
                    </div>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw land in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="form-floating mb-4">
                    <input type="text" className="form-control" id="floatingExhibitorPrice" placeholder="100" required value={exhibitorPrice} onChange={(e) => {setExhibitorPrice(e.target.value)}}/>
                    <label htmlFor="floatingExhibitorPrice">Prijs standhouder (in tudors)</label>
                    <Form.Control.Feedback type="invalid" className='mb-5'>
                      Gelieve uw naam in te geven.
                    </Form.Control.Feedback>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>Beloningen</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  {rewards && rewards.length > 0 && rewards.map((r, i) => {
                    return <EventRewardRow index={i} rewards={rewards} setRewards={setRewards} key={i}/>
                  })}
                </Col>
              </Row>
              <Row>
                <Col>
                  <button type="button" className="btn btn-green mb-3" onClick={(e)=> {handleAddReward()}}>Beloning toevoegen</button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>Voorzieningen</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  {services && services.length > 0 && services.map((r, i) => {
                    return <EventServicesRow index={i} services={services} setServices={setServices} key={i}/>
                  })}
                </Col>
              </Row>
              <Row>
                <Col>
                  <button type="button" className="btn btn-green mb-3" onClick={(e)=> {handleAddService()}}>Voorziening toevoegen</button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>VZW producten</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  {nonProfitServices && nonProfitServices.length > 0 && nonProfitServices.map((r, i) => {
                    return <EventServicesRow index={i} services={nonProfitServices} setServices={setnonProfitServices} key={i}/>
                  })}
                </Col>
              </Row>
              <Row>
                <Col>
                  <button type="button" className="btn btn-green mb-3" onClick={(e)=> {handleAddnonProfitServices()}}>Voorziening toevoegen</button>
                </Col>
              </Row>
              <Col>
                  <button type="button" className="btn btn-secondary" onClick={(e)=> {e.preventDefault();history.goBack();}}>Annuleren</button>
                  <button type="submit" className="btn btn-primary mt-2 mt-sm-0 ms-sm-3">Opslaan</button>
              </Col>
          </Form>
        </Container>
    </Fragment>
  );
};

export default AdminAddEventPage;