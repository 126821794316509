import React, { } from 'react';

//import './ErrorLayout.scss';

const ErrorLayout = ({ children }) => {

  return (
    <div className="page-404">
        {children}
    </div>    
  )
};
export default ErrorLayout;