import React, { } from 'react';

const RedirectLayout = ({ children }) => {

  return (
    <div className="page-redirect">
        {children}
    </div>    
  )
};
export default RedirectLayout;