import { default as React, useState, useCallback, useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { FaChevronDown, FaChevronUp, FaTimes, FaEdit } from 'react-icons/fa';
import { useApi } from '../../services';
import Moment from 'react-moment';
import { useHistory } from 'react-router';
import {Link} from 'react-router-dom';
import * as Routes from '../../routes';
import QRCode from 'qrcode.react';
import { ImQrcode } from 'react-icons/im';

const AdminEventsPage = () => {
    const { getEvents } = useApi();
    const history = useHistory();

    const [column, setColumn] = useState('event_start');
    const [searchString, setSearchString] = useState('');
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [events, setEvents] = useState();
    const [eventPaginationData, setEventPaginationData] = useState();
    const [qrData, setQrData] = useState();
    
    const [loadingMore, setLoadingMore] = useState(false);

    const initFetch = useCallback(() => {
        const fetchdata = async () => {
            const response = await getEvents();
            setEventPaginationData(response);
            setEvents(response.data);
            setLoaded(true);
        }
        fetchdata();
	},[ getEvents ]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

    const handleLoadMore = async () => {
        setLoadingMore(true);
        let resp = await getEvents(page+1, column, order?'DESC':'ASC', searchString);
        setPage(page+1);
        setEventPaginationData(resp);
        setEvents([...events, ...resp.data]);
        setLoadingMore(false);
    }

    useEffect(async ()=> {
        handleFilter()
    }, [column, order]);

    const handleFilter = async () => {
        setLoaded(false);
        const resp = await getEvents(1, column, order?'DESC':'ASC', searchString);
        setPage(1);
        setEventPaginationData(resp);
        setEvents(resp.data);
        setLoaded(true);
    }

    const setFilter = (filter) => {
        if(column===filter){
            setOrder(!order); 
        }
        setColumn(filter);
    }

    useEffect(()=>{
		const timeout = setTimeout(() => {
			handleFilter()
		}, 750);
		return () => clearTimeout(timeout);
	}, [searchString])

  return (
      <>
        <div className='card'>
            <Row className="align-items-center mb-4">
                <Col sm={8}><h1>Events beheren</h1></Col>
                <Col sm={4}><Link to={Routes.ADD_EVENT} className="btn btn-primary float-end">event aanmaken</Link></Col>
            </Row>
            <Container fluid>
            {loaded ?
                <>
                    <Row className="align-items-center mb-3">
                        <Col sm={9} className='mb-4'></Col>
                        <Col xs={'auto'} className="text-sm-end">Zoeken:</Col>
                        <Col className="searchbar pe-2">
                            <input className="form-control w-100" value={searchString} 
                                onChange={(e)=> {setSearchString(e.target.value)}}/>
                            {searchString && <span onClick={()=> {setSearchString('');}}>
                                <FaTimes/>
                            </span>}
                        </Col>
                    </Row>
                    {events && events.length > 0 ?
                        <>
                            <div className="row">
                                <table className='admin-table'>
                                    <colgroup>
                                        <col style={{width:"20%"}}></col>
                                        <col style={{width:"25%"}}></col>
                                        <col style={{width:"25%"}}></col>
                                        <col style={{width:"10%"}}></col>
                                        <col style={{width:"10%"}}></col>
                                        <col style={{width:"10%"}}></col>
                                    </colgroup> 
                                    <thead>
                                        <tr>
                                            <th className={`th-filter ${column==='event_name'?'th-filter__active':''}`} onClick={()=> {setFilter('event_name')}}> Naam {column==='event_name' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                            <th className={`th-filter ${column==='event_description'?'th-filter__active':''}`}> Omschrijving {column==='event_description' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                            <th>Adres</th>
                                            <th className={`th-filter text-center ${column==='event_start'?'th-filter__active':''}`} onClick={()=> {setFilter('event_start')}}> Van {column==='event_start' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                            <th onClick={()=> {setFilter('event_end')}} className={`text-center th-filter ${column==='event_end'?'th-filter__active':''}`}> Tot {column==='event_end' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                            <th className='text-center'>Acties</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {events.map((event)=>{
                                            return (
                                                <tr key={event.id} className="tr-clickable">
                                                    <td onClick={()=> {history.push(Routes.EVENT_DETAIL.replace(':id', event.id), event)}}>{event.event_name}</td>
                                                    <td>{event.event_description}</td>
                                                    <td>{event.event_street} {event.event_nr}, {event.event_zip} {event.event_city}</td>
                                                    <td className="text-center"><Moment format="DD/MM/YYYY">{event.event_start}</Moment></td>
                                                    <td className="text-center"><Moment format="DD/MM/YYYY">{event.event_end}</Moment></td>
                                                    <td className="p-0">
                                                        <div className="d-flex flex-row flex-nowrap align-items-center justify-content-around p-0">
                                                            <div onClick={()=> {history.push(Routes.EDIT_EVENT, event)}} className="me-2"><FaEdit className="admin-actions__other"/></div>
                                                            <div onClick={(e)=>{e.preventDefault(); setQrData(event.qr_hash)}} className="me-2"><ImQrcode/></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <Col className="text-center align-middle d-flex mt-4">
                                    {eventPaginationData && eventPaginationData.current_page < eventPaginationData.last_page ? 
                                        loadingMore?
                                        <span className="loadingspinner mx-auto"></span>
                                        :
                                        <button className="btn btn-secondary mx-auto" onClick={()=> {handleLoadMore()}}>
                                            Laad meer events
                                        </button>
                                    :
                                    ''
                                    }
                                </Col>
                            </div>
                        </>
                    :
                        <p>Er werden geen events gevonden voor de opgegeven filters</p>
                    }
                </>
                :
                <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                    <p className="m-auto d-flex align-items-center">Events worden geladen... <span className="loadingspinner d-block ms-3"></span></p>
                </Col>
                }
            </Container>
        </div>
        {qrData && 
            <div className={`qr-wrapper mb-3 ${qrData?'qr-wrapper__active':''}`} onClick={()=>{setQrData(null)}}>
                <QRCode value={qrData} renderAs="svg" fgColor='#1A1A1A'/>
            </div>
        }
      </>
  );
};

export default AdminEventsPage;