import { default as React, useState, useCallback, useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { useApi, useAuth } from '../services';
import Moment from 'react-moment';
import { QrReader } from '@blackbox-vision/react-qr-reader';
import { useHistory } from 'react-router';
import * as Routes from '../routes';
import { useLocation } from 'react-router-dom';

const ActivitiesPage = () => {
    const history = useHistory();

    function useQuery() {
        const { search } = useLocation();     
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();

    const { getActivities, userSelfCheckin } = useApi();
    const { currentUser, setCurrentUser } = useAuth();
    const [isExhibitor] = useState(currentUser.roles.filter((x)=> { return x.name == "Exhibitor"}).length > 0);
    const [activitiesPaginationData, setActivitiesPaginationData] = useState();
    const [activities, setActivities] = useState();
    const [page, setPage] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [showScanner, setShowScanner] = useState(query.get('scan')!= null);
    const [data, setData] = useState();
    const [checkingIn, setCheckingIn] = useState(false);
    const [error, setError] = useState(false);

    const initFetch = useCallback(() => {
      const fetchdata = async () => {
        const response = await getActivities();
        setActivitiesPaginationData(response.activities);
        setActivities(response.activities.data);
        setLoaded(true);
      }
      fetchdata();
	},[ getActivities ]);

	useEffect(() => {
		initFetch();
	}, []);

    const handleLoadMore = async () => {
        setLoadingMore(true);
        let resp = await getActivities(page+1);
        setPage(page+1);
        setActivitiesPaginationData(resp.activities);
        setActivities([...activities, ...resp.activities.data]);
        setLoadingMore(false);
    }

    useEffect(async ()=>{
        if (data) {
            setCheckingIn(true);
            let resp = await userSelfCheckin(data);
            if (resp.ok) {
                let data = await resp.json();
                setActivitiesPaginationData(data.activities);
                setActivities(data.activities.data);
                setCurrentUser(data.user);
                setShowScanner(false);
            } else {
                let data = await resp.json();
                switch (Object.keys(data.errors)[0]) {
                    case 'Subscription':
                        setError('Subscription');
                        break;
                    case 'Code':
                        setError('Code');
                        break;
                    case 'lastCheckin':
                        setError('lastCheckin');
                        break;
                    default:
                        setError(Object.values(data.errors)[0])
                        break;
                }
            }
            setCheckingIn(false);
        }
    }, [data]);

  return (
      <>
        <div className='card'>
            <Row className="align-items-center mb-4">
                <Col sm={8}><h1>Activiteiten</h1></Col>
                {
                    !isExhibitor && 
                    <>
                        {!showScanner ? 
                            <>
                                <Col sm={4}><button className='btn btn-primary float-end' onClick={()=>{setShowScanner(true);setData(null); setError(null)}}> QR scannen </button></Col>
                                <p className='mb-3'>Scan de qr code van een event of standhouder om rewards te verdienen.</p>
                            </>
                        :
                            <>
                                <Col sm={4}>
                                    <button type="button" className="btn btn-secondary float-end" onClick={(e)=> {setError(null); setShowScanner(false);}}>Terug naar activiteiten</button>
                                </Col>
                                <p className='mb-3'>Scan de qr code van een event of standhouder om rewards te verdienen.</p>
                            </>
                        }
                    </>
                }
            </Row>
            <Container fluid>
                {showScanner ? 
                    <>
                        {checkingIn ?
                            <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                                <p className="m-auto d-flex align-items-center">Even geduld, we checken u in... <span className="loadingspinner d-block ms-3"></span></p>
                            </Col>
                        :
                        <>
                            {error?
                                <>
                                    <div className='mb-3'>
                                        {error == 'Code' ?
                                            <p className='error-message'>Geen event of standhouder gevonden met deze qr code.</p>
                                        :
                                            <>
                                                {error == 'lastCheckin' ? 
                                                    <p className='error-message'>U heeft vandaag al ingecheckt op dit event.</p>
                                                :
                                                <>
                                                    {error == 'Exhibitor' ? 
                                                        <p className='error-message'>De standhouder die u heeft gescanned heeft geen actieve events.</p>
                                                    :
                                                        <>
                                                            {error == 'AlreadyScannedExhibitor' ? 
                                                                <p className='error-message'>U heeft deze standhouder al gescanned op dit event.</p>
                                                            :
                                                                <p className='error-message'>{error}</p>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                }
                                            </>
                                        }
                                    </div>

                                    <button type="button" className="btn btn-primary mt-2 mt-sm-0 ms-sm-3" onClick={(e)=> {setData(null); setError(null)}}>Opnieuw scannen</button>
                                </>
                            :
                                <>
                                    {currentUser.active_subscriptions.length == 0 ? 
                                            <Row className='card card-paysubscriptionbanner' onClick={()=>{history.push(Routes.PAY_FOR_SUBSCRIPTION)}}>
                                                <Col>
                                                    Om in te kunnen checken heeft u een abonnement nodig. Klik hier!
                                                </Col>
                                            </Row>
                                        :
                                        <>
                                            <Col lg='6'>
                                                <div className='qr-reader-wrapper'>
                                                    <QrReader
                                                        constraints={{ facingMode: 'environment' }}
                                                        onResult={(result, error) => {
                                                            if (!!result) {
                                                                setData(result?.text);
                                                            }
                                                        }}
                                                        containerStyle={{padding: 0}}
                                                    />
                                                </div>
                                            </Col>
                                        </>
                                    }
                                </>
                            }
                        </>
                        }
                    </>

                :
                    <>
                        {loaded ? 
                            <>
                                {activities && activities.length > 0 ?
                                <>
                                    <div className="row">
                                    <table className='admin-table'>
                                        <colgroup>
                                            <col style={{width:"20%"}}></col>
                                            <col style={{width:"20%"}}></col>
                                            <col style={{width:"40%"}}></col>
                                            <col style={{width:"20%"}}></col>
                                        </colgroup> 
                                        <thead>
                                            <tr>
                                                <th className={`th-filter`}> Datum </th>
                                                <th className={`th-filter`}> Type </th>
                                                <th className={`th-filter`}> Omschrijving </th>
                                                <th className={`th-filter`}> Beloning </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {activities.map((activity,i)=>{
                                                return (
                                                    <tr key={activity.id}>
                                                        <td>
                                                            <Moment format="DD/MM/YYYY HH:mm" tz="Europe/Brussels">{activity.created_at}</Moment> 
                                                        </td>
                                                        <td>
                                                            {activity.type == 'transfer_outgoing'?
                                                                'Tudors gegeven'
                                                            :
                                                                <>
                                                                    {activity.type == 'transfer_incoming'?
                                                                        'Tudors ontvangen'
                                                                    :
                                                                        <>
                                                                            {activity.type == 'goodybag_given'?
                                                                                'Goodybag gegeven'
                                                                            :
                                                                            <>
                                                                                {activity.type == 'goodybag_received'?
                                                                                    'Goodybag ontvangen'
                                                                                :
                                                                                    <>{activity.type}</>
                                                                                }
                                                                            </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </td>
                                                        <td>
                                                            {activity.type == 'check-in' && `Check-in ${activity.checkin_counter} op ${activity.event.event_name}`}
                                                            {activity.type == 'transfer_incoming' && `${activity.transaction.amount} tudors ontvangen van ${activity.transaction.wallet_from.user.company.company_name}`}
                                                            {activity.type == 'transfer_outgoing' && `${activity.transaction.amount} tudors gegeven aan ${activity.transaction.wallet_to.user.firstname} ${activity.transaction.wallet_to.user.name}`}
                                                            {activity.type == 'goodybag_received' && `Goodybag ontvangen van ${activity.goody_target.company.company_name}`}
                                                            {activity.type == 'goodybag_given' && `Goodybag gegeven aan ${activity.goody_target.firstname} ${activity.goody_target.name}`}
                                                        </td>
                                                        <td>
                                                            {activity?.reward?.credits_amount? 
                                                                <>
                                                                    {activity.reward.credits_amount}
                                                                    <img className='ms-1' alt='tudors' src={'/assets/img/coin.svg'} style={{height: '1em'}}></img>
                                                                </>
                                                            : '/'}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <Col className="text-center align-middle d-flex mt-4">
                                        {activitiesPaginationData && activitiesPaginationData.current_page < activitiesPaginationData.last_page ? 
                                            loadingMore?
                                            <span className="loadingspinner mx-auto"></span>
                                            :
                                            <button className="btn btn-secondary mx-auto" onClick={()=> {handleLoadMore()}}>
                                                Laad meer activiteiten
                                            </button>
                                        :
                                        ''
                                        }
                                    </Col>
                                    </div>
                                </>
                                :
                                    <p>U heeft nog geen activiteiten</p>
                                }
                            </>
                        :
                            <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                                <p className="m-auto d-flex align-items-center">Activiteiten worden geladen... <span className="loadingspinner d-block ms-3"></span></p>
                            </Col>
                        }
                    </>
                }
            </Container>
        </div>
      </>
  );
};

export default ActivitiesPage;