import { default as React, Fragment, useEffect } from 'react';
import * as Routes from '../routes';
import { useApi } from '../services';
import { useHistory, useParams } from 'react-router-dom';

const RedirectMolliePage = () => {
    const history = useHistory();
    const { id } = useParams();

    const {handleMollieResponse} = useApi();

    useEffect(async()=> {
        const resp = await handleMollieResponse(id);
        if (resp.ok) {
          history.push(Routes.DASHBOARD);
        }
    }, [])

  return (
    <Fragment>
        <div className="d-flex align-items-center p-3 mx-auto">
          <p>Uw betaling wordt verwerkt </p><span className="loadingspinner d-block ms-3"></span>
        </div>
    </Fragment>
  );
};

export default RedirectMolliePage;