import { default as React, Fragment, useEffect, useState, useRef, useCallback } from 'react';
import {Form, Col, Container, Row} from 'react-bootstrap';
import { useApi, useAuth } from '../services';
import Select from 'react-select'

const ProfilePage = () => {
    const { currentUser, updateCurrentUser } = useAuth();
    const { initProfilePage, updateCurrentUserAndCompany } = useApi();

    const [refreshed, setRefreshed] = useState(false);

    const firstname = useRef();
    const name = useRef();
    const street = useRef();
    const nr = useRef();
    const city = useRef();
    const zip = useRef();
    const phone = useRef();
    const companyName = useRef();
    const companyStreet = useRef();
    const companyNr = useRef();
    const companyZip = useRef();
    const companyCity = useRef();
    const companyScanGift = useRef();
      
    const [country, setCountry] = useState(currentUser.address_country);
    const [companyCountry, setCompanyCountry] = useState(currentUser?.company?.company_country);
    const [companyDescription, setCompanyDescription] = useState(currentUser?.company?.company_description);
    const [updateSuccessfull, setUpdateSuccessfull] = useState(false);
    const [validated, setValidated] = useState(false);
    const [wantsCard, setWantsCard] = useState(currentUser.card == 1);
    const [showError, setShowError] = useState(false);
    const [companyTypes, setCompanyTypes] = useState();
    const [companyType, setCompanyType] = useState(currentUser?.company?.company_type?.id);
    const [error, setError] = useState();
    const [isExhibitor] = useState(currentUser.roles.filter((x)=> { return x.name == "Exhibitor"}).length > 0);

    const initFetch = useCallback(() => {
        const fetchdata = async () => {
            let data = await initProfilePage();
            setCompanyTypes(data.companyTypes)
            setRefreshed(true);
        }
        fetchdata();
    },[ initProfilePage ]);

    useEffect(() => {
        initFetch();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
    
        const form = event.currentTarget;
    
        if (form.checkValidity() !== false) {
            let resp;
            if (isExhibitor) {
                resp = await updateCurrentUserAndCompany(
                    firstname.current.value, 
                    name.current.value, 
                    street.current.value,
                    nr.current.value,
                    city.current.value,
                    zip.current.value,
                    country,
                    phone.current.value,
                    wantsCard,
                    companyName.current.value, 
                    companyStreet.current.value,
                    companyNr.current.value,
                    companyCity.current.value,
                    companyZip.current.value,
                    companyCountry,
                    companyType,
                    companyDescription,
                    companyScanGift.current.value
                );
            } else {
                resp = await updateCurrentUser(
                    firstname.current.value, 
                    name.current.value, 
                    street.current.value,
                    nr.current.value,
                    city.current.value,
                    zip.current.value,
                    country,
                    phone.current.value,
                    wantsCard
                );
            }

          if (resp.ok) {
            setShowError(false);
            setError(null);
            setUpdateSuccessfull(true);
          } else {
            let data = await resp.json(); 
            setError(Object.keys(data.errors)[0]);
            setUpdateSuccessfull(false);
            setShowError(true);
          }
        } else {
          setValidated(true);
        }
    };

  return (
    <Fragment>
        <h1>Profiel</h1>
        <Container fluid className='mt-4 card'>
            {refreshed === true ?
                <Row className="w-100">
                    {updateSuccessfull && 
                        <p className="w-100 text-start mb-3 success-message">
                            Uw gegevens werden succesvol aangepast.
                        </p>
                    }
                    {showError && 
                        <p className="w-100 text-start mb-3 error-message">
                            Opslaan mislukt.
                        </p>
                    }
                    <Form validated={validated} onSubmit={handleSubmit}>
                        <Form.Group>
                            <Row>
                                <Col xs={6}>
                                    <div className="form-floating mb-4">
                                        <input type="text" className={`form-control ${error== 'firstname'? 'is-invalid' : ''}`} id="floatingFirstname" placeholder="voornaam" required ref={firstname} defaultValue={currentUser.firstname}/>
                                        <label htmlFor="floatingFirstname">Voornaam</label>
                                        <Form.Control.Feedback type="invalid" className='mb-5'>
                                            Gelieve uw voornaam in te geven.
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className="form-floating mb-4">
                                        <input type="text" className={`form-control ${error=='name'? 'is-invalid' : ''}`} id="floatingName" placeholder="naam" required ref={name} defaultValue={currentUser.name}/>
                                        <label htmlFor="floatingName">Naam</label>
                                        <Form.Control.Feedback type="invalid" className='mb-5'>
                                            Gelieve uw naam in te geven.
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={9}>
                                    <div className="form-floating mb-4">
                                        <input type="text" className={`form-control ${error=='address_street'? 'is-invalid' : ''}`} id="floatingZip" placeholder="straat" required ref={street} defaultValue={currentUser.address_street}/>
                                        <label htmlFor="floatingZip">Straat</label>
                                        <Form.Control.Feedback type="invalid" className='mb-5'>
                                        Gelieve uw straat in te geven.
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="form-floating mb-4">
                                        <input type="text" className={`form-control ${error== 'address_street_nr'? 'is-invalid' : ''}`} id="floatingNumber" placeholder="+32123456789" required ref={nr} defaultValue={currentUser.address_street_nr}/>
                                        <label htmlFor="floatingNumber">Nr</label>
                                        <Form.Control.Feedback type="invalid" className='mb-5'>
                                        Gelieve uw huisnummer in te geven.
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div className="form-floating mb-4">
                                        <input type="text" className={`form-control ${error== 'address_zip'? 'is-invalid' : ''}`} id="floatingZip" placeholder="9000" required ref={zip} defaultValue={currentUser.address_zip}/>
                                        <label htmlFor="floatingZip">Postcode</label>
                                        <Form.Control.Feedback type="invalid" className='mb-5'>
                                        Gelieve uw naam in te geven.
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-floating mb-4">
                                        <input type="text" className={`form-control ${error== 'address_city' ? 'is-invalid' : ''}`} id="floatingCity" placeholder="Gemeente" required ref={city} defaultValue={currentUser.address_city}/>
                                        <label htmlFor="floatingCity">Gemeente</label>
                                        <Form.Control.Feedback type="invalid" className='mb-5'>
                                        Gelieve uw gemeente in te geven.
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div className="form-floating always-up mb-4">
                                        <label htmlFor="floatingCountry">Land</label>
                                        <div className='select-wrapper'>
                                            <select name="country" id="country" className="w-100" defaultValue={currentUser.address_country} onChange={(e)=> {setCountry(e.target.value);}}>
                                                <option value="BE">België</option>
                                                <option value="NL">Nederland</option>
                                            </select>
                                        </div>
                                        <Form.Control.Feedback type="invalid" className='mb-5'>
                                        Gelieve uw telefoonnummer in te geven.
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-floating mb-4">
                                        <input type="text" className={`form-control ${error== 'phone'? 'is-invalid' : ''}`} id="floatingFirstname" placeholder="+32123456789" required ref={phone} defaultValue={currentUser.phone}/>
                                        <label htmlFor="floatingUsername">Telefoonnummer</label>
                                        <Form.Control.Feedback type="invalid" className='mb-5'>
                                        Gelieve uw telefoonnummer in te geven.
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            </Row>
                            {/* {<Row>
                                <Col>
                                <div className="d-flex flex-row flex-nowrap align-items-center mb-3">
                                    <input className="form-check-input p-0 m-0 flex-shrink-0" type="checkbox" id="wants_card" defaultChecked={wantsCard} onChange={()=>{setWantsCard(!wantsCard)}}/>
                                    <label className="p-0 m-0 ms-2" htmlFor="wants_card">
                                    Ik wens ook een fysieke kaart te ontvangen.
                                    </label>
                                </div>
                                </Col>
                            </Row>} */}
                        </Form.Group>
                        {isExhibitor && 
                            <Form.Group>
                                <Row>
                                    <Col xs={12}><h1>Bedrijf</h1></Col>
                                    <Col xs={6}>
                                        <div className="form-floating mb-4">
                                            <input type="text" className={`form-control ${error== 'company_name'? 'is-invalid' : ''}`} id="floatingCompanyName" placeholder="bedrijfsnaam" required ref={companyName} defaultValue={currentUser.company.company_name}/>
                                            <label htmlFor="floatingCompanyName">Bedrijfsnaam</label>
                                            <Form.Control.Feedback type="invalid" className='mb-5'>
                                                Gelieve uw bedrijfsnaam in te geven.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="form-floating mb-4">
                                            <input type="text" className={`form-control ${error=='vat'? 'is-invalid' : ''}`} id="floatingVat" placeholder="btw-nummer" required defaultValue={currentUser.company.vat} disabled/>
                                            <label htmlFor="floatingVat">BTW-nummer</label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={9}>
                                        <div className="form-floating mb-4">
                                            <input type="text" className={`form-control ${error=='company_street'? 'is-invalid' : ''}`} id="floatingCompanyStreet" placeholder="straat" required ref={companyStreet} defaultValue={currentUser.company.company_street}/>
                                            <label htmlFor="floatingCompanyStreet">Straat</label>
                                            <Form.Control.Feedback type="invalid" className='mb-5'>
                                                Gelieve de straat van uw bedrijf in te geven.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="form-floating mb-4">
                                            <input type="text" className={`form-control ${error== 'company_nr'? 'is-invalid' : ''}`} id="floatingCompanyNumber" placeholder="+32123456789" required ref={companyNr} defaultValue={currentUser.company.company_nr}/>
                                            <label htmlFor="floatingCompanyNumber">Nr</label>
                                            <Form.Control.Feedback type="invalid" className='mb-5'>
                                                Gelieve het huisnummer van uw bedrijf in te geven.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <div className="form-floating mb-4">
                                            <input type="text" className={`form-control ${error== 'address_zip'? 'is-invalid' : ''}`} id="floatingCompanyZip" placeholder="9000" required ref={companyZip} defaultValue={currentUser.company.company_zip}/>
                                            <label htmlFor="floatingCompanyZip">Postcode</label>
                                            <Form.Control.Feedback type="invalid" className='mb-5'>
                                                Gelieve de postcode van uw bedrijf in te geven.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-floating mb-4">
                                            <input type="text" className={`form-control ${error== 'address_city' ? 'is-invalid' : ''}`} id="floatingCompanyCity" placeholder="Gemeente" required ref={companyCity} defaultValue={currentUser.company.company_city}/>
                                            <label htmlFor="floatingCompanyCity">Gemeente</label>
                                            <Form.Control.Feedback type="invalid" className='mb-5'>
                                                Gelieve de gemeente van uw bedrijf in te geven.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <div className="form-floating always-up mb-4">
                                            <label htmlFor="floatingCountry">Land</label>
                                            <div className='select-wrapper'>
                                                <select name="country" id="country" className="w-100" defaultValue={currentUser.company.company_country} onChange={(e)=> {setCompanyCountry(e.target.value);}}>
                                                    <option value="BE">België</option>
                                                    <option value="NL">Nederland</option>
                                                </select>
                                            </div>
                                            <Form.Control.Feedback type="invalid" className='mb-5'>
                                            Gelieve uw telefoonnummer in te geven.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-floating always-up mb-4">
                                            <label htmlFor="companyType">Type bedrijf</label>
                                            <Fragment>
                                                <Select
                                                    classNamePrefix="react-select"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#EBE9EB',
                                                            primary: 'black',
                                                            neutral10: 'black',
                                                        },
                                                    })}
                                                    defaultValue={{value: currentUser?.company?.company_type?.id, label: currentUser?.company?.company_type?.type_name}}
                                                    options={[...companyTypes.map((type, i)=> {
                                                        return {value: type.id, label: type.type_name}
                                                    })]} 
                                                    onChange={(e)=> {
                                                        setCompanyType(e.value);
                                                    }}
                                                />
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{ opacity: 0, height: 0 }}
                                                    value={companyType}
                                                    required={true}
                                                />    
                                            </Fragment>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <div className="form-floating mb-4">
                                            <textarea type="text" className="form-control" id="floatingCompanyDescription" placeholder="Omschrijving" value={companyDescription} onChange={(e) => {setCompanyDescription(e.target.value)}}/>
                                            <label htmlFor="floatingCompanyDescription">Omschrijving bedrijf</label>
                                            <Form.Control.Feedback type="invalid" className='mb-5'>
                                                Gelieve uw omschrijving in te geven.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <div className="form-floating mb-4">
                                            <input type="number" className={`form-control ${error== 'default_gift_on_scan' ? 'is-invalid' : ''}`} id="floatingCompanyDefaultGift" placeholder="1" required ref={companyScanGift} defaultValue={currentUser.company.default_gift_on_scan}/>
                                            <label htmlFor="floatingCompanyDefaultGift">Standaard scan waarde</label>
                                            <Form.Control.Feedback type="invalid" className='mb-5'>
                                                Gelieve een geheel getal van minstens 1 in te geven
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                </Row>
                            </Form.Group>
                        }
                        <Form.Row>
                            <Col>
                                <button type="submit" className="btn btn-primary">Opslaan</button>
                            </Col>
                        </Form.Row>
                    </Form>

                </Row>
            :
            <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                <p className="m-auto d-flex align-items-center">Uw profiel wordt geladen...<span className="loadingspinner d-block ms-3"></span></p>

            </Col>
            }
        </Container>
    </Fragment>
  );
};

export default ProfilePage;