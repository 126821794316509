import { default as React, Fragment, useState, useEffect, useCallback } from 'react';
import {Col, Row, Container} from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { useApi, useAuth } from '../services';
import { PopUp } from '../components';
import * as Routes from '../routes';

const EventDetailPage = () => {
    const {state} = useLocation();
    const history = useHistory();
    let { id } = useParams();

    const {currentUser} = useAuth();
    const {downloadEventOrders, joinEventAsExhibitor, getEvent} = useApi();

    const initFetch = useCallback(() => {
        const fetchdata = async () => {
            if (!state) {
                let data = await getEvent(id);
                setEvent(data);
                setHasAlreadyJoined(isExhibitor && data.exhibitors.filter((x)=> { return x.id == currentUser.id}).length > 0);
                setServices(currentUser?.company?.non_profit == 1 ? data.non_profit_services  : data.services);
                setLocalOrderedServices(data.current_user_ordered_services);
                setOrders(data.orders.filter((o) => {return o.amount > 0}).sort((a, b) => { return a.user_id < b.user_id }))

            } else {
                setEvent(state);
                setHasAlreadyJoined(isExhibitor && state.exhibitors.filter((x)=> { return x.id == currentUser.id}).length > 0);
                setServices(currentUser?.company?.non_profit == 1 ? state.non_profit_services  : state.services);
                setLocalOrderedServices(state.current_user_ordered_services);
                setOrders(state.orders.filter((o) => {return o.amount > 0}).sort((a, b) => { return a.user_id < b.user_id }))
            }
        }
        fetchdata();
    },[ getEvent ]);

    useEffect(() => {
        initFetch();
    }, []);

    const [event, setEvent] = useState();

    const [isAdmin] = useState(currentUser.roles.filter((x)=> { return x.name == "Admin"}).length > 0);
    const [isExhibitor] = useState(currentUser.roles.filter((x)=> { return x.name == "Exhibitor"}).length > 0);
    const [hasSubscription] = useState(currentUser.active_subscriptions.length > 0);
    const [hasAlreadyJoined, setHasAlreadyJoined] = useState();

    const [services, setServices] = useState();
    const [selectedExhibitor, setSelectedExhibitor] = useState();
    const [orders, setOrders] = useState();
    const [showPopup, setShowPopup] = useState(false);
    const [exhibitorsPage, setExhibitorsPage] = useState(0);
    const [joinPopup, setJoinPopup] = useState(false);

    const [wantedServices, setWantedServices] = useState({});
    const [price, setPrice] = useState(0);
    const [disableButton, setSetDisableButton] = useState(false);
    const [localOrderedServices, setLocalOrderedServices] = useState();

    useEffect(()=> {
        if (wantedServices && event) {      
            calcTotalPrice();
        }
    }, [wantedServices]);
    
    const calcTotalPrice = () => {
        let totalPrice = event.exhibitor_entree_price;
        Object.keys(wantedServices).forEach((s)=>{
        totalPrice += (wantedServices[s].price * wantedServices[s].amount);
        })
        setPrice(totalPrice);
    }

    const joinEvent = async () => {
        setSetDisableButton(true);
        setJoinPopup(false);
        if (currentUser.wallet.amount >= price) {
            let resp = await joinEventAsExhibitor(event.id, wantedServices);
            setLocalOrderedServices(resp)
            setHasAlreadyJoined(true);
        } else {
            history.push(Routes.BUY_CREDITS);
        }
    }

    const handleDownload = async () => {
        await downloadEventOrders(event.id);
    }

    return (
        <Fragment>
            {event ? 
                <>
                    <Container fluid className='mt-4'>
                        <Row>
                            
                            <Col md={6}>
                                <div className="card">
                                    <h1>{event.event_name}</h1>
                                    <small className='mb-3'>
                                        {event.event_street}{event.event_nr>0?' ' + event.event_nr:''}, {event.event_zip} {event.event_city} | <Moment format="DD/MM/YY">{event.event_start}</Moment> - <Moment format="DD/MM/YY">{event.event_end}</Moment>
                                    </small>
                                    <p>{event.event_description}</p>
                                    {isExhibitor && hasSubscription && hasAlreadyJoined && localOrderedServices && 
                                    <>
                                        <p className='mt-3'>
                                            U heeft volgende diensten/producten gereserveerd:
                                        </p>
                                        <table className='admin-table'>
                                            <tbody>
                                            {localOrderedServices.map((order, i)=>{
                                                return <tr key={i}>
                                                <td>{order.service.name}</td>
                                                <td>{order.amount}</td>
                                                </tr>
                                            })}
                                            </tbody>
                                        </table>
                                    </>
                                    }
                                </div>
                            </Col>
                            <Col md={6}>
                                <div  className="card">
                                    <Row>
                                        <Col xs={'auto'}>
                                            <h1>Standhouders</h1>
                                        </Col>
                                        {isAdmin && 
                                            <Col xs={'auto'} className="ms-auto">
                                                <button className='btn btn-primary' onClick={()=>{handleDownload()}}>download bestellingen</button>
                                            </Col>
                                        }
                                        {isExhibitor && hasSubscription && event.exhibitor_entree_price != null && !hasAlreadyJoined && 
                                            <Col xs={'auto'} className="ms-auto">
                                                {!disableButton?
                                                    <button className='btn btn-primary' onClick={()=>{setJoinPopup(true)}}>Deelnemen aan event</button>
                                                :
                                                    <span className="loadingspinner d-block mt-3"></span>
                                                }
                                            </Col>
                                        }
                                    </Row>

                                    {event.exhibitors && event.exhibitors.length > 0 && 
                                        <>
                                            <div style={{minHeight: '300px'}}>
                                                <table className='admin-table'>
                                                    <tbody>
                                                        {event.exhibitors.slice(exhibitorsPage*5, exhibitorsPage*5+5).map((exhibitor, i) => {
                                                            return <tr className={isAdmin ?"tr-clickable":''} key={i}>
                                                                        <td onClick={() => {if(isAdmin){setSelectedExhibitor(exhibitor); setShowPopup(true)}}}>{exhibitor.company.company_name}</td>
                                                                    </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {
                                                event.exhibitors.length > 0 && 
                                                    <div className='d-flex flex-row align-items-center pt-3 mt-auto'>
                                                        <button style={{width: 'auto'}} className='btn btn-pagination ms-auto' onClick={()=>{setExhibitorsPage(exhibitorsPage - 1)}} disabled={exhibitorsPage <= 0}>
                                                            {'<'}
                                                        </button>
                                                        <div>
                                                            <p className='mx-2'>
                                                                {exhibitorsPage+1} / {Math.floor(event.exhibitors.length/5)+1}
                                                            </p>
                                                        </div>

                                                        <button style={{width: 'auto'}} className='btn btn-pagination' onClick={()=>{setExhibitorsPage(exhibitorsPage + 1)}} disabled={exhibitorsPage >= Math.floor(event.exhibitors.length/5)}>
                                                            {'>'}
                                                        </button>
                                                    </div>
                                            }
                                        </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {
                        showPopup && 
                        <div className='popup' onClick={(e)=> {setShowPopup(null);}}>
                            <div className={`card`} onClick={(e)=> {e.stopPropagation()}}>
                                <div className='close' onClick={(e)=> {setShowPopup(null);}}>&times;</div>
                                <Row>
                                    {selectedExhibitor &&
                                        <>
                                            <Col xs='12'>
                                                <h3 htmlFor="user">Bestellingen {`${selectedExhibitor.firstname} ${selectedExhibitor.name}`} ({`${selectedExhibitor.company.company_name}`})</h3>
                                                <div className="form-floating mb-4">
                                                    {orders && orders.filter((o) => {return (o.amount > 0 && o.user_id == selectedExhibitor.id)}).length > 0 ? 
                                                        <>
                                                            <table className='admin-table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Naam</th>
                                                                        <th>Bedrijf</th>
                                                                        <th>Product</th>
                                                                        <th>Hoeveelheid</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        orders.filter((o) => {return (o.amount > 0 && o.user_id == selectedExhibitor.id)}).map((o, i)=>{
                                                                            return <tr key={i}>
                                                                                        <td>{o.user.firstname} {o.user.name}</td>
                                                                                        <td>{o.user.company.company_name}</td>
                                                                                        <td>{o.service.name}</td>
                                                                                        <td>{o.amount}</td>
                                                                                    </tr>
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </>
                                                        : 
                                                        'geen producten geselecteerd'
                                                    }
                                                </div>
                                            </Col>
                                        </>
                                    }
                                </Row>
                            </div>
                        </div>
                    }
                    {
                        joinPopup && 
                        <PopUp title='Weet u zeker dat u zich op dit event wil inschrijven als standhouder?' subtitle='Er zullen Tudors uit uw wallet worden genomen om uw staanplaats op het event te betalen. Selecteer hieronder de producten/diensten die u wenst te huren voor het event.' 
                        negativeTitle='annuleren' positiveTitle= 'inschrijven' negativeAction={()=>{setJoinPopup(false)}} positiveAction={()=> {joinEvent()}}>
                            <table className='admin-table'>
                            <thead>
                                <tr>
                                <th>
                                    Naam
                                </th>
                                <th>
                                    Prijs
                                </th>
                                <th>
                                    Aantal beschikbaar
                                </th>
                                <th>
                                    Aantal reserveren
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>Inschrijvingsprijs</td>
                                <td>
                                    <div className='d-flex flex-row flex-nowrap align-items-center'>
                                    <p>
                                        {event.exhibitor_entree_price}
                                    </p>
                                    <img alt='tudors' src={'/assets/img/coin.svg'} className="ms-1" style={{height: '1em'}}></img>
                                    </div>
                                </td>
                                <td></td>
                                <td>1</td>
                                </tr>
                                {services && services.map((s, i) => {
                                return <tr key={i}>
                                    <td>
                                    {s.name}
                                    </td>
                                    <td>
                                    <div className='d-flex flex-row flex-nowrap align-items-center'>
                                        <p>
                                        {s.pivot.price}
                                        </p>
                                        <img alt='tudors' src={'/assets/img/coin.svg'} className="ms-1" style={{height: '1em'}}></img>
                                    </div>
                                    </td>
                                    <td>{s.pivot.amount_available}</td>
                                    <td>
                                    <input type="number" onChange={(e)=> {let temp ={...wantedServices}; temp[s.id] = {price: s.pivot.price, amount: e.target.value}; setWantedServices(temp);}} min={0} max={s.pivot.amount_available} className="form-control" defaultValue={0} required={true} />
                                    </td>
                                </tr>
                                })}
                                <tr>
                                <td><strong>Totaal</strong></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <div className='d-flex flex-row flex-nowrap align-items-center'>
                                    <p>
                                        <strong>{price}</strong>
                                    </p>
                                    <img alt='tudors' src={'/assets/img/coin.svg'} className="ms-1" style={{height: '1em'}}></img>
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </PopUp>
                    }
                </>
            :
                <span className="loadingspinner d-block mt-3"></span>
            }
        </Fragment>
    );
};

export default EventDetailPage;