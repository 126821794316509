import { default as React } from 'react';
import Moment from 'react-moment';
import * as Routes from '../../routes';

const Footer = ({}) => {

  return (
    <footer>
        <div className="copyright">
          © 2021 -  <Moment format="YYYY">{new Date()}</Moment>  Tudor and Friends | <a href="https://vulpo.be/" target="_blank">Made by Vulpo</a> | <a href={Routes.PRIVACY} target="_blank">Privacy policy</a>
        </div>
    </footer>
  );
};

export default Footer;