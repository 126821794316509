import { default as React, Fragment } from 'react';

const PrivacyPolicyPage = () => {

  return (
    <Fragment>
      <div className='card card-privacy'>
        <h1>
          PRIVACY POLICY
        </h1>
        <h2>
        Versie 1.0
        </h2>
        <p>
          Datum: 8 april 2022
        </p>
        <h3>
          1. Identiteit en contactgegevens van de verwerkingsverantwoordelijke
        </h3>
        <p>
          Deze Privacy Policy is van toepassing op alle verwerkingen van persoonsgegevens verricht door TUDOR AND FRIENDS vzw, met maatschappelijke zetel te Rijselstraat 148, 8200 Brugge, en met ondernemingsnummer BTW BE	0680.468.064, die de verwerkingsverantwoordelijke is van de website <a href='www.doggyparadise.events' target="_blank">www.doggyparadise.events</a> en haar ledenplatform <a href='https://app.doggyparadise.events/' target="_blank">Doggy Paradise</a> (hierna ‘wij’).
        </p>

        <p>
          Wij hechten veel belang aan uw privacy en verwerken uw persoonsgegevens dan ook conform de Europese Verordening 2016/679 van 27 april 2016 betreffende de bescherming van natuurlijke personen in verband met de verwerking van persoonsgegevens (hierna “GDPR”) alsook iedere toekomstige of aanvullende wetgeving in uitvoering daarvan, voor zover van toepassing. 
        </p>

        <p>
          Voor verdere vragen of opmerkingen in verband met de manier waarop wij omgaan met uw persoonsgegevens kan u ons steeds contacteren, hetzij per e-mail naar <a href='mailto:tudor.the.labrador@gmail.com'>tudor.the.labrador@gmail.com</a>  of per post naar voormeld postadres. 
        </p>
        <h3>
          2. Wat betekent “persoonsgegevens verwerken”?
        </h3>
        
        <p>
          Het verwerken van persoonsgegevens (hierna “gegevens”) omvat elke verwerking van gegevens die u als natuurlijke persoon kunnen identificeren. Over welke gegevens het gaat, leest u in deze Privacy Policy. Het begrip ‘verwerken’ is zeer ruim en dekt onder andere het verzamelen, bewaren, gebruiken van uw gegevens, of het delen hiervan met derden. 
        </p>

        <h3>
          3. Welke gegevens verwerken wij?
        </h3>
        
        <p>
          Hieronder verduidelijken wij welke gegevens wij van u kunnen verwerken. Afhankelijk van de concrete situatie, uw voorkeuren en de manier waarop u ons contacteert, verwerken wij niet alle onderstaande gegevens van u. 
        </p>
        <strong>
        Algemeen
        </strong>
        <p>
          Van al onze contacten verwerken wij mogelijk onderstaande gegevens:
        </p>
        <ul>
          <li>
            Elektronische identificatie- en gebruiksgegevens (bv. IP-adres, browsertype);
          </li>
          <li>
            Identificatiegegevens;
          </li>
          <li>
            Contactgegevens (bv. naam, voornaam, adres, e-mailadres, etc.);
          </li>
          <li>
            Contactgeschiedenis (bv. e-mailberichten, berichten verstuurd via webformulieren, etc.); 
          </li>
        </ul>
        <strong>
          Hoofdactiviteit
        </strong>

        <p>
          Van onze leden en bezoekers van onze evenementen en vestigingen in de context van onze activiteiten als organisatie voor hondenliefhebbers, verwerken wij bijkomend mogelijk onderstaande gegevens:
        </p>
        <ul>
          <li>
            Accountgegevens voor ons ledenplatform ‘<a href='https://app.doggyparadise.events/' target="_blank">Doggy Paradise</a>’ (bv. logingegevens);
          </li>
          <li>
          Gegevens met betrekking tot uw honden (bv. naam, ras);
          </li>
          <li>
          Gegevens in functie van uw bezoeken aan onze evenementen of vestigingen (bv. registratie bezoek, lidkaart, gegevens in functie van ter plaatse georganiseerde wedstrijden en dergelijke, etc.)
          </li>
          <li>
          Bestel- en betalingsgegevens en aftersale gegevens (bv. betaalkaartgegevens, facturen, beheer van uw abonnement, etc.);
          </li>
          <li>
          Feedback, testimonials en promotionele content zoals foto’s en video’s (bv. aftermovies van onze evenementen);
          </li>
          <li>
          Gegevens met betrekking tot het gebruik van het platform en de activiteiten op onze events;
          </li>
        </ul>

        <strong>
          Zakenpartners – dienstverleners
        </strong>

        <p>
          Van onze dienstverleners en zakenpatners, verwerken wij bijkomend mogelijk onderstaande gegevens:
        </p>

        <ul>
          <li>
            Contractuele gegevens (bv. bedrijfsnaam, adres, BTW-nummer, overeenkomst, etc.);
          </li>
          <li>
            Betalings- en facturatiegegevens (bv. betaalkaartgegevens, facturen, etc.);
          </li>
          <li>
            Accountgegevens voor ons ledenplatform ‘<a href='https://app.doggyparadise.events/' target="_blank">Doggy Paradise</a>’ (bv. logingegevens);
          </li>
          <li>
            Feedback, testimonials en promotionele content zoals foto’s en video’s (bv. aftermovies van onze evenementen, vermelding aanwezigheid als standhouder op evenementen)
          </li>
          <li>
            Gegevens met betrekking tot het gebruik van het platform en de activiteiten op de events
          </li>
        </ul>

        <strong>
          Kandidaat-medewerkers
        </strong>

        <p>
          Van kandidaat-medewerkers verwerken wij bijkomend mogelijk onderstaande gegevens. Uiteraard zal dit in grote mate afhankelijk zijn van welke gegevens u zelf aan ons wenst te verstrekken in functie van uw sollicitatie. 
        </p>
        
        <ul>
          <li>
            Persoonlijke bijzonderheden;
          </li>
          <li>
            Werkgerelateerde gegevens;
          </li>
          <li>
            Persoonlijkheidsgegevens;
          </li>
          <li>
            Foto’s
          </li>
        </ul>

        <h3>
          4. Voor welke doeleinden verwerken wij uw gegevens?
        </h3>

        <p>
          Wij kunnen uw gegevens verwerken voor de hieronder beschreven doeleinden en verwerken uitsluitend de gegevens die nodig zijn voor de verwezenlijking van deze doeleinden. 
        </p>

        <strong>
          Operationele doeleinden
        </strong>
        <p>
          Wij verwerken gegevens voor bijvoorbeeld de optimalisatie van onze website en ons ledenplatform; statistische doeleinden en marktonderzoek; om onze diensten gebruiksvriendelijk te houden; het beantwoorden van uw contactvraag; het in overweging nemen van uw sollicitatie.
        </p>
        <strong>
          Zakelijke doeleinden
        </strong>
        <p>
          Wij verwerken bijvoorbeeld gegevens in het kader van onze hoofdactiviteiten zoals om onze evenementen te organiseren en u als lid of zakenpartner een account te verschaffen voor ons ledenplatform met het oog op een optimale dienstverlening en bijkomende functionaliteiten en voordelen (voor particuliere leden bv. het inchecken op evenementen, registreren van uw honden, goodies en andere voordelen zoals kortingen bij onze partners, voor zakenpartners bv. het reserveren van standplaatsen, bezoekersstatistieken, etc.); om met u te communiceren over onze dienstverlening of samenwerking; om u te informeren over ons beleid en algemene voorwaarden; om de relaties met onze huidige en potentiële nieuwe contacten te beheren en verder uit te bouwen. 
        </p>
        <strong>
          Commerciële doeleinden
        </strong>
        <p>
          Wij verwerken gegevens voor het toesturen van marketingcommunicaties zoals nieuwsbrieven via ons ledenplatform, via e-mail, of per post. Deze communicaties kunnen onze eigen activiteiten, diensten en/of producten betreffen of voor zover je particulier lid bent, ook deze van onze professionele leden die als onderneming actief zijn in de hondensector. Aangezien wij deze communicaties steeds zelf verzorgen, krijgen onze zakenpartners hierbij geen inzage in uw contactgegevens. U kan zich steeds uitschrijven voor enige marketingcommunicaties door gebruik te maken van de uitschrijflink in elk dergelijk bericht of door uw voorkeuren hieromtrent aan te passen op ons ledenplatform.
        </p>
        <strong>
          Juridische of wettelijke doeleinden
        </strong>
        <p>
          Wij verwerken gegevens voor bijvoorbeeld juridische redenen en procedures; om te voldoen aan wetgeving en overheidsbevelen; of om te kunnen voldoen aan onze interne en externe auditeisen, informatieveiligheid of ter bescherming of uitoefening van onze eigen rechten (bv. privacy, eigendom) of die van andere personen.
        </p>
        
        <h3>
          Op basis van welke rechtsgronden verwerken wij uw gegevens?
        </h3>
        <p>
          Wij verwerken uw gegevens voor bovenvermelde doeleinden enkel voor zover gebaseerd op één van de rechtsgronden zoals opgesomd in de GDPR. Hieronder verduidelijken wij op welke rechtsgronden wij ons concreet beroepen om uw gegevens te verwerken. 
        </p>
        
        <strong>
          Wettelijke verplichting
        </strong>
        <p>
          Wij verwerken bepaalde van uw gegevens om te voldoen aan wettelijke of reglementaire verplichtingen die op ons rusten als onderneming. Bijvoorbeeld op fiscaal of boekhoudkundig gebied, of op het gebied van consumentenbescherming of gegevensbescherming.
        </p>

        <strong>
          Noodzaak voor de totstandkoming of uitvoering van een overeenkomst
        </strong>
        <p>
          Wij verwerken bepaalde van uw gegevens omdat dit noodzakelijk is voor het aangaan, uitvoeren of beëindigen van een overeenkomst met u als betrokkene. Wij verwerken de gegevens van onze leden en bezoekers voornamelijk op contractuele basis, om onze diensten te kunnen verlenen en u te kunnen ontvangen op onze evenementen en in onze vestigingen. Bij aanmelding via ons ledenplatform zal u dan ook steeds onze algemene voorwaarden dienen te aanvaarden.
        </p>
        <strong>
          Gerechtvaardigde belangen
        </strong>
        <p>
          Wij verwerken bepaalde van uw gegevens op basis van onze gerechtvaardigde belangen die in specifieke gevallen zwaarder wegen dan enig mogelijk nadeel voor uw rechten. Bijvoorbeeld voor het promoten van onze activiteiten ten aanzien van onze huidige leden; het verbeteren van de kwaliteit van onze activiteiten en dienstverlening; het opleiden van medewerkers en het evalueren en bijhouden van gegevens en statistieken die betrekking hebben op onze activiteiten, in de ruime zin; het bewaren en gebruiken van bewijsmateriaal in het kader van aansprakelijkheid, procedures of geschillen en met het oogpunt op archivering van de activiteiten; en het garanderen van de veiligheid, zowel online op onze website en ons ledenplatform, als in en rond onze fysieke vestigingen.
        </p>

        <strong>
          Toestemming
        </strong>
        <p>
          Wij verwerken bepaalde van uw gegevens op basis van uw toestemming. Bijvoorbeeld voor het promoten van activiteiten ten aanzien van potentiële nieuwe contacten waar vereist; het plaatsen van foto’s en video’s, voor zover u hierop gericht en herkenbaar in beeld komt, op onze website, ons ledenplatform en onze pagina’s op sociale netwerken; of de publicatie van andere promotionele inhoud waarvoor uw toestemming vereist is. Ook gegevens van sollicitanten worden na afloop van de aanwervingsprocedure enkel verder bijgehouden mits toestemming. 
        </p>
        <p>
          In het geval wij uw gegevens verwerken op basis van uw toestemming, kan u deze toestemming steeds terug intrekken door ons te contacteren.
        </p>

        <h3>
          6. Oorsprong van de gegevens
        </h3>
        <p>
          De meeste gegevens die wij van u verwerken, hebben we ook rechtstreeks van u verkregen, naar aanleiding van uw registratie voor ons ledenplatform dan wel door rechtstreeks contact met onze onderneming. Binnen het kader van onze activiteiten en dienstverlening is het mogelijk dat wij in beperkte mate gegevens van u verkrijgen via externe dienstverleners of openbare bronnen.
        </p>
        <h3>
          Met wie delen wij uw gegevens?
        </h3>
        <p>
          Wij geven uw gegevens niet door aan derden, tenzij wanneer dit strikt noodzakelijk is in het licht van hogervermelde doeleinden, of wanneer wij daartoe wettelijk verplicht zijn.
        </p>
        <p>
          Waar nodig doen wij een beroep op externe dienstverleners (verwerkers), ter ondersteuning van onze operationele doeleinden zoals het beheer van onze website, ons ledenplatform en onze IT-systemen. Deze externe dienstverleners voeren desgevallend bepaalde verwerkingen van gegevens uit namens ons. Wij zullen uw gegevens slechts delen met deze externe dienstverleners in de mate dat dit nodig is voor het desbetreffende doel. De gegevens mogen door hen niet voor andere doeleinden worden gebruikt. Bovendien zijn deze dienstverleners contractueel gebonden om de vertrouwelijkheid van uw gegevens te waarborgen door middel van een “verwerkersovereenkomst” die met hen werd gesloten.  
        </p>
        <p>
          Concreet heeft dit tot gevolg dat wij uw gegevens, voor zover relevant in uw situatie, met de volgende derden delen voor de volgende doeleinden, waarbij deze derden in bepaalde gevallen optreden als verwerkers namens ons:
        </p>
        <ul>
          <li>
          Postbedrijven, transport- en bezorgbedrijven indien wij u iets moeten toezenden per post; 
          </li>
          <li>
          Betalingsdienstverleners indien wij betalingen van u ontvangen, of omgekeerd; 
          </li>
          <li>
          Zelfstandige medewerkers, externe vertegenwoordigers of enige andere partijen die professioneel betrokken zijn in het kader van onze hoofd- of nevenactiviteiten;
          </li>
          <li>
          De verwerkers die ons op IT-gebied bijstaan bij het uitbaten van onze organisatie, met het oog op een veilig en efficiënt digitaal gegevensbeheer binnen onze organisatie; 
          </li>
          <li>
          Overheidsorganen, gerechtelijke instanties en beoefenaars van gereglementeerde beroepen zoals boekhouders en advocaten, met het oog op de naleving van onze wettelijke verplichtingen en de uitoefening van onze rechten of verdediging van onze belangen, voor zover vereist.
          </li>
        </ul>

        <h3>
          8. Hoe lang bewaren wij uw gegevens?
        </h3>
        <p>
          Wij bewaren uw gegevens niet langer dan nodig is voor het doel waarvoor de gegevens zijn verzameld en verder worden verwerkt. Aangezien de termijn waarvoor wij uw gegevens kunnen bewaren, afhangt van de doeleinden waarvoor de gegevens zijn verzameld, kan de opslagperiode in elke situatie variëren. Soms zal specifieke wetgeving ons verplichten de gegevens bij te houden voor een bepaalde periode. Onze bewaartermijnen zijn steeds gebaseerd op wettelijke vereisten en een afweging van uw rechten en verwachtingen met hetgeen nuttig en nodig is voor het vervullen van de doeleinden. Na afloop van de relevante bewaartermijnen, worden uw gegevens gewist of geanonimiseerd.  
        </p>

        <h3>
          9. Waar bewaren wij uw gegevens en hoe worden deze beschermd?
        </h3>
        <p>
          Wij voorzien passende veiligheidsmaatregelen op technisch en organisatorisch vlak, om binnen het kader van onze activiteiten de vernietiging, het verlies, de vervalsing, de wijziging, de onbevoegde toegang door of onbedoelde kennisgeving aan derden te vermijden alsook elke andere niet-toegestane verwerking van uw gegevens. 
        </p>
        <p>
          Daarnaast hebben wij er aandacht voor dat de verwerkers op wie wij beroep doen eveneens passende veiligheidsmaatregelen treffen om risico’s op incidenten zoveel als mogelijk te beperken.
        </p>
        <p>
          Wij streven ernaar om uw gegevens zoveel als mogelijk binnen de Europese Economische Ruimte (EER) te verwerken. Indien uw gegevens bij het gebruik van specifieke diensten of softwaretools, buiten de Europese Economische Ruimte (EER) verwerkt worden, zal dit uitsluitend gebeuren in/naar landen waarvan de Europese Commissie heeft bevestigd dat ze een passend beschermingsniveau van uw gegevens waarborgen, of zullen er maatregelen genomen worden om de rechtmatige verwerking van uw gegevens in deze derde landen te verzekeren.
        </p>
        <h3>
          10. Wat zijn uw rechten en hoe kan u deze uitoefenen?
        </h3>
        <p>
          U beschikt over de hieronder omschreven rechten met betrekking tot de gegevens die wij van u verwerken. Indien u een beroep wenst te doen op de hiernavolgende rechten, gelieve ons te contacteren via de contactgegevens opgenomen onder de eerste titel van deze Privacy Policy. Indien nodig, zullen wij u hierbij verzoeken om documenten te verstrekken die ons toelaten om uw identiteit te verifiëren, alvorens gevolg te geven aan uw verzoek.
        </p>

        <strong>
          Recht van inzage en kopie
        </strong>
        <p>
          U heeft het recht om uw gegevens in te zien en hiervan een kopie te krijgen. Onder dit recht valt ook de mogelijkheid om verdere inlichtingen te vragen omtrent de verwerking van uw gegevens, onder meer wat betreft de categorieën van gegevens die van u verwerkt worden en voor welke doeleinden dit gebeurt.
        </p>
        
        <strong>
          Recht op aanpassing of rectificatie
        </strong>
        <p>
          U heeft het recht om uw gegevens te laten aanpassen indien u meent dat wij beschikken over onjuiste gegevens. Uw accountgegevens voor ons ledenplatform kan u zelf aanpassen door naar het tabblad ‘Profiel’ te gaan, de gewenste gegevens aan te passen en vervolgens op ‘Opslaan’ te klikken. Uw wachtwoord kan u aanpassen door onderaan het inlogscherm op ‘Wachtwoord vergeten?’ te klikken, waarna u een e-mail zal ontvangen met een link waarmee u een nieuw wachtwoord kan instellen.
        </p>

        <strong>
          Recht van gegevensuitwissing (recht op vergetelheid)
        </strong>
        <p>
          U heeft het recht om te vragen dat wij uw gegevens zonder onredelijke vertraging wissen. Wij zullen echter niet altijd in staat zijn om een dergelijk verzoek in te willigen, onder meer wanneer wij de gegevens nog nodig hebben in functie van een lopende overeenkomst, of wanneer het bijhouden van bepaalde van uw gegevens gedurende een bepaalde termijn wettelijk verplicht is.
        </p>

        <strong>
          Recht op beperking van verwerking
        </strong>
        <p>
          U heeft het recht om de verwerking van uw gegevens te beperken. Op die manier wordt de verwerking tijdelijk stopgezet tot er bijvoorbeeld zekerheid is over de juistheid ervan.
        </p>

        <strong>
          Recht op het intrekken van uw toestemming
        </strong>
        <p>
          Wanneer de verwerking is gebaseerd op uw toestemming (zie hierboven onder titel 5), dan heeft u het recht om deze toestemming op elk moment terug in te trekken door ons te contacteren. Voor elektronische marketingberichten die u van ons ontvangt op basis van uw toestemming, kan u deze toestemming eenvoudig intrekken door op de uitschrijflink te klikken in een dergelijk bericht.
        </p>

        <strong>
          Recht van bezwaar
        </strong>
        <p>
          U heeft het recht om bezwaar te maken tegen de verwerking van uw gegevens die is gebaseerd op de rechtsgrond van het gerechtvaardigd belang (zie hierboven onder titel 5). Dit dient te gebeuren op basis van specifieke met uw situatie verband houdende redenen. U kan eveneens algemeen bezwaar maken tegen het gebruik van uw gegevens ten behoeve van direct marketing, zonder opgave van redenen. Tegen elektronische marketingberichten die u van ons ontvangt, kan u eenvoudig bezwaar maken door op de uitschrijflink te klikken in een dergelijk bericht.
        </p>

        <strong>
          Recht op overdraagbaarheid
        </strong>
        <p>
          U heeft het recht om uw gegevens die u zelf aan ons verstrekt heeft met uw toestemming of in uitvoering van een overeenkomst, in elektronische vorm te verkrijgen. Op die manier kunnen ze makkelijk aan een andere organisatie worden overgedragen. U heeft bovendien het recht om ons te verzoeken om uw gegevens rechtstreeks over te dragen aan een andere organisatie, indien dit technisch mogelijk is.
        </p>

        <strong>
          Recht om klacht in te dienen bij uw toezichthoudende autoriteit
        </strong>
        <p>
          Indien u van mening zou zijn dat wij uw gegevens op een onjuiste manier verwerken, beschikt u steeds over het recht om een klacht in te dienen bij uw toezichthoudende autoriteit op het gebied van gegevensbescherming. Aangezien wij als organisatie gevestigd zijn in België, verwijzen wij hieronder naar de contactgegevens van de Belgische toezichthoudende autoriteit.
        </p>

        <p>
        Belgische Gegevensbeschermingsautoriteit (GBA)<br/>
        Drukpersstraat 35<br/>
        1000 Brussel<br/>
        <a href="mailto:contact@apd-gba.be">contact@apd-gba.be</a>
        </p>

        <h3>
          11. Wijzigingen
        </h3>
        <p>
          Wij behouden ons het recht voor om deze Privacy Policy te wijzigen. De meest recente versie is te allen tijde beschikbaar op onze website en ons ledenplatform. De datum waarop deze Privacy Policy voor het laatst gewijzigd is, kan u bovenaan terugvinden. In geval van een substantiële wijziging van de Privacy Policy, zullen wij de betrokkenen op wie dit een impact kan hebben, hiervan zo mogelijk rechtstreeks informeren.
        </p>
      </div>
    </Fragment>
  );
};

export default PrivacyPolicyPage;