import { default as React, Fragment, useEffect, useState, useRef } from 'react';
import {Col, Row, Form} from 'react-bootstrap';
import * as Routes from '../../routes';
import { useAuth } from '../../services';
import { useHistory, useParams } from 'react-router-dom';

const ResetPasswordPage = () => {
  const history = useHistory();
  const {token} = useParams();

  const {resetPassword, currentUser} = useAuth();

  const email = useRef();
  const password = useRef();
  const repeatpassword = useRef();
    
  const [validated, setValidated] = useState(false);
  const [alreadyUsedEmail, setAlreadyUsedEmail] = useState(false);

  useEffect(() => {
    if (!token) {
      history.push(Routes.LOGIN);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity() !== false) {
      const resp = await resetPassword(token, email.current.value, password.current.value, repeatpassword.current.value);
      if (resp.ok) {
        history.push(Routes.DASHBOARD);
      } else {
        setAlreadyUsedEmail(true);
      }
    } else {
      setValidated(true);
    }
  };

  return (
    <Fragment>
      <Form className='card card-auth' noValidate validated={validated} onSubmit={handleSubmit} onInput={()=>{repeatpassword.current.setCustomValidity(repeatpassword.current.value != password.current.value ? "Passwords do not match." : "")}}>
        <h2 className="mb-3">Stel een nieuw wachtwoord in</h2>
          <Form.Group>
            <div className="form-floating mb-4">
              <input type="email" className="form-control" id="floatingEmail" placeholder="name@example.com" defaultValue={!!currentUser?currentUser.email:''} required ref={email}/>
              <label htmlFor="floatingEmail">E-mailadres</label>
              <Form.Control.Feedback type="invalid" className='mb-5'>
                Gelieve de e-mailadres in te geven.
              </Form.Control.Feedback>
            </div>
          </Form.Group>
          <Form.Group>
            <div className="form-floating mb-4">
              <input type="password" pattern='.{8,20}' className="form-control" id="floatingPassword" placeholder="wachtwoord" required ref={password}/>
              <label htmlFor="floatingPassword">Wachtwoord</label>
              <Form.Control.Feedback type="invalid" className='mb-5'>
                Gelieve een wachtwoord in te geven tussen 8 en 20 karakters.
              </Form.Control.Feedback>
            </div>
          </Form.Group>
          <Form.Group>
            <div className="form-floating mb-3">
              <input type="password" pattern='.{8,20}' className="form-control" id="floatingPasswordRepeat" placeholder="wachtwoord" required ref={repeatpassword}/>
              <label htmlFor="floatingPasswordRepeat">Herhaal Wachtwoord</label>
              <Form.Control.Feedback type="invalid" className='mb-5'>
                Gelieve uw wachtwoord correct te herhalen.
              </Form.Control.Feedback>
            </div>
          </Form.Group>
          {alreadyUsedEmail && 
          <Form.Row className="w-100 text-start mb-4 error-message">
            <p className="w-100 text-center mb-3 error-message form-row">
              Er liep iets mis bij het resetten van het wachtwoord of het gebruikte e-mailadres vroeg geen reset aan.
            </p>
          </Form.Row>}
      <Form.Row>
      <Row className="flex-row-reverse">
            <Col sm={6}>
                <button type="submit" className="btn btn-primary w-100 mb-3 mb-sm-0">Wachtwoord resetten</button>
            </Col>
            <Col sm={6}>
                <button className="btn btn-secondary w-100" onClick={()=> {history.push(Routes.LOGIN)}}>Annuleren</button>
            </Col>
          </Row>      </Form.Row>
    </Form>
  </Fragment>
  );
};

export default ResetPasswordPage;