

import { default as React} from 'react';

const StatsCard = ({number, text, extraClasses=''}) => {
  return (
    <>
        <div className={`card card-stats pum-trigger ${extraClasses}`}>
            <div className="statsNumber">
                <h3 className='mx-auto'>{number}</h3>
            </div>
            <p>{text}</p>
        </div>
    </>
  );
};

export default StatsCard;