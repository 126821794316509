import { default as React, useEffect, useState } from 'react';
import { Route as ReactRoute, Redirect} from 'react-router';
import {useHistory} from 'react-router-dom';
import { useAuth } from '../services';
import * as Routes from '../routes';

const renderMergedProps = (component, layout, routeProps) => {
  return (layout) ? React.createElement(layout, routeProps, React.createElement(component, routeProps)) : React.createElement(component, routeProps);
};

const UnAuthRouteWithLayout = ({ component, layout, ...rest }) => {
  const history = useHistory();
  const [checkedLoggedIn, setCheckedLoggedIn]= useState(false);

  const {bearerToken, currentUser, refreshCurrentUser} = useAuth();

  useEffect(async ()=> {
    if (bearerToken && !currentUser) {
      await refreshCurrentUser();
      history.push(Routes.DASHBOARD)
    }
    setCheckedLoggedIn(true);
  }, []);

  return (
    <>
        {!!currentUser?
          <Redirect to={Routes.DASHBOARD}/>
        :
        <>
        {checkedLoggedIn && <ReactRoute {...rest} render={routeProps => {
          return renderMergedProps(component, layout, routeProps);
        }} />}
        </>
        }
    </>

  );
};

export default UnAuthRouteWithLayout;