import React, { } from 'react';

const PublicLayout = ({ children }) => {

  return (
    <div className="page-public">
        <p className='page-public__slogan'>word lid van de leukste roedel van het land</p>
        {children}
    </div>    
  )
};
export default PublicLayout;