import { default as React, Fragment, useState, useRef } from 'react';
import {Form} from 'react-bootstrap';
import * as Routes from '../../routes';
import { useAuth } from '../../services';
import { Link, useHistory } from 'react-router-dom';

const LoginPage = () => {
  const { facebookResponse, googleResponse, signIn } = useAuth();
  const history = useHistory();

  const username = useRef();
  const password = useRef();
    
  const [validated, setValidated] = useState(false);
  const [unknown, setUnknown] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() !== false) {
      const resp = await signIn(username.current.value, password.current.value);
      if (resp.user && resp.token) {
        history.push(Routes.DASHBOARD);
      } else if(resp){
        setUnknown(true);
      }
    } else {
      setValidated(true);
    }
  };

  return (
    <Fragment>
      <Form className='card card-auth' noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group>
            <div className="form-floating mb-4">
              <input type="email" className="form-control" id="floatingEmail" placeholder="name@example.com" required ref={username}/>
              <label htmlFor="floatingEmail">E-mailadres</label>
              <Form.Control.Feedback type="invalid" className='mb-5'>
                Gelieve een correcte email in te geven.
              </Form.Control.Feedback>
            </div>
        </Form.Group>
        <Form.Group>
          <div className="form-floating mb-3">
            <input type="password" className="form-control" id="floatingPassword" placeholder="wachtwoord" required ref={password}/>
            <label htmlFor="floatingPassword">Wachtwoord</label>
          </div>
        </Form.Group>
        {unknown && <Form.Row className="w-100 text-center mb-3 error-message">
          Het opgegeven e-mailadres of wachtwoord is incorrect
        </Form.Row>}
        <Form.Row className="d-flex flex-column flex-xl-row align-items-center mb-3">
            <Link to={Routes.FORGOT_PASSWORD} className="mt-2 mt-xl-0 w-100 text-center"><p>wachtwoord vergeten?</p></Link>
        </Form.Row>
        <Form.Row className="d-flex flex-column flex-xl-row align-items-center mb-3">
            <button type="submit" className="btn btn-primary mx-auto w-100">Aanmelden</button>
        </Form.Row>
        <hr></hr>
        <Form.Row className="align-items-center justify-content-between flex-column">
          <Link to={Routes.REGISTER} className="btn btn-secondary mb-3 mt-xl-0 w-100">registreer</Link>
        </Form.Row>
      </Form>
    </Fragment>
  );
};

export default LoginPage;