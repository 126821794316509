export const formatDistance = (number, withSI = true) => {
    let result;
    if (number >= 1000) {
        if ((number/1000).toFixed(1)- Math.floor(number/1000) == 0) {
            result = (number/1000) + (withSI?'km':'');
        } else {
            result = (number/1000).toFixed(1) + (withSI?'km':'');
        }
    } else {
        result = number + (withSI?'m':'');
    }
    return result;
};

export const formatMoney = (amount) => {
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(amount);
};

export const dateTimeToUTC = (dateString) => {
    var date = new Date(dateString); 
    var now_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),
     date.getHours(), date.getMinutes(), date.getSeconds());
     let result = new Date(now_utc);
     return result;
};
