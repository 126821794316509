import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ labels, data, title=''}) => {
    let options = {
        responsive: true,
        scales: {
            y: {
              beginAtZero: true
            },
          },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: title,
            },
        },
        aspectRatio: 1.5
    };

    const graphData = {
        labels,
        datasets: [
            {
                label: title,
                data: data,
                backgroundColor: '#288F93',
            }
        ]
    };

  return <Bar options={options} data={graphData}/>;
}

export default BarChart;
