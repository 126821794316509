import { default as React, useState } from 'react';
import { Footer, Header, Nav } from '../components';

const PageLayout = ({children}) => {
  const [openSideNav, setOpenSideNav] = useState(window.innerWidth>1200?true: false);
  const [openProfileActions, setOpenProfileActions] = useState(false);

  return (
    <div className="page" onClick={()=>{if (openProfileActions) {
      setOpenProfileActions(false);
    }}}>
      <div className="page-wrapper">
        <Nav openSideNav={openSideNav} setOpenSideNav={setOpenSideNav}/>
        <div className="page-content" onClick={()=> {if (openSideNav && window.innerWidth<1200) {
          setOpenSideNav(false);
        }}}>
          <Header sideNavStatus={openSideNav} 
            openSideNav={()=>{setOpenSideNav(!openSideNav)}} 
            openProfileActions={openProfileActions} 
            setOpenProfileActions={()=>{setOpenProfileActions(!openProfileActions)}}
          />
          <main>
            {children}
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default PageLayout;