import { default as React, Fragment, useState, useEffect } from 'react';
import { useApi } from '../services';

const ContinuePaymentPage = () => {
    const { initContinuePage } = useApi();
    const [loaded] = useState(false);
    
    useEffect(async ()=> {
        const resp = await initContinuePage();
        window.location = resp.url;
    }, []);

  return (
    <Fragment>
        <div className="d-flex align-items-center p-3 mx-auto">
          <p>Uw betaling wordt geladen... </p><span className="loadingspinner d-block ms-3"></span>
        </div>
    </Fragment>
  );
};

export default ContinuePaymentPage;