import React from 'react';
import {BrowserRouter as Router, Redirect, Switch} from 'react-router-dom';
import * as Routes from './routes';
import { AdminActivitiesPage, ActivitiesPage, BuyCreditsPage, CollectedDataPage, DashBoardPage, ContinuePaymentPage, EventsPage, ErrorPage, ForgotPasswordPage, LoginPage, RedirectMolliePage, RegisterPage, ResetPasswordPage, PayForSubscriptionPage, ProfilePage, StatsPage, AdminUsersPage, AdminSubscriptionsPage, AdminEventsPage, EventDetailPage, AdminAddEventPage, AdminEditEventPage, MyDogsPage, QRReaderPage, SendGiftPage, ScanUserEntrancePage, AdminExhibitorsPage, PrivacyPolicyPage } from './pages';
import { AdminRouteWithLayout, AuthRouteWithLayout, DogOwnerRouteWithLayout, RouteWithLayout, UnAuthRouteWithLayout, NotSubscribedRouteWithLayout, EntranceScannerRouteWithLayout, ExhibitorRouteWithLayout } from './utilities';
import { ApiProvider, AuthProvider } from './services';
import { ErrorLayout, PageLayout, PublicLayout, RedirectLayout } from './layouts';

import './_styling/css/app.css';

function App() {
  return (
    <div className="app">
      <AuthProvider>
        <ApiProvider>
          <Router basename='/'>
            <Switch>
                <UnAuthRouteWithLayout exact path={Routes.LOGIN} layout={PublicLayout} component={LoginPage} />

                <UnAuthRouteWithLayout exact path={Routes.REGISTER} layout={PublicLayout} component={RegisterPage} />
                <RouteWithLayout exact path={Routes.RESET_PASSWORD} layout={PublicLayout} component={ResetPasswordPage} />
                <UnAuthRouteWithLayout exact path={Routes.FORGOT_PASSWORD} layout={PublicLayout} component={ForgotPasswordPage} />
                <RouteWithLayout exact path={Routes.PRIVACY} layout={PublicLayout} component={PrivacyPolicyPage} />

                <AuthRouteWithLayout exact path={Routes.DASHBOARD} layout={PageLayout} component={DashBoardPage} />
                <NotSubscribedRouteWithLayout exact path={Routes.PAY_FOR_SUBSCRIPTION} layout={PageLayout} component={PayForSubscriptionPage} />
                <AuthRouteWithLayout exact path={Routes.MOLLIE_REDIRECT} layout={RedirectLayout} component={RedirectMolliePage} />
                <AuthRouteWithLayout exact path={Routes.CONTINUE_PAYMENT} layout={RedirectLayout} component={ContinuePaymentPage} />

                <DogOwnerRouteWithLayout exact path={Routes.DOGS} layout={PageLayout} component={MyDogsPage} />
                <AuthRouteWithLayout exact path={Routes.ACTIVITIES} layout={PageLayout} component={ActivitiesPage} />
                <AuthRouteWithLayout exact path={Routes.EVENTS} layout={PageLayout} component={EventsPage} />
                <AuthRouteWithLayout exact path={Routes.EVENT_DETAIL} layout={PageLayout} component={EventDetailPage} />

                <ExhibitorRouteWithLayout exact path={Routes.COLLECTED_DATA} layout={PageLayout} component={CollectedDataPage} />
                <ExhibitorRouteWithLayout exact path={Routes.BUY_CREDITS} layout={PageLayout} component={BuyCreditsPage} />
                <ExhibitorRouteWithLayout exact path={Routes.GIFT} layout={PageLayout} component={SendGiftPage} />

                <AuthRouteWithLayout exact path={Routes.PROFILE} layout={PageLayout} component={ProfilePage} />
                
                <EntranceScannerRouteWithLayout exact path={Routes.SCAN_USER} layout={PageLayout} component={ScanUserEntrancePage} />

                <AdminRouteWithLayout exact path={Routes.ADMIN_USERS} layout={PageLayout} component={AdminUsersPage} />
                <AdminRouteWithLayout exact path={Routes.ADMIN_EXHIBITORS} layout={PageLayout} component={AdminExhibitorsPage} />
                <AdminRouteWithLayout exact path={Routes.ADMIN_EVENTS} layout={PageLayout} component={AdminEventsPage} />
                <AdminRouteWithLayout exact path={Routes.ADMIN_ACTIVITIES} layout={PageLayout} component={AdminActivitiesPage} />
                <AdminRouteWithLayout exact path={Routes.ADD_EVENT} layout={PageLayout} component={AdminAddEventPage} />
                <AdminRouteWithLayout exact path={Routes.EDIT_EVENT} layout={PageLayout} component={AdminEditEventPage} />
                <AdminRouteWithLayout exact path={Routes.ADMIN_SUBSCRIPTIONS} layout={PageLayout} component={AdminSubscriptionsPage} />
                <AdminRouteWithLayout exact path={Routes.ADMIN_STATS} layout={PageLayout} component={StatsPage} />
                <AdminRouteWithLayout exact path={Routes.ADMIN_READ_QR} layout={PageLayout} component={QRReaderPage} />

                <RouteWithLayout exact path={Routes.ERROR} layout={ErrorLayout} component={ErrorPage}/>

                <Redirect from={'/register'} to={Routes.REGISTER}/>

                <Redirect from='/*' to={Routes.LOGIN}/>
            </Switch>
          </Router>
        </ApiProvider>
      </AuthProvider>
    </div>
  );
}

export default App;