export const HOME = '/';
export const LOGIN = '/auth/login';
export const REGISTER = '/auth/register';
export const FORGOT_PASSWORD = '/auth/forgot-password';
export const RESET_PASSWORD = '/auth/reset-password/:token';
export const PRIVACY = '/privacy-policy';

export const DASHBOARD = '/dashboard';
export const DOGS = '/dogs';
export const ACTIVITIES = '/activities';
export const EVENTS = '/events';
export const EVENT_DETAIL = '/events/:id';

export const SCAN_USER = '/scanuser';

export const PAY_FOR_SUBSCRIPTION = '/payforsubscription';
export const CONTINUE_PAYMENT = '/continuepayment';
export const MOLLIE_REDIRECT = '/mollieredirect/:id';

export const COLLECTED_DATA = '/userdata';
export const BUY_CREDITS = '/buycredits';
export const GIFT = '/sendgift';

export const PROFILE = '/profile';
export const SETTINGS = '/settings';

export const ADMIN = '/admin';
export const ADMIN_SCAN_USER = '/admin/scanuser/:id';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_EXHIBITORS = '/admin/exhibitors';
export const ADMIN_EVENTS = '/admin/events';
export const ADMIN_ACTIVITIES = '/admin/activities';
export const EDIT_EVENT = '/admin/events/edit';
export const ADD_EVENT = '/admin/events/add';
export const ADMIN_STATS = '/admin/statistics';
export const ADMIN_USERS_EDIT = '/admin/users/edit';
export const ADMIN_USERS_ADD = '/admin/users/add';
export const ADMIN_SUBSCRIPTIONS = '/admin/subscriptions';
export const ADMIN_READ_QR = '/admin/readqr';

export const ERROR = '/404';