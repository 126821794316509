import { default as React, useState, useCallback, useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { useApi } from '../services';
import { PopUp } from '../components';
import Moment from 'react-moment';

const CollectedDataPage = () => {
    const { initCollectedDataPage } = useApi();
    const [column, setColumn] = useState('name');
    const [searchString, setSearchString] = useState('');
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [users, setUsers] = useState();
    const [userPaginationData, setUserPaginationData] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [showPopup, setShowPopup] = useState();

    const [loadingMore, setLoadingMore] = useState(false);

    const initFetch = useCallback(() => {
        const fetchdata = async () => {
            const response = await initCollectedDataPage(page, column, order?'DESC':'ASC', searchString);
            setUserPaginationData(response.users);
            setUsers(response.users.data);
        }
        fetchdata();
        setLoaded(true);
	},[ initCollectedDataPage ]);

	useEffect(() => {
		initFetch();
	}, []);

    const handleLoadMore = async () => {
        setLoadingMore(true);
        let resp = await initCollectedDataPage(page+1, column, order?'DESC':'ASC', searchString);
        setPage(page+1);
        setUserPaginationData(resp);
        setUsers([...users, ...resp.data]);
        setLoadingMore(false);
    }

    useEffect(async ()=> {
        handleFilter()
    }, [column, order]);

    const handleFilter = async () => {

        setLoaded(false);
        const response = await initCollectedDataPage(1, column, order?'DESC':'ASC', searchString);
        setPage(1);
        setUserPaginationData(response.users);
        setUsers(response.users.data);
        setLoaded(true);
    }

    const setFilter = (filter) => {
        if(column===filter){
            setOrder(!order); 
        }
        setColumn(filter);
    }

    useEffect(()=>{
		const timeout = setTimeout(() => {
			handleFilter()
		}, 750);
		return () => clearTimeout(timeout);
	}, [searchString]);

  return (
    <>
        <div className='card'>
            <Row className="align-items-center mb-4">
                <Col><h1>Mijn roedel</h1></Col>
            </Row>
            {/* {showError && 
                <p className="w-100 text-start my-2 error-message">
                    Aanmaken abonnement mislukt. Vul zowel de naam als het ras van alle rijen in.
                </p>
            } */}
            <Container fluid>
            {loaded ? 
            <>
                <Row className="align-items-center mb-3">
                    <Col sm={9} className='mb-4'></Col>
                    <Col xs={'auto'} className="text-sm-end">Zoeken:</Col>
                    <Col className="searchbar pe-2">
                        <input className="form-control w-100" value={searchString} 
                            onChange={(e)=> {setSearchString(e.target.value)}}/>
                            {searchString && <span onClick={()=> {setSearchString('');}}>
                                <FaTimes/>
                            </span>}
                        </Col>
                </Row>
                {users && users.length > 0 ?
                <>
                    <div className="row">
                        <table className='admin-table'>
                            <thead>
                                <tr>
                                    <th className={`th-filter ${column==='name'?'th-filter__active':''}`} onClick={()=> {setFilter('name')}}> Naam {column==='name' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                    <th className={`th-filter ${column==='email'?'th-filter__active':''}`} onClick={()=> {setFilter('email')}}> Email {column==='email' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                    {/* <th className={`th-filter ${column==='wallet_cumuls.amount'?'th-filter__active':''}`} onClick={()=> {setFilter('wallet_cumuls.amount')}}> Coins {column==='wallet_cumuls.amount' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                    <th onClick={()=> {setFilter('amount_subscriptions')}} className={`text-center th-filter ${column==='amount_subscriptions'?'th-filter__active':''}`}>Abonnement tot {column==='amount_subscriptions' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                    <th onClick={()=> {setFilter('amount_roles')}} className={`text-center th-filter ${column==='amount_roles'?'th-filter__active':''}`}>Rol {column==='amount_roles' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th> */}
                                    {/*<th>&nbsp;</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user)=>{
                                    return (
                                        <tr className="tr-clickable" key={user.id} onClick={() => {
                                            setSelectedUser(user);
                                            setShowPopup(true)
                                        }}>
                                            <td>{user.firstname} {user.name}</td>
                                            <td>{user.email}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Col className="text-center align-middle d-flex mt-4">
                            {userPaginationData && userPaginationData.current_page < userPaginationData.last_page ? 
                                loadingMore?
                                <span className="loadingspinner mx-auto"></span>
                                :
                                <button className="btn btn-secondary mx-auto" onClick={()=> {handleLoadMore()}}>
                                    Laad meer gebruikers
                                </button>
                            :
                            ''
                            }
                        </Col>
                    </div>
                </>
                :
                    <p>Er werden geen gebruikers gevonden</p>
                }
            </>
            :
                <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                    <p className="m-auto d-flex align-items-center">Gebruikers worden geladen... <span className="loadingspinner d-block ms-3"></span></p>
                </Col>
            }
            </Container>
        </div>
        {showPopup && 
            <PopUp title={selectedUser.firstname + ' ' + selectedUser.name} negativeTitle='sluiten' negativeAction={()=>{setShowPopup(false)}}>
                <Row>
                    <Col xs={12} className='d-flex flex-row'><label>Email</label>: &nbsp;<p>{selectedUser.email}</p></Col>
                    <Col xs={12} className='d-flex flex-row'><h3>Honden</h3></Col>
                    <Col xs={12}>
                        {selectedUser.dogs.map((dog, i) => {
                            return <div key={i} className='d-flex flex-row justify-content-between'><span>{dog.dog_name}</span><span>{dog.breed.breed_name}</span></div> 
                        })}
                    </Col>
                    <Col xs={12} className='d-flex flex-row mt-3'><h3>Activiteiten</h3></Col>
                    <Col xs={12}>
                        {selectedUser.current_user_data_shares.map((data, i) => {
                            return <Row key={i} className=' mb-2'>
                                        <Col sm={'auto'} className="mb-2 mb-sm-0">
                                            <b><Moment format="DD/MM/YY">{data.created_at}</Moment></b>
                                        </Col>
                                        <Col sm={10} className="mb-2 mb-sm-0">
                                            {data.type == 'transaction' &&
                                                <p>{'Je hebt ' + selectedUser.firstname + ' ' + selectedUser.name + ' ' + data.transaction.amount } <img alt='tudors' src={'/assets/img/coin.svg'} style={{height: '1em'}}></img> gegeven{data.event ? ` op ${data.event.event_name}.` : '.'} </p>
                                            }
                                            {data.type == 'goodybag' &&
                                                <p>{'Je hebt ' + selectedUser.firstname + ' ' + selectedUser.name + ' een goodybag gegeven' }{data.event ? ` op ${data.event.event_name}.` : '.'} </p>
                                            }
                                        </Col>
                                </Row> 
                        })}
                    </Col>
                </Row>
            </PopUp>
        }
    </>
  );
};

export default CollectedDataPage;