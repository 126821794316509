import { default as React, Fragment, useEffect, useState } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {DashBoardCard} from '../components';
import { FaDog, FaUserAlt, FaMoneyCheck, FaUsers, FaCalendarAlt, FaGift, FaBook, FaClipboardList} from 'react-icons/fa';
import { TiShoppingCart } from 'react-icons/ti';
import * as Routes from '../routes';
import { useAuth } from '../services';
import { useHistory } from 'react-router';
import { MdQrCodeScanner } from 'react-icons/md';
import { GoGraph } from 'react-icons/go';

const DashBoardPage = () => {
  const history = useHistory();

  const { currentUser } = useAuth();
  const [salutation, setSalutation] = useState();
  const [isAdmin] = useState(currentUser.roles.filter((x)=> { return x.name == "Admin"}).length > 0);
  const [isDogOwner] = useState(currentUser.roles.filter((x)=> { return x.name == "DogOwner"}).length > 0);
  const [isExhibitor] = useState(currentUser.roles.filter((x)=> { return x.name == "Exhibitor"}).length > 0);
  const [isEntranceScanner] = useState(currentUser.roles.filter((x)=> { return x.name == "EntranceScanner"}).length > 0);

  useEffect(()=>{
    let now = new Date();
    if (now.getHours() < 12) {
        setSalutation('Goedemorgen');
    } else if (now.getHours() < 18){
      setSalutation('Goedemiddag');
    } else {
      setSalutation('Goedenavond');
    }
  },[]);

  return (
    <Fragment>
          <h1>{salutation} {currentUser.firstname}</h1>
         {currentUser.active_subscriptions.length == 0 &&
         <>
          {currentUser.unfinished_payment == 0?
            <Container fluid className='mt-4'>
              <Row className='card card-paysubscriptionbanner' onClick={()=>{history.push(Routes.PAY_FOR_SUBSCRIPTION)}}>
                <Col>
                  U bent succesvol geregistreed! Klik hier om uw abonnement te betalen.
                </Col>
              </Row>
            </Container>
          :
            <Container fluid className='mt-4'>
              <Row className='card card-paysubscriptionbanner' onClick={()=>{history.push(Routes.CONTINUE_PAYMENT)}}>
                <Col>
                  U bent een betaling gestart. Klik hier om verder te gaan.
                </Col>
              </Row>
            </Container>
          }
         </>
          }
          <Container fluid className='mt-4'>
            <Row>
              {isDogOwner && 
                <>
                  <Col xl={6}><DashBoardCard icon={<MdQrCodeScanner />} title='QR scannen' extraClasses={'green'} route={Routes.ACTIVITIES + '?scan=true'}/></Col>
                  <Col xl={6}><DashBoardCard icon={<FaUserAlt />} title='Profiel' route={Routes.PROFILE}/></Col>
                  <Col xl={6}><DashBoardCard icon={<FaDog />} title='Mijn honden' route={Routes.DOGS}/></Col>
                  <Col xl={6}><DashBoardCard icon={<FaBook />} title='Activiteiten' route={Routes.ACTIVITIES}/></Col>
                </>
              }
              {isExhibitor && 
                <>
                  <Col xl={6}><DashBoardCard icon={<FaUserAlt />} title='Profiel' route={Routes.PROFILE}/></Col>
                  <Col xl={6}><DashBoardCard icon={<FaGift />} title='Belongingen uitdelen' route={Routes.GIFT}/></Col>
                  <Col xl={6}><DashBoardCard icon={<FaUsers />} title='Mijn roedel' route={Routes.COLLECTED_DATA}/></Col>
                </>
              }
              <Col xl={6}><DashBoardCard icon={<FaCalendarAlt />} title='Events' route={Routes.EVENTS}/></Col>

              {isEntranceScanner && 
                <Col xl={6}> <DashBoardCard icon={<MdQrCodeScanner />} title='Ingang scanner' route={Routes.SCAN_USER}/></Col> 
              }
              {isAdmin && 
                <>
                  <Col xs={12}><h2>Admin</h2></Col>
                  <Col xl={6}><DashBoardCard icon={<MdQrCodeScanner />} title='QR scanner' route={Routes.ADMIN_READ_QR}/></Col>
                  <Col xl={6}><DashBoardCard icon={<FaUsers />} title='Gebruikers' route={Routes.ADMIN_USERS}/></Col>
                  <Col xl={6}><DashBoardCard icon={<TiShoppingCart />} title='Standhouders' route={Routes.ADMIN_EXHIBITORS}/></Col>
                  <Col xl={6}><DashBoardCard icon={<FaCalendarAlt />} title='Events beheren' route={Routes.ADMIN_EVENTS}/></Col>
                  <Col xl={6}><DashBoardCard icon={<FaMoneyCheck />} title='Abonnementen' route={Routes.ADMIN_SUBSCRIPTIONS}/></Col>
                  <Col xl={6}><DashBoardCard icon={<FaClipboardList />} title='Alle activiteiten' route={Routes.ADMIN_ACTIVITIES}/></Col>
                  <Col xl={6}><DashBoardCard icon={<GoGraph />} title='Statistieken' route={Routes.ADMIN_STATS}/></Col>
                </>
              }
            </Row>
          </Container>
    </Fragment>
  );
};

export default DashBoardPage;