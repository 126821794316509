import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ labels, data, title=''}) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: title,
            },
        },
        aspectRatio: 1.5
    };

    const graphData = {
        labels,
        datasets: [
            {
                label: title,
                data: data,
                backgroundColor: '#288F93',
            }
        ]
    };

  return <Line options={options} data={graphData} />;
}

export default LineChart;
