import { default as React, useState, useCallback, useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { useApi } from '../../services';
import Moment from 'react-moment';
import {formatMoney} from '../../utilities/NumberFormat';
import Select from 'react-select'
import Switch from "react-switch";

const AdminExhibitorsPage = () => {
    const { changeIsNonProfit, downloadExhibitorsCsv, getFilteredExhibitors, createSubscriptionByAdmin, updateRoles, initExhibitorsPage, adminGiveTudors } = useApi();

    const [column, setColumn] = useState('name');
    const [searchString, setSearchString] = useState('');
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [users, setUsers] = useState();
    const [userPaginationData, setUserPaginationData] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [selectedUserCard, setSelectedUserCard] = useState();
    const [showPopup, setShowPopup] = useState();
    const [roles, setRoles] = useState();
    const [selectedRoles, setSelectedRoles] = useState();
    const [amountToGive, setAmountToGive] = useState(1);

    const [loadingMore, setLoadingMore] = useState(false);

    const [showError, setShowError] = useState(false);
    const [total, setTotal] = useState(0);
    const [prices, setPrices]= useState();

    const initFetch = useCallback(() => {
      const fetchdata = async () => {
        const response = await initExhibitorsPage();
        setPrices(response.prices);
        setRoles(response.roles);
        setUserPaginationData(response.users);
        setUsers(response.users.data);
      }
      fetchdata();
      setLoaded(true);
	},[ initExhibitorsPage ]);

	useEffect(() => {
		initFetch();
	}, []);

    const handleLoadMore = async () => {
        setLoadingMore(true);
        let resp = await getFilteredExhibitors(page+1, column, order?'DESC':'ASC', searchString);
        setPage(page+1);
        setUserPaginationData(resp);
        setUsers([...users, ...resp.data]);
        setLoadingMore(false);
    }

    useEffect(async ()=> {
        handleFilter()
    }, [column, order]);

    const handleFilter = async () => {
        setLoaded(false);
        const resp = await getFilteredExhibitors(1, column, order?'DESC':'ASC', searchString);
        setPage(1);
        setUserPaginationData(resp);
        setUsers(resp.data);
        setLoaded(true);
    }

    const setFilter = (filter) => {
        if(column===filter){
            setOrder(!order); 
        }
        setColumn(filter);
    }

    useEffect(()=>{
		const timeout = setTimeout(() => {
			handleFilter()
		}, 750);
		return () => clearTimeout(timeout);
	}, [searchString]);

    const handleCreateSubscription = async () => {
        setLoaded(false);
        setShowPopup(null);
        const resp = await createSubscriptionByAdmin(selectedUser.id, selectedUserCard, [], [], [], column, order?'DESC':'ASC', searchString);
        if (!resp.message) {
            setShowError(false);
            setPage(1);
            setUserPaginationData(resp);
            setUsers(resp.data);
        } else {
            setShowError(true);
        }
        setShowPopup(null);
        setLoaded(true);
    }

    const handleChangeRoles = async () => {
        setLoaded(false);
        const resp = await updateRoles(selectedUser.id, selectedRoles.map((role) => {return role.value}));
        if (!resp.message) {
            setShowError(false);
            setPage(1);
            setUserPaginationData(resp);
            setUsers(resp.data);
        } else {
            setShowError(true);
        }
        setShowPopup(null);
        setLoaded(true);
    }

    const handleGiveTudors = async () => {
        setLoaded(false);
        setShowPopup(null);
        let resp = await adminGiveTudors(selectedUser.id, amountToGive);
        if (resp.ok) {
            let data = await resp.json();
            let temp = [...users];
            let index = temp.findIndex((x => x.id == data.user.id));
            temp[index].wallet.amount = data.user.wallet.amount;
            setUsers(temp);
        }
        setLoaded(true);
    }

    useEffect(() => {
        if (selectedUser) {
            setTotal(calcTotalPrice(selectedUser));  
        }
    }, [selectedUser])

    const calcTotalPrice = (user) => {
        let price = 0;
        let isExhibitor = user.roles.filter((x)=> { return x.name == "Exhibitor"}).length > 0;
        let isDogOwner = user.roles.filter((x)=> { return x.name == "DogOwner"}).length > 0;
        if (isExhibitor) {
          price = prices.filter((x) => {return x.name == 'exhibitor'})[0].price_incl;
        } else if(isDogOwner){
          price = prices.filter((x) => {return x.name == 'dog_owner'})[0].price_incl;
        }
        if (user.card) {
          price += prices.filter((x) => {return x.name == 'card'})[0].price_incl;
        }
        return price;
    }

    const handleProfitSwitch = (user) => {
        let temp = [...users];
        let tempUser = temp.find((x)=> x.id == user.id);
        tempUser.company.non_profit = !user.company.non_profit;
        setUsers(temp);
        changeIsNonProfit(user.company.id, tempUser.company.non_profit);
    }
    const handleDownloadCsv = async () => {
        await downloadExhibitorsCsv();
    }

  return (
      <>
    <div className='card'>
        <Row className="align-items-center mb-4">
            <Col sm={8}><h1>Standhouders beheren</h1></Col>
            <Col sm={4}><button className='btn btn-primary float-end' onClick={()=>{handleDownloadCsv()}}> csv downloaden </button></Col>
        </Row>
        {showError && 
            <p className="w-100 text-start my-2 error-message">
                Aanmaken abonnement mislukt.
            </p>
        }
        <Container fluid>
        {loaded ? 
        <>
            <Row className="align-items-center mb-3">
                <Col sm={9} className='mb-4'></Col>
                <Col xs={'auto'} className="text-sm-end">Zoeken:</Col>
                <Col className="searchbar pe-2">
                    <input className="form-control w-100" value={searchString} 
                        onChange={(e)=> {setSearchString(e.target.value)}}/>
                        {searchString && <span onClick={()=> {setSearchString('');}}>
                            <FaTimes/>
                        </span>}
                    </Col>
            </Row>
            {users && users.length > 0 ?
                <>
                    <div className="row">
                    <table className='admin-table'>
                        <colgroup>
                            <col style={{width:"20%"}}></col>
                            <col style={{width:"20%"}}></col>
                            <col style={{width:"10%"}}></col>
                            <col style={{width:"10%"}}></col>
                            <col style={{width:"10%"}}></col>
                            <col style={{width:"10%"}}></col>
                            <col style={{width:"10%"}}></col>
                        </colgroup> 
                        <thead>
                            <tr>
                                <th className={`th-filter ${column==='name'?'th-filter__active':''}`} onClick={()=> {setFilter('name')}}> Naam {column==='name' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                <th className={`th-filter ${column==='companies.company_name'?'th-filter__active':''}`} onClick={()=> {setFilter('companies.company_name')}}> Bedrijf {column==='companies.company_name' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                <th className={`th-filter ${column==='email'?'th-filter__active':''}`} onClick={()=> {setFilter('email')}}> Email {column==='email' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                <th className={`th-filter text-center ${column==='wallet_cumuls.amount'?'th-filter__active':''}`} onClick={()=> {setFilter('wallet_cumuls.amount')}}> Coins {column==='wallet_cumuls.amount' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                <th onClick={()=> {setFilter('amount_subscriptions')}} className={`text-center th-filter ${column==='amount_subscriptions'?'th-filter__active':''}`}>Abonnement tot {column==='amount_subscriptions' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                <th onClick={()=> {setFilter('amount_roles')}} className={`text-center th-filter ${column==='amount_roles'?'th-filter__active':''}`}>Rol {column==='amount_roles' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                <th>VZW</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user)=>{
                                return (
                                    <tr className="tr-clickable" key={user.id}>
                                        <td onClick={() => {
                                                setSelectedUser(user);
                                                setShowPopup('info');
                                            }}>{user.firstname} {user.name}</td>
                                        <td onClick={() => {
                                                setSelectedUser(user);
                                                setShowPopup('info');
                                            }}>{user.company.company_name}</td>
                                        <td>{user.email}</td>
                                        <td className="text-center" onClick={() => {
                                                setSelectedUser(user);
                                                setShowPopup('wallet');
                                            }}>{user.wallet.amount} <img className='ms-1' alt='tudors' src={'/assets/img/coin.svg'} style={{height: '1em'}}></img></td>
                                        <td className="text-center" onClick={()=>{setSelectedUser(user); setSelectedUserCard(user.card); setShowPopup('subscription')}}>
                                            {user.active_subscriptions.length > 0 ? <Moment format="DD/MM/YYYY">{user.active_subscriptions[0].active_until}</Moment> : 'Geen abonnement'}
                                        </td>
                                        <td className="text-center" onClick={() => {
                                                setSelectedUser(user);
                                                setSelectedRoles(user.roles.map((role, i)=> {
                                                    return {value: role.id, label: role.name}
                                                })); 
                                                setShowPopup('roles');
                                            }}>
                                                {user.roles.map((role, i) => {
                                                    let rolename;
                                                    switch (role.name) {
                                                        case 'DogOwner':
                                                            rolename = 'Gebruiker'
                                                            break;
                                                        case 'Exhibitor':
                                                            rolename = 'Standhouder'
                                                            break;
                                                        case 'EntranceScanner':
                                                            rolename = 'Ingang scanner'
                                                            break;
                                                    
                                                        default:
                                                            rolename = role.name;
                                                            break;
                                                    }
                                                    return rolename + (user.roles.length == i+1?'': (user.roles.length == i+2 ? ' en ' : ', '));
                                                })}
                                        </td>
                                        <td><Switch checked={user.company.non_profit==1?true:false} onChange={()=>{handleProfitSwitch(user);}}/></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <Col className="text-center align-middle d-flex mt-4">
                        {userPaginationData && userPaginationData.current_page < userPaginationData.last_page ? 
                            loadingMore?
                            <span className="loadingspinner mx-auto"></span>
                            :
                            <button className="btn btn-secondary mx-auto" onClick={()=> {handleLoadMore()}}>
                                Laad meer standhouders
                            </button>
                        :
                        ''
                        }
                    </Col>
                    </div>
                </>
            :
                <p>Er werden geen standhouders gevonden voor de opgegeven filters</p>
            }
        </>
        :
            <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                <p className="m-auto d-flex align-items-center">Standhouders worden geladen... <span className="loadingspinner d-block ms-3"></span></p>
            </Col>
        }
        </Container>
    </div>

    {showPopup == 'subscription' &&
        <div className='popup' onClick={(e)=> {setShowPopup(null);}}>
            <div className={`card`} onClick={(e)=> {e.stopPropagation()}}>
                <div className='close' onClick={(e)=> {setShowPopup(null);}}>&times;</div>
                <Row>
                    {selectedUser &&
                        <>
                            <Col xs='12'>
                                <h3 htmlFor="user">Abonnement aanmaken</h3>
                                <table className="payforsubscriptiontable">
                                    <tbody>
                                        <tr>
                                            <td className='pt-0'>
                                                {selectedUser.firstname} {selectedUser.name}
                                            </td>
                                            <td className='p-0 text-end'>
                                                {formatMoney(prices.filter((x) => {return x.name == 'exhibitor'})[0].price_incl)}
                                            </td>
                                        </tr>
                                        {selectedUser.active_subscriptions.length == 0 ? 
                                            <>
                                            <tr>
                                                <td className='py-3 d-flex flex-row flex-nowrap align-items-center'>
                                                    <input className="form-check-input p-0 m-0 flex-shrink-0" type="checkbox" id="wants_card" defaultChecked={selectedUserCard == 1} 
                                                        onChange={()=>{
                                                            if (selectedUserCard) {
                                                                setSelectedUserCard(0); setTotal(total - prices.filter((x) => {return x.name == 'card'})[0].price_incl)
                                                            } else {
                                                                setSelectedUserCard(1); setTotal(total+ prices.filter((x) => {return x.name == 'dog_cheap'})[0].price_incl);
                                                            }
                                                        }}/>
                                                    <label className="p-0 m-0 ms-2" htmlFor="wants_card">
                                                        Fysieke kaart
                                                    </label>
                                                </td>
                                                <td className='p-0 py-3 text-end'>
                                                {formatMoney(prices.filter((x) => {return x.name == 'card'})[0].price_incl)}
                                                </td>
                                            </tr>
                                        </>
                                        :
                                        <>
                                            {selectedUserCard == 1 ? 
                                                <tr>
                                                    <td className='py-3'>
                                                        Fysieke kaart
                                                    </td>
                                                    <td className='p-0 py-3 text-end'>
                                                    {formatMoney(prices.filter((x) => {return x.name == 'card'})[0].price_incl)}
                                                    </td>
                                                </tr>
                                                :
                                                ''
                                            }
                                        </>
                                        }
                                        <tr>
                                            <td>
                                            <b>Totaal: </b>
                                            </td>
                                            <td className='p-0 text-end'>
                                                {formatMoney(total)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            {selectedUser.active_subscriptions.length == 0 && 
                                <Col xs='auto' className='ms-auto'>
                                    <button className='btn btn-primary' onClick={()=>{handleCreateSubscription()}}>
                                        Abonnement aanmaken
                                    </button>
                                </Col>
                            }
                        </>
                    }
                </Row>
            </div>
        </div>
    }
    {showPopup == 'roles' &&
        <div className='popup' onClick={(e)=> {setShowPopup(null);}}>
            <div className={`card`} onClick={(e)=> {e.stopPropagation()}}>
                <div className='close' onClick={(e)=> {setShowPopup(null);}}>&times;</div>
                <Row>
                    {selectedUser &&
                        <>
                            <Col xs='12'>
                                <h3 htmlFor="user">Rollen {selectedUser.firstname} {selectedUser.name}</h3>
                                <Select
                                    isMulti={true}
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#EBE9EB',
                                            primary: 'black',
                                            neutral10: 'black',
                                        },
                                    })}
                                    value={selectedRoles}
                                    options={[...roles.map((role, i)=> {
                                        return {value: role.id, label: role.name};
                                    })]} 
                                    onChange={(e)=> {
                                        setSelectedRoles(e);
                                    }}
                                />
                            </Col>
                            <Col xs='auto' className='ms-auto mt-3'>
                                <button className='btn btn-primary' onClick={()=>{handleChangeRoles()}}>
                                    rollen opslaan
                                </button>
                            </Col>
                        </>
                    }
                </Row>
            </div>
        </div>
    }
    {showPopup == 'wallet' &&
        <div className='popup' onClick={(e)=> {setShowPopup(null);}}>
            <div className={`card`} onClick={(e)=> {e.stopPropagation()}}>
                <div className='close' onClick={(e)=> {setShowPopup(null);}}>&times;</div>
                <Row>
                    {selectedUser &&
                        <>
                            <Col xs='12'>
                                <h3 htmlFor="user">Wallet {`${selectedUser.firstname} ${selectedUser.name}`}</h3>
                                <div className="form-floating mb-4">
                                    <input className="form-control" type="number" id="floatingStreet" placeholder="straat"  value={amountToGive} min={1} 
                                        onChange={(e)=>{
                                            setAmountToGive(e.target.value);
                                        }}/>
                                    <label htmlFor="floatingStreet">Hoeveel tudors wil {selectedUser.firstname} je geven?</label>
                                </div>
                            </Col>
                            
                            <Col xs='auto' className='ms-auto'>
                                <button className='btn btn-primary' onClick={()=>{handleGiveTudors()}}>
                                    Tudors geven
                                </button>
                            </Col>
                            
                        </>
                    }
                </Row>
            </div>
        </div>
    }
    {showPopup == 'info' &&
        <div className='popup' onClick={(e)=> {setShowPopup(null);}}>
            <div className={`card`} onClick={(e)=> {e.stopPropagation()}}>
                <div className='close' onClick={(e)=> {setShowPopup(null);}}>&times;</div>
                <Row>
                    {selectedUser &&
                        <>
                            <Col xs='12'>
                                <h3 className='mb-1'>Gegevens</h3>
                                <p>{selectedUser.firstname} {selectedUser.name}</p>
                                <p>{selectedUser.email}</p>
                                <p>{selectedUser.phone}</p>
                                <h3 className='mb-1 mt-3'>Bedrijf</h3>
                                <p>{selectedUser.company.company_name}</p>
                                <p>{selectedUser.company.vat}</p>
                                <p>{selectedUser.company.company_type.type_name}</p>
                                <p>{`${selectedUser.company.company_street} ${selectedUser.company.company_nr}, ${selectedUser.company.company_zip} ${selectedUser.company.company_city}`}</p>
                                <p>{selectedUser.company.company_description}</p>
                            </Col>
                        </>
                    }
                </Row>
            </div>
        </div>
    }
    </>
  );
};

export default AdminExhibitorsPage;