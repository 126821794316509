import React, { createContext, useContext, useState } from 'react';
import {config} from '../config';

const AuthContext = createContext();
const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const baseUrl = config.API_URL;

  const [bearerToken, setBearerToken] = useState(JSON.parse(localStorage.getItem('tudorandfriendstoken')) || null);
  const [currentUser, setCurrentUser] = useState();

  const signIn = async (email, password) => {
    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({email, password}),
      redirect: 'follow',
    };

    const resp = await fetch(`${baseUrl}/api/login`, options);
    if (resp.ok) {
      let data = await resp.json();
      localStorage.setItem('tudorandfriendstoken', JSON.stringify(data.token));
      setCurrentUser(data.user);
      setBearerToken(data.token);
      return data;
    } else {
      return resp
    }
  }

  const signUp = async (firstname, name, email, password, repeatpassword, street, nr, city, zip, country, registerAsExhibitor, company_name, company_vat, company_street, company_nr, company_city, company_zip, company_description, company_country, company_type, phone, wants_card, dogs) => {
    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
              firstname, 
              name, 
              email, 
              password, 
              password_confirmation: repeatpassword,
              street,
              nr,
              city,
              zip,
              country,
              registerAsExhibitor,
              company_name,
              company_vat,
              company_street,
              company_nr,
              company_city,
              company_zip,
              company_description,
              company_country,
              company_type,
              phone,
              wants_card,
              dogs
            }),
      redirect: 'follow',
    };

    const resp = await fetch(`${baseUrl}/api/register`, options);
    if (resp.ok) {
      let data = await resp.json();
      localStorage.setItem('tudorandfriendstoken', JSON.stringify(data.token));
      setCurrentUser(data.user);
      setBearerToken(data.token);
    }
    return resp
  }

  const refreshCurrentUser = async ()=> {
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };
    try {
      const resp = await fetch(`${baseUrl}/api/users/current-user`, options);
      let user = await resp.json();
      setCurrentUser(user);
      return user;
    } catch (error) {
      localStorage.removeItem('tudorandfriendstoken');
      setBearerToken();
      setCurrentUser();
    }
  }

  const updateCurrentUser = async (firstname, name, street, nr, city, zip, country, phone, wants_card) => {
    let url = `${baseUrl}/api/users/current-user`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        firstname, 
        name, 
        street,
        nr,
        city,
        zip,
        country,
        phone,
        wants_card
      }),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    if (resp.ok) {
      let temp = await resp.json();
      setCurrentUser(temp);
    }
    return resp;
  }

  const logOut = () =>{
    localStorage.removeItem('tudorandfriendstoken');
    setBearerToken(null);
    setCurrentUser(null);
  }

  const resendVerificationEmail = async () => {
    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    };

    const resp = await fetch(`${baseUrl}/api/email/verification-notification`, options);
    
    return resp;
  }

  const sendForgotPasswordEmail = async (email) => {
    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({email}),
      redirect: 'follow',
    };

    const resp = await fetch(`${baseUrl}/api/forgot-password`, options);
    return resp;
  }

  const resetPassword = async (token, email, password, password_confirmation) => {
    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({token, email, password, password_confirmation}),
      redirect: 'follow',
    };

    const resp = await fetch(`${baseUrl}/api/reset-password`, options);
    return resp;
  }

  const toggleNotifications = async (token, email, password, password_confirmation) => {
    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({token, email, password, password_confirmation}),
      redirect: 'follow',
    };

    const resp = await fetch(`${baseUrl}/api/users/current-user/notifications`, options);

    if (resp.ok) {
      let data = await resp.json();

      setCurrentUser({...currentUser, receive_notifications: data.receive_notifications});
      return data;
    } else {
      return resp
    }
  }

  return (
    <AuthContext.Provider value={{ bearerToken, currentUser, setCurrentUser, logOut, refreshCurrentUser, resetPassword, sendForgotPasswordEmail, signIn, signUp, resendVerificationEmail, toggleNotifications, updateCurrentUser}}>
      {children}
    </AuthContext.Provider>
  )
};

export {
  AuthContext,
  AuthProvider,
  useAuth,
}
