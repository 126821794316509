import { default as React, Fragment } from 'react';
import * as Routes from '../routes';
import { Link } from 'react-router-dom';


const ErrorPage = () => {
  return (
    <div className="w-100 my-auto p-5">
        <h1 className="mb-5">404 is it me you are looking for</h1>
        <Link to={Routes.DASHBOARD} className="btn btn-primary">terug naar de app</Link>
    </div>
  );
};

export default ErrorPage;