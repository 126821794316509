import { default as React, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

const EventServicesRow = ({ index, services, setServices, handleDelete=(d)=>{},  id=null}) => {
	return (
        <Row className='my-3'>
            <Col xs={11}>
                <Row>
                    <Col md={4}>
                        <Row>
                            <Col xs={12}>
                                <div className="form-floating always-up">
                                    <input type="text" value={services[index].name} onChange={(e)=> {let temp = [...services]; temp[index].name = e.target.value; setServices(temp)}} className="form-control" id={`name-${index}`} placeholder="naam" required={true} />
                                    <label htmlFor="check-ins">Naam</label>
                                    <Form.Control.Feedback type="invalid" className='mb-5'>
                                        Gelieve een naam in te geven.
                                    </Form.Control.Feedback>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} className='mb-md-0'>
                        <div className="form-floating">
                            <input type="number" value={services[index].available} onChange={(e)=> {let temp = [...services]; temp[index].available = e.target.value; setServices(temp)}} className="form-control" id={`available-${index}`} placeholder="1" required={true} />
                            <label htmlFor="check-ins">Hoeveel beschikbaar</label>
                            <Form.Control.Feedback type="invalid" className='mb-5'>
                                Gelieve een hoeveelheid in te geven.
                            </Form.Control.Feedback>
                        </div>
                    </Col>
                    <Col md={4} className='mb-md-0'>
                        <div className="form-floating">
                            <input type="number" value={services[index].price} onChange={(e)=> {let temp = [...services]; temp[index].price = e.target.value; setServices(temp)}} className="form-control" id={`price-${index}`} placeholder="50" required={true} />
                            <label htmlFor="credits">Prijs (in tudors)</label>
                            <Form.Control.Feedback type="invalid" className='mb-5'>
                                Gelieve een hoeveelheid Tudors in te geven.
                            </Form.Control.Feedback>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col xs={1} className="p-0 d-flex align-items-center">
                <button type="button" className="btn btn-primary p-0 px-md-2" onClick={()=> {
                        let temp = [...services]; 
                        temp.splice(index, 1);
                        setServices(temp);
                        handleDelete(id);
                    }}
                >&times;</button>
            </Col>
        </Row>
	);
};

export default EventServicesRow;