import { default as React, Fragment, useState, useCallback, useEffect } from 'react';
import { QrReader } from '@blackbox-vision/react-qr-reader';
import { useApi } from '../../services';
import {Col, Container, Row} from 'react-bootstrap';
import Moment from 'react-moment';


const QRReaderPage = () => {
    const { getUserFromQR } = useApi();
    const [data, setData] = useState();
    const [user, setUser] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    useEffect(async ()=>{
        if (data) {
            setLoading(true);
            let resp = await getUserFromQR(data);
            if (resp.ok) {
                let user = await resp.json();
                setUser(user);
            } else {
                setError(true);
            }
            setLoading(false);
        }
    }, [data]);

  return (
      <>
        <Row>
            <Col xs='12'>
                <h3 htmlFor="user">Qr scanner</h3>
            </Col>

            {loading ? 
                <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                    <p className="m-auto d-flex align-items-center">Gebruiker wordt geladen... <span className="loadingspinner d-block ms-3"></span></p>
                </Col>
            :
            <>
                {!user ?
                    <>
                        {error ? 
                        <>
                            <Row>
                                <Col xs={12}>
                                    <p className='error-message'>Geen gebruiker gevonden met deze qr code</p>
                                </Col>
                            </Row>
                            <Col xs='auto' className='ms-auto mt-3'>
                                <button className='btn btn-primary' onClick={()=>{setData(null); setUser(null); setError(null)}}>
                                    Nieuwe gebruiker scannen
                                </button>
                            </Col>
                        </>
                    :
                        <Col lg='6'>
                            <div className='qr-reader-wrapper'>
                                <QrReader
                                    constraints={{ facingMode: 'environment' }}
                                    onResult={(result, error) => {
                                        if (!!result) {
                                            setData(result?.text);
                                        }
                                    }}
                                    containerStyle={{padding: 0}}
                                />
                            </div>
                        </Col>
                        }
                    </>
                    :
                    <>
                        <Col xs='12'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td colSpan={2} className='pt-3 pb-0'>
                                            <h4>{user.firstname} {user.name}</h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='py-3'>
                                            Abonnement tot 
                                        </td>
                                        <td><Moment format="DD/MM/YYYY">{user.active_subscriptions[0].active_until}</Moment> (nog <Moment date={user.active_subscriptions[0].active_until} format='DD' duration={new Date()}/> dagen)</td>
                                    </tr>
                                    <tr>
                                        <td className='py-3'>
                                            Fysieke kaart
                                        </td>
                                        <td>{user.card == 1? 'ja': 'nee'}</td>
                                    </tr>
                                    {user.active_subscriptions[0].subscription_records.map((r, i)=> {
                                        if (r.dog) {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        {r.dog.dog_name}
                                                    </td>
                                                    <td>
                                                        {r.dog.breed.breed_name}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </Col>
                        <Col xs='auto' className='ms-auto mt-3'>
                            <button className='btn btn-primary' onClick={()=>{setData(null); setUser(null); setError(null)}}>
                                Nieuwe gebruiker scannen
                            </button>
                        </Col>
                    </>
                }
            </>
            }
        </Row>

    </>
  );
};

export default QRReaderPage;