import { default as React, useState, useCallback, useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { useApi } from '../../services';
import Moment from 'react-moment';
import {formatMoney} from '../../utilities/NumberFormat';
import {DogRegisterRow} from '../../components';
import Select from 'react-select'

const AdminUsersPage = () => {
    const { getFilteredUsers, createSubscriptionByAdmin, downloadDogOwnersCsv, updateRoles, initUsersPage, adminGiveTudors } = useApi();
    const [column, setColumn] = useState('name');
    const [searchString, setSearchString] = useState('');
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [users, setUsers] = useState();
    const [userPaginationData, setUserPaginationData] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [selectedUserCard, setSelectedUserCard] = useState();
    const [showPopup, setShowPopup] = useState();
    const [roles, setRoles] = useState();
    const [selectedRoles, setSelectedRoles] = useState();
    const [amountToGive, setAmountToGive] = useState(1);

    const [loadingMore, setLoadingMore] = useState(false);

    const [dogs, setDogs] = useState([]);
    const [newDogs, setNewDogs] = useState([]);
    const [deletedDogs, setDeletedDogs] = useState([]);
    const [breeds, setBreeds] = useState([]);

    const [showError, setShowError] = useState(false);
    const [total, setTotal] = useState(0);
    const [prices, setPrices]= useState();

    const initFetch = useCallback(() => {
      const fetchdata = async () => {
        const response = await initUsersPage();
        setPrices(response.prices);
        setRoles(response.roles);
        setBreeds(response.breeds);
        setUserPaginationData(response.users);
        setUsers(response.users.data);
      }
      fetchdata();

      setLoaded(true);

	},[ getFilteredUsers ]);

	useEffect(() => {
		initFetch();
	}, []);

    const handleLoadMore = async () => {
        setLoadingMore(true);
        let resp = await getFilteredUsers(page+1, column, order?'DESC':'ASC', searchString);
        setPage(page+1);
        setUserPaginationData(resp);
        setUsers([...users, ...resp.data]);
        setLoadingMore(false);
    }

    useEffect(async ()=> {
        handleFilter()
    }, [column, order]);

    const handleFilter = async () => {
        setLoaded(false);
        const resp = await getFilteredUsers(1, column, order?'DESC':'ASC', searchString);
        setPage(1);
        setUserPaginationData(resp);
        setUsers(resp.data);
        setLoaded(true);
    }

    const setFilter = (filter) => {
        if(column===filter){
            setOrder(!order); 
        }
        setColumn(filter);
    }

    useEffect(()=>{
		const timeout = setTimeout(() => {
			handleFilter()
		}, 750);
		return () => clearTimeout(timeout);
	}, [searchString])

    const handleCreateSubscription = async () => {
        setLoaded(false);
        setShowPopup(null);
        const resp = await createSubscriptionByAdmin(selectedUser.id, selectedUserCard, dogs, newDogs, deletedDogs, column, order?'DESC':'ASC', searchString);
        if (!resp.message) {
            setShowError(false);
            setPage(1);
            setUserPaginationData(resp);
            setUsers(resp.data);
        } else {
            setShowError(true);
        }
        setShowPopup(null);
        setLoaded(true);
    }

    const handleChangeRoles = async () => {
        setLoaded(false);
        const resp = await updateRoles(selectedUser.id, selectedRoles.map((role) => {return role.value}));
        if (!resp.message) {
            setShowError(false);
            setPage(1);
            setUserPaginationData(resp);
            setUsers(resp.data);
        } else {
            setShowError(true);
        }
        setShowPopup(null);
        setLoaded(true);
    }
    const handleGiveTudors = async () => {
        setLoaded(false);
        setShowPopup(null);
        let resp = await adminGiveTudors(selectedUser.id, amountToGive);
        if (resp.ok) {
            let data = await resp.json();
            let temp = [...users];
            let index = temp.findIndex((x => x.id == data.user.id));
            temp[index].wallet.amount = data.user.wallet.amount;
            setUsers(temp);
        }
        setLoaded(true);
    }

    const addDog = () =>{
        if ((newDogs.length + dogs.length)<2) {
            setTotal(total + prices.filter((x) => {return x.name == 'dog_expensive'})[0].price_incl);
        } else {
            setTotal(total + prices.filter((x) => {return x.name == 'dog_cheap'})[0].price_incl);
        }
        let temp = [...newDogs];
        temp.push({name: '', breed: null});
        setNewDogs(temp);

    }

    useEffect(() => {
        if (selectedUser) {
            setTotal(calcTotalPrice(selectedUser));  
        }
    }, [selectedUser])

    const calcTotalPrice = (user) =>{
        let price = 0;
        let isExhibitor = user.roles.filter((x)=> { return x.name == "Exhibitor"}).length > 0;
        let isDogOwner = user.roles.filter((x)=> { return x.name == "DogOwner"}).length > 0;
        if (isExhibitor) {
          price = prices.filter((x) => {return x.name == 'exhibitor'})[0].price_incl;
        } else if(isDogOwner){
          price = prices.filter((x) => {return x.name == 'dog_owner'})[0].price_incl;
        }
        if (user.card) {
          price += prices.filter((x) => {return x.name == 'card'})[0].price_incl;
        }
        user?.dogs.map((dog, i)=> {
          if (i<2) {
            price += prices.filter((x) => {return x.name == 'dog_expensive'})[0].price_incl;
          } else {
            price += prices.filter((x) => {return x.name == 'dog_cheap'})[0].price_incl;
          }
        });
        return price;
    }

    const handleDownloadCsv = async () => {
        await downloadDogOwnersCsv();
    }

  return (
      <>
    <div className='card'>
        <Row className="align-items-center mb-4">
            <Col sm={8}><h1>Gebruikers beheren</h1></Col>
            <Col sm={4}><button className='btn btn-primary float-end' onClick={()=>{handleDownloadCsv()}}> csv downloaden </button></Col>
        </Row>
        {showError && 
            <p className="w-100 text-start my-2 error-message">
                Aanmaken abonnement mislukt. Vul zowel de naam als het ras van alle rijen in.
            </p>
        }
        <Container fluid>
        {loaded ? 
        <>
            <Row className="align-items-center mb-3">
                <Col sm={9} className='mb-4'></Col>
                <Col xs={'auto'} className="text-sm-end">Zoeken:</Col>
                <Col className="searchbar pe-2">
                    <input className="form-control w-100" value={searchString} 
                        onChange={(e)=> {setSearchString(e.target.value)}}/>
                        {searchString && <span onClick={()=> {setSearchString('');}}>
                            <FaTimes/>
                        </span>}
                    </Col>
            </Row>
            {users && breeds && breeds.length && users.length > 0 ?
                <>
                    <div className="row">
                    <table className='admin-table'>
                        <colgroup>
                            <col style={{width:"20%"}}></col>
                            <col style={{width:"30%"}}></col>
                            <col style={{width:"10%"}}></col>
                            <col style={{width:"20%"}}></col>
                            <col style={{width:"20%"}}></col>
                            {/* <col style={{width:"15%"}}></col> */}
                        </colgroup> 
                        <thead>
                            <tr>
                                <th className={`th-filter ${column==='name'?'th-filter__active':''}`} onClick={()=> {setFilter('name')}}> Naam {column==='name' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                <th className={`th-filter ${column==='email'?'th-filter__active':''}`} onClick={()=> {setFilter('email')}}> Email {column==='email' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                <th className={`th-filter ${column==='wallet_cumuls.amount'?'th-filter__active':''}`} onClick={()=> {setFilter('wallet_cumuls.amount')}}> Coins {column==='wallet_cumuls.amount' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                <th onClick={()=> {setFilter('amount_subscriptions')}} className={`text-center th-filter ${column==='amount_subscriptions'?'th-filter__active':''}`}>Abonnement tot {column==='amount_subscriptions' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                <th onClick={()=> {setFilter('amount_roles')}} className={`text-center th-filter ${column==='amount_roles'?'th-filter__active':''}`}>Rol {column==='amount_roles' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                {/*<th>&nbsp;</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user)=>{
                                return (
                                    <tr className="tr-clickable" key={user.id}>
                                        <td onClick={() => {
                                                setSelectedUser(user);
                                                setShowPopup('activities')
                                            }}>{user.firstname} {user.name}</td>
                                        <td>{user.email}</td>
                                        <td onClick={() => {
                                                setSelectedUser(user);
                                                setShowPopup('wallet')
                                            }}>{user.wallet.amount} <img className='ms-1' alt='tudors' src={'/assets/img/coin.svg'} style={{height: '1em'}}></img></td>
                                        <td className="text-center" onClick={()=>{setSelectedUser(user); setSelectedUserCard(user.card); setDogs(user.dogs.map((d) => { return {id: d.id, name: d.dog_name, breed: d.breed.id}})); setDeletedDogs([]); setNewDogs([]); setShowPopup('subscription')}}>
                                            {user.active_subscriptions.length > 0? <Moment format="DD/MM/YYYY">{user.active_subscriptions[0].active_until}</Moment> : 'Geen abonnement'}
                                        </td>
                                        <td className="text-center" onClick={() => {
                                                setSelectedUser(user);
                                                setSelectedRoles(user.roles.map((role, i)=> {
                                                    return {value: role.id, label: role.name}
                                                })); 
                                                setShowPopup('roles')
                                            }}>
                                                {user.roles.map((role, i) => {
                                                    let rolename;
                                                    switch (role.name) {
                                                        case 'DogOwner':
                                                            rolename = 'Gebruiker'
                                                            break;
                                                        case 'Exhibitor':
                                                            rolename = 'Standhouder'
                                                            break;
                                                        case 'EntranceScanner':
                                                            rolename = 'Ingang scanner'
                                                            break;
                                                    
                                                        default:
                                                            rolename = role.name;
                                                            break;
                                                    }
                                                    return rolename + (user.roles.length == i+1?'': (user.roles.length == i+2 ? ' en ' : ', '));
                                                })}
                                        </td>
                                        {/* <td className="admin-actions" onClick={()=> {history.push(Routes.ADMIN_USERS_EDIT, user)}}><FaEdit className="admin-actions__edit"/></td> */}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <Col className="text-center align-middle d-flex mt-4">
                        {userPaginationData && userPaginationData.current_page < userPaginationData.last_page ? 
                            loadingMore?
                            <span className="loadingspinner mx-auto"></span>
                            :
                            <button className="btn btn-secondary mx-auto" onClick={()=> {handleLoadMore()}}>
                                Laad meer gebruikers
                            </button>
                        :
                        ''
                        }
                    </Col>
                    </div>
                </>
            :
                <p>Er werden geen gebruikers gevonden voor de opgegeven filters</p>
            }
        </>
        :
            <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                <p className="m-auto d-flex align-items-center">Gebruikers worden geladen... <span className="loadingspinner d-block ms-3"></span></p>
            </Col>
        }
        </Container>
    </div>
    {showPopup && 
        <>
            {showPopup == 'subscription' &&
                <div className='popup' onClick={(e)=> {setShowPopup(null);}}>
                    <div className={`card`} onClick={(e)=> {e.stopPropagation()}}>
                        <div className='close' onClick={(e)=> {setShowPopup(null);}}>&times;</div>
                        <Row>
                            {selectedUser &&
                                <>
                                    <Col xs='12'>
                                        <h3 htmlFor="user">Abonnement aanmaken</h3>
                                        <table className="payforsubscriptiontable">
                                            <tbody>
                                                <tr>
                                                    <td className='pt-0'>
                                                    {selectedUser.firstname} {selectedUser.name}
                                                    </td>
                                                    <td></td>
                                                    <td className='p-0 text-end'>
                                                    {formatMoney(prices.filter((x) => {return x.name == 'dog_owner'})[0].price_incl)}
                                                    </td>
                                                </tr>
                                                {selectedUser.active_subscriptions.length == 0 ? 
                                                    <>
                                                    <tr>
                                                        <td className='py-3 d-flex flex-row flex-nowrap align-items-center'>
                                                            <input className="form-check-input p-0 m-0 flex-shrink-0" type="checkbox" id="wants_card" defaultChecked={selectedUserCard == 1} 
                                                                onChange={()=>{
                                                                    if (selectedUserCard) {
                                                                        setSelectedUserCard(0); setTotal(total - prices.filter((x) => {return x.name == 'card'})[0].price_incl)
                                                                    } else {
                                                                        setSelectedUserCard(1); setTotal(total+ prices.filter((x) => {return x.name == 'dog_cheap'})[0].price_incl);
                                                                    }
                                                                }}/>
                                                            <label className="p-0 m-0 ms-2" htmlFor="wants_card">
                                                                Fysieke kaart
                                                            </label>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td className='p-0 py-3 text-end'>
                                                        {formatMoney(prices.filter((x) => {return x.name == 'card'})[0].price_incl)}
                                                        </td>
                                                    </tr>
                                                    {dogs.map((dog, i)=> {
                                                        return (
                                                            <tr key={i}>
                                                                <td className='p-0 py-3' colSpan={3}>
                                                                    <Row>
                                                                        <Col md={10}>
                                                                            <Row>
                                                                                <DogRegisterRow id={dog.id} dogs={dogs} index={i} breeds={breeds} setDogs={setDogs} showDelete={true} 
                                                                                    handleDelete={(d)=>{
                                                                                        if ((newDogs.length + dogs.length)<=2) {
                                                                                            setTotal(total - prices.filter((x) => {return x.name == 'dog_expensive'})[0].price_incl);
                                                                                        } else {
                                                                                            setTotal(total - prices.filter((x) => {return x.name == 'dog_cheap'})[0].price_incl);
                                                                                        }
                                                                                        let temp = [...deletedDogs];
                                                                                        temp.push(d);
                                                                                        setDeletedDogs(temp);
                                                                                    }}/>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md={2} className='text-end'>
                                                                            {i<2?formatMoney(prices.filter((x) => {return x.name == 'dog_expensive'})[0].price_incl): formatMoney(prices.filter((x) => {return x.name == 'dog_cheap'})[0].price_incl)}
                                                                        </Col>
                                                                    </Row>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    {newDogs.map((dog, i)=> {
                                                        return (
                                                            <tr key={i}>
                                                                <td className='p-0 py-3' colSpan={3}>
                                                                    <Row>
                                                                        <Col md={10}>
                                                                            <Row>
                                                                                <DogRegisterRow dogs={newDogs} index={i} breeds={breeds} setDogs={setNewDogs} showDelete={true} handleDelete={(id)=>{
                                                                                    if ((newDogs.length + dogs.length)<=2) {
                                                                                        setTotal(total - prices.filter((x) => {return x.name == 'dog_expensive'})[0].price_incl);
                                                                                    } else {
                                                                                        setTotal(total - prices.filter((x) => {return x.name == 'dog_cheap'})[0].price_incl);
                                                                                    }
                                                                                }}/>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md={2} className='text-end'>
                                                                            {(i + dogs.length)<2?formatMoney(prices.filter((x) => {return x.name == 'dog_expensive'})[0].price_incl): formatMoney(prices.filter((x) => {return x.name == 'dog_cheap'})[0].price_incl)}
                                                                        </Col>
                                                                    </Row>
    
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td className='p-0 py-2' colSpan={3}>
                                                            <button className='btn btn-primary' onClick={()=> {addDog()}}>hond toevoegen</button>
                                                        </td>
                                                    </tr>
                                                </>
                                                :
                                                <>
                                                    {selectedUserCard == 1 ? 
                                                        <tr>
                                                            <td className='py-3'>
                                                                Fysieke kaart
                                                            </td>
                                                            <td></td>
                                                            <td className='p-0 py-3 text-end'>
                                                            {formatMoney(prices.filter((x) => {return x.name == 'card'})[0].price_incl)}
                                                            </td>
                                                        </tr>
                                                        :
                                                        ''
                                                    }
                                                    {selectedUser.dogs.map((dog, i)=> {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                {dog.dog_name}
                                                                </td>
                                                                <td>
                                                                {dog.breed.breed_name}
                                                                </td>
                                                                <td className='text-end pe-0'>
                                                                    {i <2?formatMoney(prices.filter((x) => {return x.name == 'dog_expensive'})[0].price_incl): formatMoney(prices.filter((x) => {return x.name == 'dog_cheap'})[0].price_incl)}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                                }
                                                <tr>
                                                    <td>
                                                    <b>Totaal: </b>
                                                    </td>
                                                    <td></td>
                                                    <td className='p-0 text-end'>
                                                        {formatMoney(total)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    {selectedUser.active_subscriptions.length == 0 && 
                                        <Col xs='auto' className='ms-auto'>
                                            <button className='btn btn-primary' onClick={()=>{handleCreateSubscription()}}>
                                                Abonnement aanmaken
                                            </button>
                                        </Col>
                                    }
                                </>
                            }
                        </Row>
                    </div>
                </div>
            }
            
            {showPopup == 'roles' &&
                <div className='popup' onClick={(e)=> {setShowPopup(null);}}>
                    <div className={`card`} onClick={(e)=> {e.stopPropagation()}}>
                        <div className='close' onClick={(e)=> {setShowPopup(null);}}>&times;</div>
                        <Row>
                            {selectedUser &&
                                <>
                                    <Col xs='12'>
                                        <h3 htmlFor="user">Rollen {selectedUser.firstname} {selectedUser.name}</h3>
                                        <Select
                                            isMulti={true}
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#EBE9EB',
                                                    primary: 'black',
                                                    neutral10: 'black',
                                                },
                                            })}
                                            // value={dogs[index].breed == null ?'' :{value: dogs[index].breed, label: breeds.filter((x) => x.id == dogs[index].breed).length == 1 ? breeds.filter((x) => x.id == dogs[index].breed)[0].breed_name : 'Andere'}}
                                            // value={[...selectedUser.roles.map((role, i)=> {
                                            //     return {value: role.id, label: role.name}
                                            // })]}
                                            value={selectedRoles}
                                            options={[...roles.map((role, i)=> {
                                                return {value: role.id, label: role.name};
                                            })]} 
                                            onChange={(e)=> {
                                                setSelectedRoles(e);
                                            }}
                                        />
                                    </Col>
                                    <Col xs='auto' className='ms-auto mt-3'>
                                        <button className='btn btn-primary' onClick={()=>{handleChangeRoles()}}>
                                            rollen opslaan
                                        </button>
                                    </Col>
                                </>
                            }
                        </Row>
                    </div>
                </div>
            }
            {showPopup == 'activities' &&
                <div className='popup' onClick={(e)=> {setShowPopup(null);}}>
                    <div className={`card`} onClick={(e)=> {e.stopPropagation()}}>
                        <div className='close' onClick={(e)=> {setShowPopup(null);}}>&times;</div>
                        {selectedUser &&
                        <>
                            <h3 htmlFor="user">Activiteiten {selectedUser.firstname} {selectedUser.name}</h3>
                            <table className='admin-table'>
                                <colgroup>
                                    <col style={{width:"20%"}}></col>
                                    <col style={{width:"20%"}}></col>
                                    <col style={{width:"40%"}}></col>
                                    <col style={{width:"20%"}}></col>
                                </colgroup> 
                                <thead>
                                    <tr>
                                        <th className={`th-filter`}> Datum </th>
                                        <th className={`th-filter`}> Type </th>
                                        <th className={`th-filter`}> Omschrijving </th>
                                        <th className={`th-filter`}> Beloning </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedUser.activities.map((activity)=>{
                                        return (
                                            <tr className="tr-clickable" key={activity.id}>
                                                <td>
                                                    <Moment format="DD/MM/YYYY HH:mm" tz="Europe/Brussels">{activity.created_at}</Moment> 
                                                </td>
                                                <td>
                                                    {activity.type}
                                                </td>
                                                <td>
                                                    {activity.type == 'check-in'?
                                                        `Check-in ${activity.checkin_counter} op ${activity.event.event_name}`
                                                    :''}
                                                </td>
                                                <td>
                                                    {activity?.reward?.credits_amount? 
                                                            <>
                                                                {activity.reward.credits_amount}
                                                                <img className='ms-1' alt='tudors' src={'/assets/img/coin.svg'} style={{height: '1em'}}></img>
                                                            </>
                                                    : '/'}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            </>
                        }
                    </div>
                </div>
            }
            {showPopup == 'wallet' &&
                <div className='popup' onClick={(e)=> {setShowPopup(null);}}>
                    <div className={`card`} onClick={(e)=> {e.stopPropagation()}}>
                        <div className='close' onClick={(e)=> {setShowPopup(null);}}>&times;</div>
                        <Row>
                            {selectedUser &&
                                <>
                                    <Col xs='12'>
                                        <h3 htmlFor="user">Wallet {`${selectedUser.firstname} ${selectedUser.name}`}</h3>
                                        <div className="form-floating mb-4">
                                            <input className="form-control" type="number" id="floatingStreet" placeholder="straat"  value={amountToGive} min={1} 
                                                onChange={(e)=>{
                                                    setAmountToGive(e.target.value);
                                                }}/>
                                            <label htmlFor="floatingStreet">Hoeveel tudors wil {selectedUser.firstname} je geven?</label>
                                        </div>
                                    </Col>
                                    
                                    <Col xs='auto' className='ms-auto'>
                                        <button className='btn btn-primary' onClick={()=>{handleGiveTudors()}}>
                                            Tudors geven
                                        </button>
                                    </Col>
                                    
                                </>
                            }
                        </Row>
                    </div>
                </div>
            }
        </>
        }
    </>
  );
};

export default AdminUsersPage;