import { default as React, useState } from 'react';
import { FaDog, FaUser, FaUsers, FaMoneyCheck, FaCalendarAlt, FaBook, FaClipboardList, FaGift } from 'react-icons/fa';
import { TiShoppingCart } from 'react-icons/ti';
import { MdDashboard } from 'react-icons/md';
import { GoGraph } from 'react-icons/go';
import {Link, useLocation} from 'react-router-dom';
import * as Routes from '../../routes';
import { useAuth } from '../../services';
import { MdQrCodeScanner } from 'react-icons/md';

const Nav = ({openSideNav, setOpenSideNav}) => {
  const location = useLocation();
  const {currentUser} = useAuth();
  const [isAdmin] = useState(currentUser.roles.filter((x)=> { return x.name == "Admin"}).length > 0);
  const [isDogOwner] = useState(currentUser.roles.filter((x)=> { return x.name == "DogOwner"}).length > 0);
  const [isEntranceScanner] = useState(currentUser.roles.filter((x)=> { return x.name == "EntranceScanner"}).length > 0);
  const [isExhibitor] = useState(currentUser.roles.filter((x)=> { return x.name == "Exhibitor"}).length > 0);
  
  return (
    <nav className={openSideNav?'show':''}>
        <img alt='de jog club logo' src={'/assets/img/logo.svg'}></img>
        <Link to={Routes.DASHBOARD} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav)}} className={location.pathname.split('/')[1] === 'dashboard'?'active':''}><MdDashboard/> Dashboard</Link>
        <Link to={Routes.PROFILE} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav)}} className={location.pathname.split('/')[1] === 'profile'?'active':''}><FaUser/> Profiel</Link>
        {isDogOwner && 
          <>
            <Link to={Routes.DOGS} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav)}} className={location.pathname.split('/')[1] === 'dogs'?'active':''}><FaDog/> Mijn honden</Link>
            <Link to={Routes.ACTIVITIES} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav)}} className={location.pathname.split('/')[1] === 'activities'?'active':''}><FaBook/> Activiteiten</Link>
          </>
        }
        {isExhibitor && 
          <>
            <Link to={Routes.GIFT} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav)}} className={location.pathname.split('/')[1] === 'sendgift'?'active':''}><FaGift/>Beloningen uitdelen</Link>
            <Link to={Routes.COLLECTED_DATA} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav)}} className={location.pathname.split('/')[1] === 'userdata'?'active':''}><FaUsers/>Mijn roedel</Link>
          </>
        }
        <Link to={Routes.EVENTS} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav)}} className={location.pathname.split('/')[1] === 'events'?'active':''}><FaCalendarAlt/> Events</Link>
        {isEntranceScanner && 
          <>
            <Link to={Routes.SCAN_USER} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav)}} className={location.pathname.split('/')[1] === 'scanuser'?'active':''}><MdQrCodeScanner/> Ingang scanner </Link>
          </>
        }
        {isAdmin && 
        <>
          <h2 className='w-100 mt-3 text-light'>Admin</h2>
          <Link to={Routes.ADMIN_READ_QR} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav);}} className={location.pathname.split('/')[2] === 'readqr'?'active':''}><MdQrCodeScanner/> QR scanner </Link>
          <Link to={Routes.ADMIN_USERS} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav);}} className={location.pathname.split('/')[2] === 'users'?'active':''}><FaUsers/> Gebruikers </Link>
          <Link to={Routes.ADMIN_EXHIBITORS} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav);}} className={location.pathname.split('/')[2] === 'exhibitors'?'active':''}><TiShoppingCart/> Standhouders </Link>
          <Link to={Routes.ADMIN_EVENTS} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav);}} className={location.pathname.split('/')[2] === 'events'?'active':''}><FaCalendarAlt/> Events beheren </Link>
          <Link to={Routes.ADMIN_SUBSCRIPTIONS} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav);}} className={location.pathname.split('/')[2] === 'subscriptions'?'active':''}><FaMoneyCheck/> Abonnementen </Link>
          <Link to={Routes.ADMIN_ACTIVITIES} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav);}} className={location.pathname.split('/')[2] === 'activities'?'active':''}><FaClipboardList/> Alle activiteiten </Link>
          <Link to={Routes.ADMIN_STATS} onClick={()=> {window.innerWidth<1200 && setOpenSideNav(!openSideNav);}} className={location.pathname.split('/')[2] === 'statistics'?'active':''}><GoGraph/> Statistieken </Link>
        </>
        }
        <ul className={`header-hamburger ${openSideNav?'active':''}`} onClick={()=>{setOpenSideNav(!openSideNav)}}>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
    </nav>
  );
};

export default Nav;