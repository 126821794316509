import { default as React, useEffect, useState} from 'react';
import { PopUp } from '../';
import {useApi, useAuth} from '../../services';
import Moment from 'react-moment';
import {useHistory} from 'react-router-dom';
import * as Routes from '../../routes';

const EventCard = ({id, title = '', location = '', start='', end='', showJoinButton = false, joined, exhibitorEntrancePrice, services, orderedServices = [], event}) => {
  const { currentUser } = useAuth();
  const { joinEventAsExhibitor } = useApi();
  const history = useHistory();

  const [disableButton, setSetDisableButton] = useState(false);
  const [joinPopup, setJoinPopup] = useState(false);
  const [localJoined, setLocalJoined] = useState(joined);
  const [price, setPrice] = useState(0);
  const [wantedServices, setWantedServices] = useState({});
  const [localOrderedServices, setLocalOrderedServices] = useState(orderedServices);

  const joinEvent = async () => {
    setSetDisableButton(true);
    setJoinPopup(false);
    if (currentUser.wallet.amount >= price) {
      let resp = await joinEventAsExhibitor(id, wantedServices);
      setLocalOrderedServices(resp)
      setLocalJoined(true);
    } else {
      history.push(Routes.BUY_CREDITS);
    }
  }

  useEffect(()=> {
    if (wantedServices) {      
      calcTotalPrice();
    }
  }, [wantedServices]);

  const calcTotalPrice = () => {
    let totalPrice = exhibitorEntrancePrice;
    Object.keys(wantedServices).forEach((s)=>{
      totalPrice += (wantedServices[s].price * wantedServices[s].amount);
    })
    setPrice(totalPrice);
  }

  return (
    <>
      <div className='card card-event'>
        <div className='card-event__title mb-3'>
            <h3>{title}</h3>
            <span className='card-event__dates'>
              <Moment format="DD/MM/YY">{start}</Moment> - <Moment format="DD/MM/YY">{end}</Moment>
            </span>
        </div>
        <div className='card-event__data'>
          <p className='mb-3'><span className={`card-event__location `}>{location}</span></p>
          <div onClick={()=>{history.push(Routes.EVENT_DETAIL.replace(':id', id), event)}} className="btn btn-readmore m-0">
            Meer info
          </div>
          {showJoinButton && 
            <>
              {localJoined?
                <>
                  <p className='mt-3'>U bent ingeschreven voor dit event als standhouder.</p>
                  {localOrderedServices && localOrderedServices.length > 0 && 
                    <>
                      <p className='mt-3'>
                        U heeft volgende diensten/producten gereserveerd:
                      </p>
                      <table className='admin-table'>
                        <tbody>
                          {localOrderedServices.map((order, i)=>{
                            return <tr key={i}>
                              <td>{order.service.name}</td>
                              <td>{order.amount}</td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </>
                  }
                </>
              :
                <>
                  {!disableButton?
                    <button className='btn btn-green w-100 mt-3' onClick={()=>{setJoinPopup(true)}}>Deelnemen aan event</button>
                  :
                    <span className="loadingspinner d-block mt-3"></span>
                  }
                </>
              }
            </>
          }
        </div>
      </div>
      {
        joinPopup && 
          <PopUp title='Weet u zeker dat u zich op dit event wil inschrijven als standhouder?' subtitle='Er zullen Tudors uit uw wallet worden genomen om uw staanplaats op het event te betalen. Selecteer hieronder de producten/diensten die u wenst te huren voor het event.' 
          negativeTitle='annuleren' positiveTitle= 'inschrijven' negativeAction={()=>{setJoinPopup(false)}} positiveAction={()=> {joinEvent()}}>
            <table className='admin-table'>
              <thead>
                <tr>
                  <th>
                    Naam
                  </th>
                  <th>
                    Prijs
                  </th>
                  <th>
                    Aantal beschikbaar
                  </th>
                  <th>
                    Aantal reserveren
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Inschrijvingsprijs</td>
                  <td>
                    <div className='d-flex flex-row flex-nowrap align-items-center'>
                      <p>
                        {exhibitorEntrancePrice}
                      </p>
                      <img alt='tudors' src={'/assets/img/coin.svg'} className="ms-1" style={{height: '1em'}}></img>
                    </div>
                  </td>
                  <td></td>
                  <td>1</td>
                </tr>
                {services && services.map((s, i) => {
                  return <tr key={i}>
                    <td>
                      {s.name}
                    </td>
                    <td>
                      <div className='d-flex flex-row flex-nowrap align-items-center'>
                        <p>
                          {s.pivot.price}
                        </p>
                        <img alt='tudors' src={'/assets/img/coin.svg'} className="ms-1" style={{height: '1em'}}></img>
                      </div>
                    </td>
                    <td>{s.pivot.amount_available}</td>
                    <td>
                      <input type="number" onChange={(e)=> {let temp ={...wantedServices}; temp[s.id] = {price: s.pivot.price, amount: e.target.value}; setWantedServices(temp);}} min={0} max={s.pivot.amount_available} className="form-control" defaultValue={0} required={true} />
                    </td>
                  </tr>
                })}
                <tr>
                  <td><strong>Totaal</strong></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div className='d-flex flex-row flex-nowrap align-items-center'>
                      <p>
                        <strong>{price}</strong>
                      </p>
                      <img alt='tudors' src={'/assets/img/coin.svg'} className="ms-1" style={{height: '1em'}}></img>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </PopUp>
      }
    </>

  );
};

export default EventCard;