import { default as React, useState, useCallback, useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { useApi } from '../../services';
import { BarChart, LineChart, DoubleBarChart, StatsCard } from '../../components';

const StatsPage = () => {
    const { initStatsPage } = useApi();
    const [loaded, setLoaded] = useState(false);
    const [labels, setLabels] = useState();
    const [exhibitorSubscriptionGraphData, setExhibitorSubscriptionGraphData] = useState();
    const [subscriptionGraphData, setSubscriptionGraphData] = useState();
    const [subscriptionsPricesGraphData, setSubscriptionsPricesGraphData] = useState();
    const [userGraphData, setUserGraphData] = useState();
    const [breedGraphData, setBreedGraphData] = useState();
    const [breedLabels, setBreedLabels] = useState();
    const [zipLabels, setZipLabels] = useState();
    const [zipData, setZipData] = useState();

    const [dogOwnersSubscriptionsAmount, setDogOwnersSubscriptionsAmount] = useState(0);
    const [exhibitorsSubscriptionsAmount, setExhibitorsSubscriptionsAmount] = useState(0);
    const [dogsAmount, setDogsAmount] = useState(0);
    const [dogOwnersAmount, setDogOwnersAmount] = useState(0);
    const [exhibitorsAmount, setExhibitorsAmount] = useState(0);
    
    const initFetch = useCallback(() => {
        const fetchdata = async () => {
            const response = await initStatsPage();
            let temp = response.zipsGraph;
            const sorted = Object.entries(temp)
                    .sort(([,a],[,b]) => a-b)
                    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
            setZipLabels(Object.keys(sorted).reverse());
            setZipData(Object.values(sorted).reverse());
            setLabels(response.labels);
            setExhibitorSubscriptionGraphData(response.exhibitoSubscriptionsGraph);
            setSubscriptionGraphData(response.subscriptionGraph);
            setSubscriptionsPricesGraphData(response.subscriptionsPricesGraph);
            setUserGraphData(response.usersGraph);
            setBreedGraphData(response.breedsGraph);
            setBreedLabels(response.breedsLabels);

            setDogsAmount(response.dogs_amount);
            setDogOwnersSubscriptionsAmount(response.dogowners_subscriptions_amount);
            setExhibitorsSubscriptionsAmount(response.exhibitors_subscriptions_amount);
            setDogOwnersAmount(response.dogowners_amount);
            setExhibitorsAmount(response.exhibitors_amount);
            setLoaded(true);
        }
        fetchdata();
	},[ initStatsPage ]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

  return (
    <div className='card'>
        <Row className="align-items-center mb-3">
            <Col><h1>Statistieken</h1></Col>
        </Row>
        <Container fluid >
        {loaded ?
            <>
                <Row className="align-items-center mb-3">
                    <Col md={4}>
                        <StatsCard text={'Gebruikers'} number={dogOwnersAmount} extraClasses='green'/>
                    </Col>
                    <Col md={4}>
                        <StatsCard text={'Gebruikers met abonnement'} number={dogOwnersSubscriptionsAmount} extraClasses='green'/>
                    </Col>
                    <Col md={4}>
                        <StatsCard text={'Honden'} number={dogsAmount} extraClasses='orange'/>
                    </Col>
                    <Col md={4}>
                        <StatsCard text={'Standhouders'} number={exhibitorsAmount} extraClasses='blue'/>
                    </Col>
                    <Col md={4}>
                        <StatsCard text={'Standhouders met abonnement'} number={exhibitorsSubscriptionsAmount} extraClasses='blue'/>
                    </Col>

                </Row>
                <Row className="align-items-center mb-3">
                    <Col md={6}>
                        <Row>
                            <Col xs={12}>
                                <h2>Nieuwe abonnementen</h2>
                            </Col>
                            <Col>
                                <DoubleBarChart labels={labels} data={subscriptionsPricesGraphData} axis='inkomsten in €' data2={subscriptionGraphData} axis2='nieuwe gebruikers' data3={exhibitorSubscriptionGraphData} axis3='nieuwe standhouders'/>
                                {/* <BarChart labels={labels} data={subscriptionGraphData}/> */}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col xs={12}><h2>Aantal gebruikers</h2></Col>
                            <Col>
                                <LineChart labels={labels} data={userGraphData}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Row>
                            <Col xs={12}><h2>Honden per ras</h2></Col>
                            <Col>
                                <BarChart horizontalScroll={true} labels={breedLabels} data={breedGraphData}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col xs={12}><h2>Gebruikers per postcode</h2></Col>
                            <Col>
                                <BarChart horizontalScroll={true} labels={zipLabels} data={zipData}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
            :
            <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                <p className="m-auto d-flex align-items-center">Statistieken worden geladen... <span className="loadingspinner d-block ms-3"></span></p>
            </Col>
            }
        </Container>
    </div>
  );
};

export default StatsPage;