import { default as React, Fragment, useState, useRef } from 'react';
import {Col, Container, Row, Form} from 'react-bootstrap';
import * as Routes from '../../routes';
import { useAuth } from '../../services';
import { useHistory, Link } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa'

const ForgotPasswordPage = () => {
  const history = useHistory();

  const [sendEmail, setSendEmail] = useState();

  const {sendForgotPasswordEmail} = useAuth();

  const email = useRef();
    
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity() !== false) {
      const resp = await sendForgotPasswordEmail(email.current.value);
      if(resp.ok){
        setSendEmail(true);
      } else{
        setSendEmail(false);
      }
    } else {
      setValidated(true);
    }
  };

  return (
    <Fragment>
        {sendEmail === true ?  
          <div className='card card-auth d-flex flex-column align-items-center'>
              <FaCheckCircle className="add-success__icon mb-4"/>
              <h3 className="mb-2">De email werd succesvol verzonden!</h3>
              <p>Klik op de link in de email om de volgende stappen te ondernemen</p>
              <Link to={Routes.LOGIN} className="btn btn-primary mt-4">terug naar login</Link>
          </div>
        :
      <Form className='card card-auth' noValidate validated={validated} onSubmit={handleSubmit} >
          <h2 className="mb-3">Wachtwoord vergeten? Geen probleem.</h2>
          <p className="mb-4">Vul uw e-mailadres in en we verzenden u een email met een reset link waarmee u uw wachtwoord kan resetten.</p>
          <Form.Group>
            <div className="form-floating mb-3">
              <input type="email" className="form-control" id="floatingEmail" placeholder="name@example.com" required ref={email}/>
              <label htmlFor="floatingEmail">E-mailadres</label>
              <Form.Control.Feedback type="invalid" className='mb-4'>
                Gelieve de e-mailadres in te vullen.
              </Form.Control.Feedback>
            </div>
          </Form.Group>
        {sendEmail === false && <p className="w-100 text-center mb-3 error-message form-row">Er liep iets mis bij het verzenden van de email, probeer later opnieuw.</p>}
      <Form.Row>
          <Row className="flex-row-reverse">
            <Col sm={6}>
                <button type="submit" className="btn btn-primary w-100 mb-3 mb-sm-0">Reset mail verzenden</button>
            </Col>
            <Col sm={6}>
                <button type="button" className="btn btn-secondary w-100" onClick={()=> {history.push(Routes.LOGIN)}}>Annuleren</button>
            </Col>
          </Row>

      </Form.Row>
    </Form>
    }
  </Fragment>
  );
};

export default ForgotPasswordPage;