import { default as React, useState, useCallback, useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { useApi } from '../../services';
import Moment from 'react-moment';

const AdminSubscriptionsPage = () => {
    const { getFilteredSubscriptions, initSubscriptionPage } = useApi();

    const [column, setColumn] = useState('active_until');
    const [searchString, setSearchString] = useState('');
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [subscriptions, setSubscriptions] = useState();
    const [newSubscriptionData, setNewSubscriptionData] = useState({user: null, until_date: null});
    const [users, setUsers] = useState();
    const [subscriptionPaginationData, setSubscriptionPaginationData] = useState();
    
    const [loadingMore, setLoadingMore] = useState(false);

    const initFetch = useCallback(() => {
        var today = new Date();
        setNewSubscriptionData({...newSubscriptionData, until_date:today.getDate() + '/' + (today.getMonth() + 1) + '/' + (today.getFullYear()+1)})
        const fetchdata = async () => {
            const response = await initSubscriptionPage();
            setSubscriptionPaginationData(response.subscriptions);
            setSubscriptions(response.subscriptions.data);
            setUsers(response.users);
            setLoaded(true);
        }
        fetchdata();
	},[ getFilteredSubscriptions ]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

    const handleLoadMore = async () => {
        setLoadingMore(true);
        let resp = await getFilteredSubscriptions(page+1, column, order?'DESC':'ASC', searchString);
        setPage(page+1);
        setSubscriptionPaginationData(resp);
        setSubscriptions([...subscriptions, ...resp.data]);
        setLoadingMore(false);
    }

    useEffect(async ()=> {
        handleFilter()
    }, [column, order]);

    const handleFilter = async () => {
        setLoaded(false);
        const resp = await getFilteredSubscriptions(1, column, order?'DESC':'ASC', searchString);
        setPage(1);
        setSubscriptionPaginationData(resp);
        setSubscriptions(resp.data);
        setLoaded(true);
    }

    const setFilter = (filter) => {
        if(column===filter){
            setOrder(!order); 
        }
        setColumn(filter);
    }

    useEffect(()=>{
		const timeout = setTimeout(() => {
			handleFilter()
		}, 750);
		return () => clearTimeout(timeout);
	}, [searchString])

  return (
    <div className='card'>
        <Row className="align-items-center mb-4">
            <Col><h1>Abonnementen beheren</h1></Col>
        </Row>
        <Container fluid>
        {loaded ?
            <>
                <Row className="align-items-center mb-3">
                    <Col sm={9} className='mb-4'></Col>
                    <Col xs={'auto'} className="text-sm-end">Zoeken:</Col>
                    <Col className="searchbar pe-2">
                        <input className="form-control w-100" value={searchString} 
                            onChange={(e)=> {setSearchString(e.target.value)}}/>
                        {searchString && <span onClick={()=> {setSearchString('');}}>
                            <FaTimes/>
                        </span>}
                    </Col>
                </Row>
                {subscriptions && subscriptions.length > 0 ?
                    <>
                        <div className="row">
                            <table className='admin-table'>
                                <colgroup>
                                    <col style={{width:"40%"}}></col>
                                    <col style={{width:"20%"}}></col>
                                    <col style={{width:"20%"}}></col>
                                    <col style={{width:"20%"}}></col>
                                    {/* <col style={{width:"20%"}}></col> */}
                                    {/* <col style={{width:"15%"}}></col> */}
                                </colgroup> 
                                <thead>
                                    <tr>
                                        <th className={`th-filter ${column==='name'?'th-filter__active':''}`}> Naam {column==='name' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                        <th className={`th-filter ${column==='email'?'th-filter__active':''}`}> Email {column==='name' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                        <th className={`th-filter text-center ${column==='active_from'?'th-filter__active':''}`} onClick={()=> {setFilter('active_from')}}> Abonnement van {column==='active_from' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                        <th onClick={()=> {setFilter('active_until')}} className={`text-center th-filter ${column==='active_until'?'th-filter__active':''}`}>Abonnement tot {column==='active_until' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th>
                                        {/* <th onClick={()=> {setFilter('amount_roles')}} className={`text-center th-filter ${column==='amount_roles'?'th-filter__active':''}`}>Rol {column==='amount_roles' && (order?<FaChevronDown/>:<FaChevronUp/>)}</th> */}
                                        {/*<th>&nbsp;</th>*/}
                                    </tr>
                                </thead>
                                <tbody>
                                    {subscriptions.map((subscription)=>{
                                        return (
                                            <tr key={subscription.id}>
                                                <td>{subscription.user.firstname} {subscription.user.name}</td>
                                                <td>{subscription.user.email}</td>
                                                <td className="text-center"><Moment format="DD/MM/YYYY">{subscription.active_from}</Moment></td>
                                                <td className="text-center"><Moment format="DD/MM/YYYY">{subscription.active_until}</Moment></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <Col className="text-center align-middle d-flex mt-4">
                                {subscriptionPaginationData && subscriptionPaginationData.current_page < subscriptionPaginationData.last_page ? 
                                    loadingMore?
                                    <span className="loadingspinner mx-auto"></span>
                                    :
                                    <button className="btn btn-secondary mx-auto" onClick={()=> {handleLoadMore()}}>
                                        Laad meer abonnementen
                                    </button>
                                :
                                ''
                                }
                            </Col>
                        </div>
                    </>
                :
                    <p>Er werden geen abonnementen gevonden voor de opgegeven filters</p>
                }
            </>
            :
            <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                <p className="m-auto d-flex align-items-center">Abonnementen worden geladen... <span className="loadingspinner d-block ms-3"></span></p>
            </Col>
            }
        </Container>
    </div>
  );
};

export default AdminSubscriptionsPage;