import { default as React, useEffect, useState} from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import { ImQrcode } from 'react-icons/im';
import { useAuth } from '../../services';
import QRCode from 'qrcode.react';
import { useHistory } from 'react-router';
import * as Routes from '../../routes';

const Header = ({sideNavStatus, openSideNav}) => {
    const {currentUser, logOut} = useAuth();
    const history = useHistory();

    const [isExhibitor] = useState(currentUser.roles.filter((x)=> { return x.name == "Exhibitor"}).length > 0);
    const [showQr, setShowQR] = useState(false);

    const handleQrIconClick = () => {
        if (currentUser.active_subscriptions.length == 0) {
            history.push(Routes.PAY_FOR_SUBSCRIPTION);
        } else {
            if (!showQr) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
            setShowQR(!showQr);
        }
    }

  return (
    <>
        <header>
            <ul className={`header-hamburger ${sideNavStatus?'active':''}`} onClick={()=>{openSideNav(!sideNavStatus)}}>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <div className="header-profile">
                <div className="header-username" >
                    <div className={`me-1 ${isExhibitor?'pointer':''}`} onClick={()=>{if(isExhibitor){history.push(Routes.BUY_CREDITS)}}}>
                        {currentUser.wallet.amount}
                    </div>
                    <div className={`me-3 ${isExhibitor?'pointer':''}`} onClick={()=>{if(isExhibitor){history.push(Routes.BUY_CREDITS)}}}>
                        <img alt='tudors' src={'/assets/img/coin.svg'} style={{height: '1em'}}></img>
                    </div>
                    <div className='me-3' onClick={()=>{handleQrIconClick()}}>
                        <ImQrcode/>
                    </div>
                    <div className='ms-3' onClick={()=>{logOut()}}>
                        <FaSignOutAlt/> 
                    </div>
                </div>
            </div>
        </header>
        <div className={`qr-wrapper mb-3 ${showQr?'qr-wrapper__active':''}`} onClick={()=>{handleQrIconClick()}}>
            <QRCode value={currentUser.qr_hash} renderAs="svg" fgColor='#1A1A1A'/>
        </div>
    </>
  );
};

export default Header;