import { default as React, useContext, createContext } from 'react';
import {useAuth} from './index';
import {config} from '../config';

const ApiContext = createContext();
const useApi = () => useContext(ApiContext);

const ApiProvider = ({children}) => {
  const {bearerToken, setCurrentUser} = useAuth();
  const baseUrl = config.API_URL;

  const getBreeds = async () => {
    let url = `${baseUrl}/api/breeds`;
    
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    let breeds = JSON.parse(sessionStorage.getItem('tudorandfriendsbreeds'));
    if (breeds) {
      return breeds;
    }

    try {
      const resp = await fetch(url, options);
      let data = await resp.json();
      sessionStorage.setItem('tudorandfriendsbreeds', JSON.stringify(data));
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  const getAdminBreeds = async () => {
    let url = `${baseUrl}/api/admin/breeds`;
    
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    try {
      const resp = await fetch(url, options);
      let data = await resp.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  const getUsers = async () => {
    let url = `${baseUrl}/api/users`;
    
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const getAdmins = async () => {
    let url = `${baseUrl}/api/users/team`;

    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const getFilteredUsers = async (page, column, order, searchString='') => {
    let url = `${baseUrl}/api/users?page=${page}&column=${column}&order=${order}&search=${searchString}`;
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const updateUser = async (id, name, email, role) => {
    let url = `${baseUrl}/api/users/${id}`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({name, email, role}),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    return resp;
  }

  const payForDogOwnerSubscription = async (wants_card) => {
    let url = `${baseUrl}/api/redirecttomollie/dogowner`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({wants_card}),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    return resp;
  }

  const payForExhibitorSubscription = async (credits) => {
    let url = `${baseUrl}/api/redirecttomollie/exhibitor`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({credits}),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    return resp;
  }

  const initPayForSubscriptionPage = async () => {
    let url = `${baseUrl}/api/payforsubscription`;

    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    if (resp.ok) {
      let data = await resp.json();
      setCurrentUser(data.user);
      return data.prices;
    }
    return resp;
  }

  const handleMollieResponse = async (paymentId) => {
    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'POST',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };

    let resp = await fetch(`${baseUrl}/api/handlemollie/${paymentId}`, options);
    let apiData = await resp.json();
    if (resp.ok) {
      setCurrentUser(apiData);
    }
    return resp;
  };

  const getFilteredSubscriptions = async (page, column, order, searchString='') => {
    let url = `${baseUrl}/api/subscriptions?page=${page}&column=${column}&order=${order}&search=${searchString}`;
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const initSubscriptionPage = async (page, column, order, searchString='') => {
    let url = `${baseUrl}/api/initsubscriptionpage`;
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const createSubscriptionByAdmin = async (user_id, wants_card, dogs, newDogs, deletedDogs, column, order, searchString='') => {
    let url = `${baseUrl}/api/admin/subscriptions/store`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({user_id, wants_card, column, order, searchString, dogs, newDogs, deletedDogs}),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const updateDogs = async (dogs, newDogs, deletedDogs) => {
    let url = `${baseUrl}/api/dogs/update`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({dogs, newDogs, deletedDogs}),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    if (resp.ok) {
      let data = await resp.json();
      setCurrentUser(data.user);
      return {data, resp};
    }
    return {resp};

  }

  const initDogsPage = async () => {
    let url = `${baseUrl}/api/initdogspage`;
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const initContinuePage = async () => {
    let url = `${baseUrl}/api/continuepayment`;
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const initStatsPage = async () => {
    let url = `${baseUrl}/api/initstatspage`;
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const getEvents = async (page=1, column='event_start', order='DESC', searchString='') => {
    let url = `${baseUrl}/api/events?page=${page}&column=${column}&order=${order}&search=${searchString}`;

    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }


  const getEvent = async (id) => {
    let url = `${baseUrl}/api/events/${id}`;

    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const initEventsPage = async () => {
    let url = `${baseUrl}/api/initeventspage`;

    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    if (resp.ok) {
      let data = await resp.json();
      setCurrentUser(data.user);
      return data.events;
    }
    return resp;
  }

  const addEvent = async (event_name, event_description, event_start, event_end, event_street, event_nr, event_city, event_zip, event_country, exhibitorPrice, rewards, services, nonProfitServices) => {
    let url = `${baseUrl}/api/events`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }
    
    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        event_name,
        event_description,
        event_start,
        event_end,
        event_street,
        event_nr,
        event_city,
        event_zip,
        event_country,
        exhibitorPrice,
        rewards,
        services,
        nonProfitServices
      }),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    return resp;
  }

  const joinEventAsExhibitor = async (event_id, services) => {
    let url = `${baseUrl}/api/joinevent/exhibitor`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        event_id,
        services,
      }),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    let data;
    if (resp.ok) {
      data = await resp.json();
      setCurrentUser(data.user);
    }
    return data.orderedServices;
  }

  const getUserFromQR = async (code) => {
    let url = `${baseUrl}/api/getuserbyqr/${code}`;

    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return resp;
  }

  const getOngoingEvents = async () => {
    let url = `${baseUrl}/api/ongoingevents`;

    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return resp;
  }

  const checkInUserToEvent = async (code, event_id) => {
    let url = `${baseUrl}/api/joinevent/user`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        event_id,
        code
      }),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    return resp;
  }

  const userSelfCheckin = async (code) => {
    let url = `${baseUrl}/api/checkin`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        code
      }),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    return resp;
  }

  const updateRoles = async (user_id, roles) => {
    let url = `${baseUrl}/api/users/updateroles`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        user_id,
        roles
      }),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    return resp;
  }

  const initUsersPage = async () => {
    let url = `${baseUrl}/api/admin/inituserspage`;
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const getActivities = async (page=1) => {
    let url = `${baseUrl}/api/activities?page=${page}`;
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const getAdminActivities = async (page=1) => {
    let url = `${baseUrl}/api/admin/activities?page=${page}`;
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const getFilteredExhibitors = async (page, column, order, searchString='') => {
    let url = `${baseUrl}/api/exhibitors?page=${page}&column=${column}&order=${order}&search=${searchString}`;
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const initExhibitorsPage = async () => {
    let url = `${baseUrl}/api/admin/initexhibitorspage`;
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const initRegisterPage = async () => {
    let url = `${baseUrl}/api/initregisterpage`;
    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
    };

    const resp = await fetch(url, options);
    return await resp.json();
  }

  const updateEvent = async (id, event_name, event_description, event_start, event_end, event_street, event_nr, event_city, event_zip, event_country, exhibitorPrice, rewards, services, nonProfitServices) => {
    let url = `${baseUrl}/api/events/${id}`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }
    
    const options = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        event_name,
        event_description,
        event_start,
        event_end,
        event_street,
        event_nr,
        event_city,
        event_zip,
        event_country,
        exhibitorPrice,
        rewards,
        services,
        nonProfitServices
      }),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    return resp;
  }

  const buyCredits = async (credits) => {
    let url = `${baseUrl}/api/buycredits`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }
    
    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        credits
      }),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    return resp;
  }

  const changeIsNonProfit = async (company_id, non_profit) => {
    let url = `${baseUrl}/api/users/nonprofit`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }
    
    const options = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        company_id,
        non_profit
      }),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    return resp;
  }

  const transferCoins = async (user_id, amount, event_id) => {
    let url = `${baseUrl}/api/givetudors`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }
    
    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        user_id,
        amount,
        event_id
      }),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    let data = await resp.json();

    if (resp.ok) {
      setCurrentUser(data.user);
    }
    return {ok: resp.ok, data};
  }

  const adminGiveTudors = async (user_id, amount) => {
    let url = `${baseUrl}/api/admin/givetudors`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }
    
    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        user_id,
        amount
      }),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    return resp;
  }

  const giveGoodybag = async (user_id, event_id) => {
    let url = `${baseUrl}/api/givegoody`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }
    
    const options = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        user_id,
        event_id
      }),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    let data = await resp.json();
    return {ok: resp.ok, data};
  }

  const initCollectedDataPage = async (page=1, column='name', order='ASC', searchString='') => {
    let url = `${baseUrl}/api/initcollecteddata?page=${page}&column=${column}&order=${order}&search=${searchString}`;

    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    if (resp.ok) {
      let data = await resp.json();
      return data;
    }
    return resp;
  }

  const initProfilePage = async () => {
    let url = `${baseUrl}/api/initprofilepage`;

    const myHeaders = {
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    if (resp.ok) {
      let data = await resp.json();
      return data;
    }
    return resp;
  }

  const updateCurrentUserAndCompany = async (
    firstname, 
    name, 
    street, 
    nr, 
    city, 
    zip, 
    country, 
    phone, 
    wants_card, 
    company_name,
    company_street,
    company_nr,
    company_city,
    company_zip,
    company_country,
    company_type_id,
    company_description,
    default_gift_on_scan
  ) => {
    let url = `${baseUrl}/api/users/currentuserandcompany`;

    const myHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        firstname, 
        name, 
        street,
        nr,
        city,
        zip,
        country,
        phone,
        wants_card,
        company_name,
        company_street,
        company_nr,
        company_city,
        company_zip,
        company_country,
        company_type_id,
        company_description,
        default_gift_on_scan
      }),
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    if (resp.ok) {
      let temp = await resp.json();
      setCurrentUser(temp);
    }
    return resp;
  }

  const downloadExhibitorsCsv = async () => {
    let url = `${baseUrl}/api/csv/exhibitors`;

    const myHeaders = {
      "Accept": "text/csv",
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    if (resp.ok) {
      let csv = await resp.text();
      let today = new Date();

      saveStreamCSV('standhouders_' + today.getDate() + '_' + (today.getMonth() + 1) + '_' + today.getFullYear(), csv);
    }
    return resp;
  }

  const downloadDogOwnersCsv = async () => {
    let url = `${baseUrl}/api/csv/dogowners`;

    const myHeaders = {
      "Accept": "text/csv",
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    if (resp.ok) {
      let csv = await resp.text();
      let today = new Date();

      saveStreamCSV('gebruikers_' + today.getDate() + '_' + (today.getMonth() + 1) + '_' + today.getFullYear(), csv);
    }
    return resp;
  }

  const downloadEventOrders = async (eventId) => {
    let url = `${baseUrl}/api/csv/${eventId}/orders`;

    const myHeaders = {
      "Accept": "text/csv",
      "Authorization" : `Bearer ${bearerToken}`
    }

    const options = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const resp = await fetch(url, options);
    if (resp.ok) {
      let csv = await resp.text();
      let today = new Date();

      saveStreamCSV('event_' + eventId + '_bestellingen_' + today.getDate() + '_' + (today.getMonth() + 1) + '_' + today.getFullYear(), csv);
    }
    return resp;
  }


  const saveStreamCSV = (filename, text) => {
    if(window.navigator.msSaveBlob) {
        // IE 10 and later, and Edge.
        var blobObject = new Blob([text], {type: 'text/csv'});
        window.navigator.msSaveBlob(blobObject, filename);
    } else {
        // Everthing else (except old IE).
        // Create a dummy anchor (with a download attribute) to click.
        var anchor = document.createElement('a');
        anchor.download = filename;
        if(window.URL.createObjectURL) {
            // Everything else new.
            var blobObject = new Blob([text], {type: 'text/csv'});
            anchor.href = window.URL.createObjectURL(blobObject);
        } else {
            // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
            // Load up the data into the URI for "download."
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text);
        }
        // Now, click it.
        if (document.createEvent) {
            var event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            anchor.dispatchEvent(event);
        }
        else {
            anchor.click();
        }
    }
}

  

  return (
      <ApiContext.Provider value={{
          addEvent,
          adminGiveTudors,
          buyCredits,
          createSubscriptionByAdmin,
          checkInUserToEvent,
          changeIsNonProfit,
          downloadDogOwnersCsv,
          downloadEventOrders,
          downloadExhibitorsCsv,
          getAdmins,
          getAdminBreeds,
          getAdminActivities,
          getBreeds,
          getFilteredExhibitors,
          getFilteredSubscriptions,
          getFilteredUsers,
          getUsers,
          getEvents,
          getEvent,
          getOngoingEvents,
          getUserFromQR,
          giveGoodybag,
          handleMollieResponse,
          getActivities,
          initCollectedDataPage,
          initUsersPage,
          initEventsPage,
          initExhibitorsPage,
          initDogsPage,
          initContinuePage,
          initPayForSubscriptionPage,
          initRegisterPage,
          initSubscriptionPage,
          initStatsPage,
          initProfilePage,
          joinEventAsExhibitor,
          payForDogOwnerSubscription,
          payForExhibitorSubscription,
          transferCoins,
          updateUser,
          updateDogs,
          updateEvent,
          updateCurrentUserAndCompany,
          updateRoles,
          userSelfCheckin
        }
      }>
        {children}
      </ApiContext.Provider>
  );
};

export {
  ApiContext,
  ApiProvider,
  useApi,
}