import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router';

import * as Routes from '../routes';
import { useAuth } from '../services';

const renderMergedProps = (component, layout, routeProps) => {
  return (layout) ? React.createElement(layout, routeProps, React.createElement(component, routeProps)) : React.createElement(component, routeProps);
};

const NotSubscribedRouteWithLayout = ({ component, layout, ...rest }) => {
  const [checkedLoggedIn, setCheckedLoggedIn]= useState(false);
  const { currentUser, bearerToken, refreshCurrentUser } = useAuth();

  useEffect(async ()=> {
    if (bearerToken && !currentUser) {
      await refreshCurrentUser();
    }
    setCheckedLoggedIn(true);
  }, []);

  return (  
    <>
      {checkedLoggedIn && 
      <Route {...rest} render={routeProps => {
        return currentUser && (currentUser.active_subscriptions.length == 0) ?
          renderMergedProps(component, layout, routeProps)
          :<Redirect to={Routes.DASHBOARD}/>
        }
      } />
      }
    </>
  );
};

export default NotSubscribedRouteWithLayout;