import { default as React, Fragment, useState, useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { useAuth, useApi } from '../services';
import {formatMoney} from '../utilities/NumberFormat';

const BuyCreditsPage = () => {
  const { currentUser } = useAuth();
  const [prices, setPrices]= useState();
  const [redirecting, setRedirecting]= useState(false);
  const [credits, setCredits]= useState(1000);
  const [showOther, setShowOther]= useState(false);
  const [minCreditsWarning, setMinCreditsWarning]= useState(false);
  const [creditsPrice, setCreditsPrice]= useState(0);
  const { initPayForSubscriptionPage, buyCredits } = useApi();

  const [loaded, setLoaded] = useState(false);

  useEffect(async ()=> {
      let data = await initPayForSubscriptionPage();
      setPrices(data);
      setLoaded(true);
      setCreditsPrice(data.filter((x) => {return x.name == 'coin'})[0].price_incl * credits);
  }, []);

  const handleMollieBuyCredits = async ( ) => {
    if (credits < 50) {
      setMinCreditsWarning(true);
      setCredits(50);
      setCreditsPrice(50 * parseFloat(prices.filter((x) => {return x.name == 'coin'})[0].price_incl));

    } else {
      setRedirecting(true);
      let resp = await buyCredits(credits);
      if (resp.ok) {
        let data = await resp.json();
        window.location = data.mollieUrl;
      }
    }
  }

  const handleCreditsChange = (amount) => {
    if (amount != 'other') {
      setShowOther(false);
      setCredits(amount); 
      setCreditsPrice(amount * parseFloat(prices.filter((x) => {return x.name == 'coin'})[0].price_incl));
    } else {
      setShowOther(true);
    }
  }

  const handleCreditsInputChange = (amount) => {
      setCredits(Math.round(amount)); 
      setCreditsPrice(Math.round(amount) * parseFloat(prices.filter((x) => {return x.name == 'coin'})[0].price_incl));
  }

  return (
    <Fragment>
          <Container fluid className='mt-4'>
            {loaded && prices ? 
            <>
              <Row>
                <Col xs={12}>
                  <h1>Tudors kopen</h1>
                  <p>Meer credits kopen</p>
                  <table className="payforsubscriptiontable">
                    <tbody>
                      <tr>
                        <td>
                          <div className='d-flex flex-row align-items-center'>
                            <img alt='tudors' className='me-1' src={'/assets/img/coin.svg'} style={{height: '1em'}}></img>
                            <label htmlFor="floatingCredits" className='p-0 m-0 me-2'>Tudors</label>
                            <div className='select-wrapper ms-auto'>
                              <select name="credits" id="floatingCredits" className="w-100 pe-4" defaultValue={credits} onChange={(e)=> {handleCreditsChange(e.target.value)}}>
                                <option value="1000">1000</option>
                                <option value="2500">2500</option>
                                <option value="5000">5000</option>
                                <option value="other">Eigen aantal</option>
                              </select>
                            </div>
                            {showOther && 
                              <input type="number" className="form-control w-25 ms-2" step={1} value={credits} min={50} onChange={(e)=> {handleCreditsInputChange(e.target.value)}}/>
                            }
                          </div>
                        </td>
                        <td>
                          {formatMoney(creditsPrice)}
                        </td>
                      </tr>
                      {minCreditsWarning && 
                        <tr>
                          <td><p className='error-message'><small>Het minimum aantal Tudors dat gekocht kan worden, is 50.</small></p></td>
                          <td></td>
                        </tr>  
                      }
                      <tr>
                        <td>
                          <b>Totaal: </b>
                        </td>
                        <td>
                          {formatMoney(parseFloat(creditsPrice))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row>
                <Col>
                  {!redirecting?
                    <button type="button" className="btn btn-primary " onClick={()=> {handleMollieBuyCredits();}}>Betalen</button>
                    :
                    <span className="loadingspinner d-block ms-3"></span>
                  }
                </Col>
              </Row>
            </>
            :
            <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                <p className="m-auto d-flex align-items-center">Laden... <span className="loadingspinner d-block ms-3"></span></p>
            </Col>
            }
          </Container>
    </Fragment>
  );
};

export default BuyCreditsPage;