import { default as React, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import Select from 'react-select'

const EventRewardRow = ({ index, rewards, setRewards, handleDelete=(d)=>{},  id=null}) => {

	return (
        <Row className='my-3'>
            <Col xs={11}>
                <Row>
                    <Col md={6} className='mb-3'>
                        <Row>
                            <Col xs={12}>
                                <div className="form-floating always-up">
                                    <label htmlFor="breed">Type</label>
                                    <Select
                                        classNamePrefix="react-select"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#EBE9EB',
                                                primary: 'black',
                                                neutral10: 'black',
                                            },
                                        })}
                                        value={{value: rewards[index].type, label: rewards[index].type}}
                                        options={[{value: 'check-in', label: 'check-in'}, {value: 'check-in-recurring', label: 'check-in-recurring'}]}
                                        onChange={(e)=> {
                                            let temp = [...rewards];
                                            temp[index].type = e.value;
                                            setRewards(temp);
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {rewards[index].type == 'check-in' ? 
                        <>
                            <Col md={6} className='mb-3 mb-md-0'>
                                <div className="form-floating">
                                    <input type="number" value={rewards[index].reward_at} onChange={(e)=> {let temp = [...rewards]; temp[index].reward_at = e.target.value; setRewards(temp)}} className="form-control" id="check-ins" placeholder="naam" required={true} />
                                    <label htmlFor="check-ins">Na hoeveel check-ins?</label>
                                    <Form.Control.Feedback type="invalid" className='mb-5'>
                                        Gelieve een hoeveelheid check-ins in te geven.
                                    </Form.Control.Feedback>
                                </div>
                            </Col>
                            <Col md={6}>
                            </Col>
                        </>
                    :''}
                    {rewards[index].type == 'check-in-recurring' ? 
                        <>
                            <Col md={6} className='mb-3 mb-md-0'>
                                <div className="form-floating">
                                    <input type="number" value={rewards[index].reward_at} onChange={(e)=> {let temp = [...rewards]; temp[index].reward_at = e.target.value; setRewards(temp)}} className="form-control" id="check-ins" placeholder="naam" required={true} />
                                    <label htmlFor="check-ins">Om de hoeveel check-ins?</label>
                                    <Form.Control.Feedback type="invalid" className='mb-5'>
                                        Gelieve een hoeveelheid check-ins in te geven.
                                    </Form.Control.Feedback>
                                </div>
                            </Col>
                            <Col md={6}>
                            </Col>
                        </>
                    :''}
                    <Col md={6} className='mb-3 mb-md-0'>
                        <div className="form-floating">
                            <input type="number" value={rewards[index].credits} onChange={(e)=> {let temp = [...rewards]; temp[index].credits = e.target.value; setRewards(temp)}} className="form-control" id="credits" placeholder="naam" required={true} />
                            <label htmlFor="credits">Tudors</label>
                            <Form.Control.Feedback type="invalid" className='mb-5'>
                                Gelieve een hoeveelheid Tudors in te geven.
                            </Form.Control.Feedback>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col xs={1} className="p-0 d-flex align-items-center">
                <button type="button" className="btn btn-primary p-0 px-md-2" onClick={()=> {
                        let temp = [...rewards]; 
                        temp.splice(index, 1);
                        setRewards(temp);
                        handleDelete(id);
                    }}
                >&times;</button>
            </Col>
        </Row>
	);
};

export default EventRewardRow;