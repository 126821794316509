import { default as React, useState, useCallback, useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { useApi } from '../../services';
import Moment from 'react-moment';

const AdminActivitiesPage = () => {
    const { getAdminActivities } = useApi();
    const [activitiesPaginationData, setActivitiesPaginationData] = useState();
    const [activities, setActivities] = useState();
    const [page, setPage] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);

    const initFetch = useCallback(() => {
      const fetchdata = async () => {
        const response = await getAdminActivities();
        setActivitiesPaginationData(response.activities);
        setActivities(response.activities.data);
        setLoaded(true);
      }
      fetchdata();
	},[ getAdminActivities ]);

	useEffect(() => {
		initFetch();
	}, []);

    const handleLoadMore = async () => {
        setLoadingMore(true);
        let resp = await getAdminActivities(page+1);
        setPage(page+1);
        setActivitiesPaginationData(resp.activities);
        setActivities([...activities, ...resp.activities.data]);
        setLoadingMore(false);
    }


  return (
      <>
    <div className='card'>
        <Row className="align-items-center mb-4">
            <Col sm={8}><h1>Alle activiteiten</h1></Col>
        </Row>
        <Container fluid>
            {loaded ? 
                <>
                    {activities && activities.length > 0 ?
                    <>
                        <div className="row">
                        <table className='admin-table'>
                            <colgroup>
                                <col style={{width:"20%"}}></col>
                                <col style={{width:"20%"}}></col>
                                <col style={{width:"20%"}}></col>
                                <col style={{width:"20%"}}></col>
                                <col style={{width:"20%"}}></col>
                            </colgroup> 
                            <thead>
                                <tr>
                                    <th className={`th-filter`}> Gebruiker </th>
                                    <th className={`th-filter`}> Datum </th>
                                    <th className={`th-filter`}> Type </th>
                                    <th className={`th-filter`}> Omschrijving </th>
                                    <th className={`th-filter`}> Beloning </th>
                                </tr>
                            </thead>
                            <tbody>
                                {activities.map((activity,i)=>{
                                    return (
                                        
                                        <tr className="tr-clickable" key={activity.id}>
                                            <td>
                                                {activity.user.firstname} {activity.user.name}
                                            </td>
                                            <td>
                                                <Moment format="DD/MM/YYYY HH:mm" tz="Europe/Brussels">{activity.created_at}</Moment> 
                                            </td>
                                            <td>
                                                {activity.type == 'transfer_outgoing'?
                                                    'Tudors gegeven'
                                                :
                                                    <>
                                                        {activity.type == 'transfer_incoming'?
                                                            'Tudors ontvangen'
                                                        :
                                                            <>
                                                                {activity.type == 'goodybag_given'?
                                                                    'Goodybag gegeven'
                                                                :
                                                                <>
                                                                    {activity.type == 'goodybag_received'?
                                                                        'Goodybag ontvangen'
                                                                    :
                                                                        <>{activity.type}</>
                                                                    }
                                                                </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                {activity.type == 'check-in' && `Check-in ${activity.checkin_counter} op ${activity.event.event_name}`}
                                                {activity.type == 'transfer_incoming' && `${activity.transaction.amount} tudors ontvangen van ${activity.transaction.wallet_from.user.company.company_name}`}
                                                {activity.type == 'transfer_outgoing' && `${activity.transaction.amount} tudors gegeven aan ${activity.transaction.wallet_to.user.firstname} ${activity.transaction.wallet_to.user.name}`}
                                                {activity.type == 'goodybag_received' && `Goodybag ontvangen van ${activity.goody_target.company.company_name}`}
                                                {activity.type == 'goodybag_given' && `Goodybag gegeven aan ${activity.goody_target.firstname} ${activity.goody_target.name}`}
                                            </td>
                                            <td>
                                                {activity?.reward?.credits_amount? 
                                                    <>
                                                        {activity.reward.credits_amount}
                                                        <img className='ms-1' alt='tudors' src={'/assets/img/coin.svg'} style={{height: '1em'}}></img>
                                                    </>
                                                : '/'}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <Col className="text-center align-middle d-flex mt-4">
                            {activitiesPaginationData && activitiesPaginationData.current_page < activitiesPaginationData.last_page ? 
                                loadingMore?
                                    <span className="loadingspinner mx-auto"></span>
                                :
                                    <button className="btn btn-secondary mx-auto" onClick={()=> {handleLoadMore()}}>
                                        Laad meer activiteiten
                                    </button>
                            :
                                ''
                            }
                        </Col>
                        </div>
                    </>
                    :
                        <p>Er zijn nog geen activiteiten</p>
                    }
                </>
            :
                <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                    <p className="m-auto d-flex align-items-center">Activiteiten worden geladen... <span className="loadingspinner d-block ms-3"></span></p>
                </Col>
            }
        </Container>
    </div>
    </>
  );
};

export default AdminActivitiesPage;