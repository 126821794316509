import { default as React, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import Select from 'react-select'

const DogRegisterRow = ({ dogs, index, breeds, setDogs, showDelete, handleDelete=(d)=>{}, id=null}) => {
    const [showOtherInput, setShowOtherInput] = useState(dogs[index].breed == 'other');

	return (
        <>
            <Col xs={11}>
                <Row>
                    <Col md={6} className='mb-3 mb-md-0'>
                        <div className="form-floating">
                            <input type="text" value={dogs[index].name} onChange={(e)=> {let temp = [...dogs]; temp[index].name = e.target.value; setDogs(temp)}} className="form-control" id="floatingDogName" placeholder="naam" required={true} />
                            <label htmlFor="floatingDogName">Naam</label>
                            <Form.Control.Feedback type="invalid" className='mb-5'>
                                Gelieve de naam van uw hond in te geven.
                            </Form.Control.Feedback>
                        </div>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col xs={12}>
                                <div className="form-floating always-up">
                                    <label htmlFor="breed">Ras</label>
                                    <Select
                                        classNamePrefix="react-select"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#EBE9EB',
                                                primary: 'black',
                                                neutral10: 'black',
                                            },
                                        })}
                                        value={dogs[index].breed == null ?'' :{value: dogs[index].breed, label: breeds.filter((x) => x.id == dogs[index].breed).length == 1 ? breeds.filter((x) => x.id == dogs[index].breed)[0].breed_name : 'Andere'}}
                                        options={[{value: 'other', label: 'Andere'}, ...breeds.map((breed, i)=> {
                                            return {value: breed.id, label: breed.breed_name}
                                        })]} 
                                        onChange={(e)=> {
                                            if (e.value == 'other') {
                                                setShowOtherInput(true);
                                            } else {
                                                setShowOtherInput(false);
                                            }
                                            let temp = [...dogs];
                                            temp[index].breed = e.value;
                                            setDogs(temp);
                                        }}
                                    />
                                </div>
                            </Col>
                            {showOtherInput && 
                                <Col xs={12} className='mt-3'>
                                    <div className="form-floating">
                                        <input type="text" value={dogs[index].other_breed} onChange={(e)=> {let temp = [...dogs]; temp[index].other_breed = e.target.value; setDogs(temp);}} className="form-control" id="floatingDogBreed" placeholder="naam" required={true} />
                                        <label htmlFor="floatingDogBreed">Ras naam</label>
                                        <Form.Control.Feedback type="invalid" className='mb-5'>
                                            Gelieve het ras van uw hond in te geven.
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            }
                        </Row>

                    </Col>
                </Row>
            </Col>
            {showDelete &&
                <Col xs={1} className="p-0 d-flex align-items-center">
                    <button type="button" className="btn btn-primary p-0 px-md-2" onClick={()=> {
                            let temp = [...dogs]; 
                            temp.splice(index, 1);
                            setDogs(temp);
                            handleDelete(id);
                        }}
                    >&times;</button>
                </Col>
            }

        </>
	);
};

export default DogRegisterRow;