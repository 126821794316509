import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DoubleBarChart = ({ labels, data, data2, data3 = [], title='', axis='', axis2='',  axis3=''}) => {
    let options = {
        responsive: true,
        scales: {
            y: {
              beginAtZero: true,
              display: true,
              position: 'left',
            },
            y1: {
              beginAtZero: true,
              display: true,
              position: 'right',
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
            },
          },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: title,
            },
        },
        aspectRatio: 1.5
    };

    const graphData = {
        labels,
        datasets: [
            {
                label: axis,
                data: data,
                backgroundColor: '#288F93',
                yAxisID: 'y',
            },
            {
                label: axis2,
                data: data2,
                backgroundColor: '#B8D26C',
                yAxisID: 'y1',
            },
            {
              label: axis3,
              data: data3,
              backgroundColor: '#E8744E',
              yAxisID: 'y1',
          }
        ]
    };

  return <Bar options={options} data={graphData}/>;
}

export default DoubleBarChart;
