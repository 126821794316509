import { default as React, Fragment, useEffect, useState, useCallback } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { useApi, useAuth } from '../services';
import { useHistory } from 'react-router';
import { EventCard } from '../components';

const EventsPage = () => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const { initEventsPage } = useApi();

    const [loaded, setLoaded] = useState(false);
    const [events, setEvents] = useState();
    const [hasSubscription] = useState(currentUser.active_subscriptions.length > 0);
    const [isExhibitor] = useState(currentUser.roles.filter((x)=> { return x.name == "Exhibitor"}).length > 0);

    const initFetch = useCallback(() => {
        const fetchdata = async () => {
            const response = await initEventsPage();
            setEvents(response);
            setLoaded(true);
        }
        fetchdata();
    },[ initEventsPage ]);

    useEffect(() => {
        initFetch();
    }, []);

    return (
        <Fragment>
            <h1>Events</h1>
            <Container>
                <Row>
                    {loaded ?
                    <>
                        {events && events.length> 0? 
                            <>
                                {events.map((event, i) => {
                                    return <Col sm={6} md={4} lg={3} key={i}>
                                        <EventCard 
                                            id={event.id} 
                                            joined={isExhibitor && currentUser.events_as_exhibitor.filter((x)=> { return x.id == event.id}).length > 0} 
                                            showJoinButton={isExhibitor && hasSubscription && event.exhibitor_entree_price != null} 
                                            title={event.event_name} 
                                            start={event.event_start} 
                                            end={event.event_end} 
                                            exhibitorEntrancePrice={event.exhibitor_entree_price} 
                                            services={currentUser?.company?.non_profit == 1 ? event.non_profit_services  : event.services} 
                                            location={`${event.event_street} ${event.event_nr}, ${event.event_zip} ${event.event_city}`}
                                            orderedServices={event.current_user_ordered_services}
                                            event={event}
                                        />
                                    </Col>
                                })}
                            </>
                        :
                            <p>Op dit moment zijn er nog geen evenementen gepland.</p>
                        }
                    </>
                    :
                    <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                        <p className="m-auto d-flex align-items-center">Events worden geladen...<span className="loadingspinner d-block ms-3"></span></p>
                    </Col>
                    }
   
                </Row>
            </Container>
        </Fragment>
    );
};

export default EventsPage;