import { default as React, Fragment, useState, useCallback, useEffect } from 'react';
import { QrReader } from '@blackbox-vision/react-qr-reader';
import { useApi, useAuth } from '../services';
import { PopUp } from '../components';
import {Col, Row} from 'react-bootstrap';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa'
import Moment from 'react-moment';
import * as Routes from '../routes';
import {Link} from 'react-router-dom';

const SendGiftPage = () => {
    const { getUserFromQR, giveGoodybag, transferCoins} = useApi();
    const { currentUser } = useAuth();
    const [data, setData] = useState();
    const [user, setUser] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [showTudorsPopup, setShowTudorsPopup] = useState(false);
    const [amountToSend, setAmountToSend] = useState(currentUser.company.default_gift_on_scan);
    const [showSuccesfull, setShowSuccesfull] = useState();
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [positiveTitle, setPositiveTitle] = useState('geef tudors');
    const [negativeTitle, setNegativeTitle] = useState('annuleren');
    const [giveTudorsError, setgiveTudorsError] = useState();
    const [title, setTitle] = useState('Tudors geven');
    const [subtitle, setSubtitle] = useState('Hoeveel tudors wenst u te geven?');
    const [successText, setSuccessText] = useState('De tudors zijn succesvol verzonden!');
    const [showDataAcquiredWarning, setShowDataAcquiredWarning] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState();

    useEffect(async ()=>{
        if (data) {
            setLoading(true);
            let resp = await getUserFromQR(data);
            if (resp.ok) {
                let user = await resp.json();
                setUser(user);
                if (user.current_user_data_shares.length > 0) {
                    setShowDataAcquiredWarning(true);
                }
            } else {
                setError(true);
            }
            setLoading(false);
        }
    }, [data]);


    const handleGiveGoodybag = async () => {
        setShowLoadingSpinner(true);
        setPositiveTitle(null);
        setNegativeTitle(null);
        setTitle('Geven van goodybag registreren...');
        setSubtitle(null);
        setSuccessText('Het geven van de goodybag is succesvol geregistreerd');
        setShowTudorsPopup(true);

        let response = await giveGoodybag(user.id, selectedEvent);

        if (response.ok) {
            setShowSuccesfull(true);
            setNegativeTitle('sluiten');

        }else {
            setNegativeTitle('sluiten');

            if (Object.keys(response.data.errors)[0] == 'wallet') {
                setgiveTudorsError('Je hebt te weinig tudors in je wallet!');
            } else {
                setgiveTudorsError('Er liep iets mis');
            }
            
            setShowSuccesfull(false);
        }
    }

    const handleGiveTudors = async () => {
        setShowLoadingSpinner(true);
        setPositiveTitle(null);
        setNegativeTitle(null);
        setSuccessText('De tudors zijn succesvol verzonden!');

        let response = await transferCoins(user.id, amountToSend, selectedEvent);
        setTitle(null);
        setSubtitle(null);

        //setShowTudorsPopup(false);
        if (response.ok) {
            setShowSuccesfull(true);
            setNegativeTitle('sluiten');

        }else {
            setNegativeTitle('sluiten');

            if (Object.keys(response.data.errors)[0] == 'wallet') {
                setgiveTudorsError('Je hebt te weinig tudors in je wallet!');
            } else {
                setgiveTudorsError('Er liep iets mis');
            }
            
            setShowSuccesfull(false);
        }
    }

    const handleCancelPopup = () => {
        if (showSuccesfull) {
            setData(null);
            setUser(null);
        }
        setShowTudorsPopup(false);
        setShowSuccesfull(null);
        setShowLoadingSpinner(false);
        setPositiveTitle('geef tudors');
        setNegativeTitle('annuleren');
        setTitle('Tudors geven');
        setSubtitle('Hoeveel tudors wenst u te geven?');
    }

    const handleInputChange = (val) =>{
        if (val != '' && val < 1) {
            val = 1;
        } else if (val > currentUser.wallet.amount){
            val = currentUser.wallet.amount;
        }
        setAmountToSend(val);
    }

  return (
      <>
        <Row>
            <Col xs='12'>
                <h3 htmlFor="user">Geef cadeau</h3>
            </Col>
            <p className='mb-3'>Geef cadeaus aan gebruikers om hen toe te voegen aan uw roedel.</p>
            {currentUser && currentUser.events_as_exhibitor && currentUser.events_as_exhibitor.length > 0?
            <>
                {selectedEvent ? 
                    <>
                        {loading ? 
                            <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                                <p className="m-auto d-flex align-items-center">Gebruiker wordt geladen... <span className="loadingspinner d-block ms-3"></span></p>
                            </Col>
                        :
                        <>
                            {!user ?
                                <>
                                    {error ? 
                                        <>
                                            <Row>
                                                <Col xs={12}>
                                                    <p className='error-message'>Geen gebruiker gevonden met deze qr code</p>
                                                </Col>
                                            </Row>
                                            <Col xs='auto' className='ms-auto mt-3'>
                                                <button className='btn btn-primary' onClick={()=>{setData(null); setUser(null); setError(null)}}>
                                                    Nieuwe gebruiker scannen
                                                </button>
                                            </Col>
                                        </>
                                    :
                                        <Col lg='6'>
                                            <button onClick={()=>{setSelectedEvent(null)}} className="btn btn-primary mb-3">
                                                Scan voor ander event
                                            </button>
                                            <div className='qr-reader-wrapper'>
                                                <QrReader
                                                    constraints={{ facingMode: 'environment' }}
                                                    onResult={(result, error) => {
                                                        if (!!result) {
                                                            setData(result?.text);
                                                        }
                                                    }}
                                                    containerStyle={{padding: 0}}
                                                />
                                            </div>
                                        </Col>
                                    }
                                </>
                                :
                                <>
                                    <Col xs='12'>
                                        <h5>{user.firstname} {user.name}</h5>
                                        {showDataAcquiredWarning && 
                                            <p className='error-message mb-3'>
                                                Opgepast! Je hebt reeds de gegevens van deze gebruiker ontvangen op <Moment format="HH:mm DD/MM/YY">{user.current_user_data_shares[user.current_user_data_shares.length - 1].created_at}</Moment>
                                            </p>
                                        }
                                        <button className='btn btn-primary' onClick={()=>{handleGiveGoodybag()}}>Geef goodiebag</button>
                                        <button className='btn btn-secondary mt-3 mt-sm-0 ms-sm-3' onClick={()=>{setShowTudorsPopup(true);}}>Geef tudors</button>
                                    </Col>
                                    <Col xs='auto' className='ms-auto mt-4'>
                                        <button className='btn btn-primary' onClick={()=>{setData(null); setUser(null); setError(null)}}>
                                            Nieuwe gebruiker scannen
                                        </button>
                                    </Col>
                                </>
                            }
                        </>
                        }
                    </>
                :
                    <>
                        {currentUser.events_as_exhibitor.map((event, i) => {
                            return <Col sm={3} key={i}>
                                <div className='card'>
                                    <h3>{event.event_name}</h3>
                                    <p className='my-3'>{`${event.event_street} ${event.event_nr}, ${event.event_zip} ${event.event_city}`}</p>
                                    <button className='btn btn-primary' onClick={()=>{setSelectedEvent(event.id);}}>Scan voor dit event</button>
                                </div>
                            </Col>
                        })}
                    </>
                }
            </>
            :
                <p>
                    Je kan enkel belongingen uitdelen op een event waar je voor bent ingeschreven.
                    Bekijk <Link to={Routes.EVENTS} className={"btn btn-readmore"}>hier</Link> de huidige events.
                </p>
            }
        </Row>
        {
        showTudorsPopup && 
            <PopUp title={title} subtitle={subtitle} 
            negativeTitle={negativeTitle} positiveTitle={positiveTitle} negativeAction={()=>{handleCancelPopup()}} positiveAction={()=> {handleGiveTudors()}}>
                {
                    showLoadingSpinner?
                        <>
                            {showSuccesfull === true?
                                <div className='add-success'>
                                    <FaCheckCircle className="add-success__icon"/>
                                    <p>{successText}</p>
                                </div>
                            :
                                showSuccesfull === false?
                                    <div className='add-success'>
                                        <FaMinusCircle className="add-success__icon"/>
                                        <p>{giveTudorsError}</p>
                                    </div>
                                :
                                <span className="loadingspinner d-block mt-3"></span>
                            }
                        </>
                    :
                        <input type="number" onChange={(e)=> {handleInputChange(e.target.value)}} min={1} max={currentUser.wallet.amount} className="form-control" value={amountToSend} required={true} />
                }
            </PopUp>
        }
    </>
  );
};

export default SendGiftPage;