import { default as React, Fragment, useState, useCallback, useEffect } from 'react';
import { QrReader } from '@blackbox-vision/react-qr-reader';
import { useApi } from '../services';
import {Col, Container, Row} from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment-timezone';
import {dateTimeToUTC} from '../utilities/NumberFormat';

const ScanUserEntrancePage = () => {
    const { getOngoingEvents, checkInUserToEvent } = useApi();
    const [data, setData] = useState();
    const [lastCheckin, setLastCheckin] = useState();
    const [user, setUser] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [selectedEvent, setSelectedEvent] = useState();
    const [selectedEventName, setSelectedEventName] = useState();
    const [events, setEvents] = useState();

    const initFetch = useCallback(() => {
        const fetchdata = async () => {
            const response = await getOngoingEvents();
            let data = await response.json();
            setEvents(data.events);
        }
        fetchdata();
    },[ getOngoingEvents ]);

    useEffect(() => {
        initFetch();
    }, []);

    useEffect(async ()=>{
        if (data) {
            setLoading(true);
            let resp = await checkInUserToEvent(data, selectedEvent);
            if (resp.ok) {
                let data = await resp.json();
                setError(null);
                setLastCheckin(data.lastCheckin);
                setUser(data.user);
            } else {
                let data = await resp.json();
                switch (Object.keys(data.errors)[0]) {
                    case 'Subscription':
                        setError('Subscription');
                        break;
                    case 'Code':
                        setError('Code');
                        break;
                    default:
                        setError(Object.values(data.errors)[0])
                        break;
                }
            }
            setLoading(false);
        }
    }, [data]);
    

  return (
      <>
        <Row>
            <Col xs='12'>
                <h3>Qr scanner {selectedEventName && `voor ${selectedEventName}`}</h3>
            </Col>

            {selectedEvent ? 
                <>
                    {loading ? 
                        <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                            <p className="m-auto d-flex align-items-center">Gebruiker wordt geladen... <span className="loadingspinner d-block ms-3"></span></p>
                        </Col>
                    :
                    <>
                        {!user ?
                            <>
                                {error ? 
                                <>
                                    <Row>
                                        <Col xs={12}>
                                            {error == 'Code' ?
                                                <p className='error-message'>Geen gebruiker gevonden met deze qr code</p>
                                            :
                                            <>
                                                <p className='error-message'>{error}</p>
                                            </>
                                            }
                                        </Col>
                                    </Row>
                                    <Col xs='auto' className='ms-auto mt-3'>
                                        <button className='btn btn-primary' onClick={()=>{setData(null); setLastCheckin(null); setError(null)}}>
                                            Nieuwe persoon scannen
                                        </button>
                                    </Col>
                                </>
                            :
                                <Col lg='6'>
                                    <div className='qr-reader-wrapper'>
                                        <QrReader
                                            constraints={{ facingMode: 'environment' }}
                                            onResult={(result, error) => {
                                                if (!!result) {
                                                    setData(result?.text);
                                                }
                                            }}
                                            containerStyle={{padding: 0}}
                                        />
                                    </div>
                                </Col>
                                }
                            </>
                            :
                            <>
                                <Col xs='12'>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td colSpan={2} className='pt-3 pb-0'>
                                                    <h4>{user.firstname} {user.name}</h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='py-3'>
                                                    Laatst ingecheckt op dit event op
                                                </td>
                                                <td className='py-3'>
                                                    {lastCheckin == 'never'?
                                                        '/'
                                                    :
                                                        <Moment format="DD/MM/YYYY HH:mm:ss" tz="Europe/Brussels">{lastCheckin}</Moment> 
                                                    }
                                                </td>
                                            </tr>
                                            {user.active_subscriptions.length > 0?
                                            <>
                                                <tr>
                                                    <td className='py-3'>
                                                        Abonnement tot 
                                                    </td>
                                                    <td><Moment format="DD/MM/YYYY">{user.active_subscriptions[0].active_until}</Moment> (nog <Moment date={user.active_subscriptions[0].active_until} format='DD' duration={new Date()}/> dagen)</td>
                                                </tr>
                                                <tr>
                                                    <td className='py-3'>
                                                        Fysieke kaart
                                                    </td>
                                                    <td>{user.card == 1? 'ja': 'nee'}</td>
                                                </tr>
                                                {user.active_subscriptions[0].subscription_records.map((r, i)=> {
                                                    if (r.dog) {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    {r.dog.dog_name}
                                                                </td>
                                                                <td>
                                                                    {r.dog.breed.breed_name}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })}
                                            </>
                                            :
                                            <tr>
                                                <td colSpan={2} >
                                                    Geen geldig abonnement gevonden.
                                                </td>
                                            </tr>
                                            }
                                            
                                        </tbody>
                                    </table>
                                </Col>
                                <Col xs='auto' className='ms-auto mt-3'>
                                    <button className='btn btn-primary' onClick={()=>{setData(null); setUser(null); setError(null)}}>
                                        Nieuwe gebruiker scannen
                                    </button>
                                </Col>
                            </>
                        }
                    </>
                    }
                </>
            :
                <>
                    {events && events.length> 0 && 
                        <>
                            {events.map((event, i) => {
                                return <Col sm={3} key={i}>
                                    <div className='card'>
                                        <h3>{event.event_name}</h3>
                                        <p className='my-3'>{`${event.event_street} ${event.event_nr}, ${event.event_zip} ${event.event_city}`}</p>
                                        <button className='btn btn-primary' onClick={()=>{setSelectedEvent(event.id); setSelectedEventName(event.event_name)}}>Scan voor dit event</button>
                                    </div>
                                </Col>
                            })}
                        </>
                    }
                </>
            }
        </Row>

    </>
  );
};

export default ScanUserEntrancePage;