import { default as React, Fragment, useState, useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { useAuth, useApi } from '../services';
import { DogRegisterRow } from '../components';

const MyDogsPage = () => {
    const { currentUser, bearerToken, refreshCurrentUser } = useAuth();
    const { updateDogs, initDogsPage } = useApi();

  const [dogs, setDogs] = useState([]);
  const [newDogs, setNewDogs] = useState([]);
  const [deletedDogs, setDeletedDogs] = useState([]);
  const [breeds, setBreeds] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showError, setShowError] = useState(false);
  const [subscriptionDogsAmount, setSubscriptionDogsAmount] = useState(0);

    
  useEffect(async ()=> {
    if (bearerToken && !currentUser) {
      await refreshCurrentUser();
    }
    const data = await initDogsPage();
    setBreeds(data.breeds);
    if (data.mollieUrl) {
        window.location = data.mollieUrl;
    } else {
        setLoaded(true);
    }

    if (currentUser.dogs.length> 0) {
        setDogs(currentUser.dogs.map((x) => {return {id:x.id, name: x.dog_name, breed: x.breed.id}}));
    }
    if (currentUser.active_subscriptions.length> 0) {
        setSubscriptionDogsAmount(currentUser.active_subscriptions[0].subscription_records.filter((x) => x.user_id == null).length);
    }
  }, []);

    const handleSaveDogs = async () => {
        setLoaded(false);
        let resp = await updateDogs(dogs, newDogs, deletedDogs);

        if (resp.resp.ok) {
            if (resp.data.mollieUrl) {
                window.location = resp.data.mollieUrl;
            } else {
                setDogs(resp.data.user.dogs.map((x) => {return {id:x.id, name: x.dog_name, breed: x.breed.id}}));
                sessionStorage.setItem('tudorandfriendsbreeds', JSON.stringify(resp.data.breeds));
                setBreeds(resp.data.breeds);
                setNewDogs([]);
                setDeletedDogs([]);
            }
        } else {
            setShowError(true);
        }

        setLoaded(true);

    }

    const addDog = () => {
        let temp = [...newDogs];
        temp.push({name: '', breed: null});
        setNewDogs(temp);
    }

    const handleDelete = (dogId) => {
        let temp = [...deletedDogs];
        let temp2 = [...dogs];
        temp.push(dogId);
        setDogs(temp2.filter((x) => x.id != dogId));
        setDeletedDogs(temp)
    }

  return (
    <Fragment>
            <h1>Mijn honden</h1>
            <Container fluid className='mt-4 card'>
            {loaded ? 
                    <>
                <Row>
                    <Col>
                    {
                        subscriptionDogsAmount == 0 ?
                            <p className='mb-3'>Overzicht van uw honden</p>
                        :
                            <p className='mb-3'>U heeft een abonnement voor {subscriptionDogsAmount} {subscriptionDogsAmount == 1 ?' hond' :' honden'}. Indien u meer dan {subscriptionDogsAmount} {subscriptionDogsAmount == 1 ?' hond' :' honden'} opslaat, zal er gevraagd worden om bij te betalen.</p>
                    }
                    {showError && 
                        <p className="w-100 text-start my-2 error-message">
                            Opslaan mislukt. Vul zowel de naam als het ras van alle rijen in.
                        </p>
                    }

                    <table className='no-borders d-table'>
                        <tbody>
                            {dogs && dogs.length > 0 && dogs.map((dog, i)=> {
                                return (
                                    <tr key={'dog-' + i}>
                                        <td className='p-0 py-3' colSpan={3}>
                                            <Row>
                                                <DogRegisterRow id={dog.id} dogs={dogs} index={i} breeds={breeds} setDogs={setDogs} showDelete={true} handleDelete={(d)=>{handleDelete(d)}}/>
                                            </Row>
                                        </td>
                                    </tr>
                                )
                            })}
                            {newDogs.map((dog, i)=> {
                                return (
                                    <tr key={'newdog-' + i}>
                                        <td className='p-0 py-3' colSpan={3}>
                                            <Row>
                                                <DogRegisterRow dogs={newDogs} index={i} breeds={breeds} setDogs={setNewDogs} showDelete={true}/>
                                            </Row>
                                        </td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td className='p-0 py-2' colSpan={3}>
                                    <button className='btn btn-secondary' onClick={()=> {addDog()}}>hond toevoegen</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </Col>
                </Row>
                <Row>
                    <Col className='ms-auto'>
                        <button className='btn btn-primary' onClick={()=> {handleSaveDogs()}}>Opslaan</button>
                    </Col>
                </Row>
                </>
                :
                <Col className="text-center align-middle d-flex"  style={{height: '60vh'}}>
                    <p className="m-auto d-flex align-items-center">Uw honden worden geladen... <span className="loadingspinner d-block ms-3"></span></p>
                </Col>
                }
            </Container>
    </Fragment>
  );
};

export default MyDogsPage;